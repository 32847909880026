import React, { useState, useEffect } from "react";
//Images
import map2 from "../../../images/map-2.png";
import avatar1 from "../../../images/avatar/1.jpg";
import avatar3 from "../../../images/avatar/3.jpg";
import delivery from "../../../images/delivery.png";
import pic1 from "../../../images/dish/pic1.jpg";
import pic2 from "../../../images/dish/pic2.jpg";
import pic3 from "../../../images/dish/pic3.jpg";
import RestomaticOnlineServices from "../../../services/services";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Button,Tag } from 'antd';
import {STATES_ORDER, PAY_TYPES, USERS} from "../../../constants";
import { useHistory } from 'react-router-dom';
import axios from "axios";

const moment = require('moment-timezone');

const DeliveryDetail = ({ getAllOrdersRef, reference, getAllMotorcyclesRef }) => {
  const history = useHistory();
  const myWorkerDelivery = localStorage.getItem("workerCod") || ""
  const [showDetailOrder, setShowDetailOrder] = useState(true)
  const [ordersLoaded, setOrdersLoaded] = useState(false)
  const [allOrdersBD, setAllOrdersBD] = useState([])
  const [allMotorcyclesBD, setAllMotorcyclesBD] = useState([])
  const [showDetailOrderSelected, setShowDetailOrderSelected] = useState({})
  const [motorycleAvailableSelected, setMotorycleAvailableSelected] = useState({})
  let id = window.location.href || ""
  id = id && id.split("/").pop()

  useEffect(() => {
  })

  const selectOrder = (e) => {
    setShowDetailOrder(true)
    setShowDetailOrderSelected(e)
    console.log(e)
    if (allMotorcyclesBD && e && e.deliveryInformation && e.deliveryInformation.motorycleAssigned) {
      allMotorcyclesBD.forEach(k => {
        if (k && k.code === e.deliveryInformation.motorycleAssigned) {     
          setMotorycleAvailableSelected(k)
        }
      })
    }
  }
  console.log(motorycleAvailableSelected)
  const goToBack = () => {
    setShowDetailOrder(false)
    history.push("/delivery")
  }

  const filterMotorcycle = (list, id) => {
    let result = {}
    if (list && id) {
      list.forEach(e => {
        if (e && e.code && e.code === myWorkerDelivery) {
          result = e
        }
      })
    }
    console.log(result)
    return result;
  }
  const getAllMotorcyclesFirebase = () => {
    let allIds = []
    let allMotorcyclesAux = []
    getAllMotorcyclesRef.on("value", (snapshot) => {
      let result = []
         if (snapshot.val() !== null) {
          snapshot.forEach(e => {
            allIds.push(e.key)
          })
           allMotorcyclesAux = Object.values(snapshot.val()) && Object.values(snapshot.val());
           allMotorcyclesAux = Object.values(allMotorcyclesAux)
           allMotorcyclesAux = allMotorcyclesAux.map((e, index) => {
              console.log(allIds)
             if (allIds.length) {
             allIds.forEach(k => {
               e.keyBD = `MOTORCYCLEID${allIds[index]}`
             })
             }
             return e
          })
          result = filterMotorcycle(allMotorcyclesAux, myWorkerDelivery)
          console.log(allMotorcyclesAux)
          setAllMotorcyclesBD(allMotorcyclesAux)
          console.log(result)
          setMotorycleAvailableSelected(result)
          // setOrdersLoaded(true)
          //  console.log(result, "allOrdersAux")
         }
         return;
       }, (error) => {
         console.log("ERROR: " + error.code);
       });
    }
    // if (allMotorcyclesBD.length === 0) getAllMotorcyclesFirebase()
    const filterOrders = (orders, id) => {
      console.log(Number(id))
      console.log(orders)
      let result = {}
      if (orders && id) {
        orders.forEach(e => {
          if (e && e.saleId && e.saleId === Number(id)) {
            result = e
          }
        })
      }
      console.log(result)
      return result;
    }
  const getAllOrdersFirebase = () => {
    fetch("https://services-restaurant.elreydelsabor.pe/all-orders-online")
    .then(res => res.json())
    .then(
      (result) => {
        console.log(result)
        result = result.map(k => {
          if (k.deliveryInformation && typeof (k.deliveryInformation) === "string") k.deliveryInformation = JSON.parse(k.deliveryInformation)
          if (k.products && typeof (k.products) === "string") k.products = JSON.parse(k.products)
          // if (k.callCenterAssignedUI && typeof (k.callCenterAssignedUI) === "string") k.callCenterAssignedUI = JSON.parse(k.callCenterAssignedUI)
          // if (k.waitressAssignedUI && typeof (k.waitressAssignedUI) === "string") k.waitressAssignedUI = JSON.parse(k.waitressAssignedUI)
          if (k.dateRegistered && typeof (k.dateRegistered) === "string") k.dateRegistered = JSON.parse(k.dateRegistered)

          return k;
        })

        setAllOrdersBD(result)
        setOrdersLoaded(true)
        console.log(result, "result")
        if (result) {
          let orderLoadedUI = {}
          orderLoadedUI = filterOrders(result, id)
          console.log(orderLoadedUI)
          setShowDetailOrderSelected(orderLoadedUI)
        }
      },
      // Nota: es importante manejar errores aquí y no en 
      // un bloque catch() para que no interceptemos errores
      // de errores reales en los componentes.
      (error) => {
       console.log(error)
      })
    }
    if (allOrdersBD.length === 0 && !ordersLoaded) getAllOrdersFirebase()
    const getNameStateByCode = (state) => {
      let result = ""
      if (state) {
        STATES_ORDER.forEach(e => {
          if (e.value === state) result = e.label
        })
      }
      return result;   
    }
    const goToClient = () => {
      let aux = {...showDetailOrderSelected}
      const service = new RestomaticOnlineServices(reference);
      
      if (aux && aux.state) {
        console.log(aux)
        aux.state = 9
        aux.payType = Number(aux.payType)
        aux.deliveryInformation.shippingDate = ""
        aux.deliveryInformation.shippingDate = moment.tz(new Date(), "America/Lima").format()
        // service.updateOrderDB(aux.keyBD, aux).then(res => {
        //   //actualizar estado de disponibilidad
        //   if (motorycleAvailableSelected) {
        //     let auxMotorycleAvailableSelected = {...motorycleAvailableSelected}
        //     auxMotorycleAvailableSelected.stateAvailable = 2
        //     service.updateStateForMotorcycle(auxMotorycleAvailableSelected.keyBD, auxMotorycleAvailableSelected).then(res => {
        //     })
        //     alert("Vas en camino!")
        //   }
        // })
        if (aux.dateRegistered) aux.dateRegistered = JSON.stringify(aux.dateRegistered)
        if (aux.deliveryInformation) aux.deliveryInformation = JSON.stringify(aux.deliveryInformation)
        if (aux.products) aux.products = JSON.stringify(aux.products)
         console.log(aux)
         axios.put(`https://services-restaurant.elreydelsabor.pe/update-order-online/${aux.id}`, aux, {
           headers: {
                     'Content-Type':'application/json',
                   },
           })
          .then(
            (result) => {
             console.log(result)
             console.log("actualizado")
             alert("Vas en camino!")
             setShowDetailOrderSelected({})
             goToBack()
          })
      }
    }
    const motoActive = localStorage.getItem("workerCod") || ""

    const finishDeliveryOnline = (order) => {
      let aux = {...order}

      if (aux && aux.state) {
        console.log(aux)
        aux.state = 10
        aux.deliveryInformation.arrivalDate = ""
        aux.deliveryInformation.arrivalDate = moment.tz(new Date(), "America/Lima").format()

        if (aux.products) aux.products = JSON.stringify(aux.products)
        if (aux.dateRegistered) aux.dateRegistered = JSON.stringify(aux.dateRegistered)
        if (aux.deliveryInformation) aux.deliveryInformation = JSON.stringify(aux.deliveryInformation)
        if (aux.paymentAtHomeInformation) aux.paymentAtHomeInformation = JSON.stringify(aux.paymentAtHomeInformation)

        aux.payType = Number(aux.payType)

        console.log(aux)
        axios.put(`https://services-restaurant.elreydelsabor.pe/update-order-online/${aux.id}`, aux, {
          headers: {
                    'Content-Type':'application/json',
                  },
          })
        .then(
          (result) => {
            console.log(result)
            setShowDetailOrderSelected({})
               alert("Pedido entregado!, regresa al local pronto o continúa con tu ruta.")
               getAllOrdersFirebase()
               history.push("/delivery")
          }, (error => {
            console.log(error)
          }))
        // service.updateOrderDB(aux.keyBD, aux).then(res => {
        //   if (motorycleAvailableSelected) {
        //     let auxMotorycleAvailableSelected = {...motorycleAvailableSelected}
        //     auxMotorycleAvailableSelected.stateAvailable = 1
        //     console.log(auxMotorycleAvailableSelected)
        //     service.updateStateForMotorcycle(auxMotorycleAvailableSelected.keyBD, auxMotorycleAvailableSelected).then(res => {
        //       alert("Pedido entregado!, regresa al local pronto")
        //       history.push("/delivery")
        //     })
        //   }
        // })
      }
    }
    const finishDelivery = () => {
      let aux = {...showDetailOrderSelected}
      const service = new RestomaticOnlineServices(reference);

      if (aux && aux.deliveryInformation && aux.deliveryInformation.state) {
        console.log(aux)
        aux.deliveryInformation.state = 10
        aux.state = 10
        aux.deliveryInformation.arrivalDate = ""
        aux.deliveryInformation.arrivalDate = moment.tz(new Date(), "America/Lima").format()
        service.updateOrderDB(aux.keyBD, aux).then(res => {
          if (motorycleAvailableSelected) {
            let auxMotorycleAvailableSelected = {...motorycleAvailableSelected}
            auxMotorycleAvailableSelected.stateAvailable = 1
            console.log(auxMotorycleAvailableSelected)
            service.updateStateForMotorcycle(auxMotorycleAvailableSelected.keyBD, auxMotorycleAvailableSelected).then(res => {
              alert("Pedido entregado!, regresa al local pronto")
              history.push("/delivery")
            })
          }
        })
      }
    }
    const getNamePayTypeByCode = (state) => {
      let result = ""
      if (state) {
        PAY_TYPES.forEach(e => {
          if (e.value === state) result = e.label
        })
      }
      return result;   
    }
    const getWorker = (cod) => {
      let result = ""
      USERS.forEach(e => {
        if (e && e.cod === cod) {
          result = e.name
        }
      })
      return result
    }
    const resultWithDifferentsKit = (order) => {
      let resultAuth = true
      if (order && order.products) {
        const onlyAuthKitchens = order.products.map(e => {
          return e.kitchenAuthorized
        })
        console.log(onlyAuthKitchens)
        if (onlyAuthKitchens) {
          console.log(onlyAuthKitchens)
          if (onlyAuthKitchens && onlyAuthKitchens.length === 1) {
            if (onlyAuthKitchens[0] && onlyAuthKitchens[0][0] && onlyAuthKitchens[0][0] === "cocna02") resultAuth = false
          } else resultAuth = onlyAuthKitchens && onlyAuthKitchens.every(a => a === onlyAuthKitchens[0])
        }
      }
      return !resultAuth;
    }
    // const result
    console.log(showDetailOrderSelected)
  return (
    <>
    {
      (showDetailOrder && showDetailOrderSelected && showDetailOrderSelected.deliveryInformation
        && showDetailOrderSelected.products && showDetailOrderSelected.deliveryInformation.client) &&
    <div className="form-head d-flex mb-3 align-items-start">
        <div className="mr-auto d-lg-block">
          <h2 className="text-black font-w600 mb-0">CODIGO DE PEDIDO <br /> # {showDetailOrderSelected.deliveryInformation.orderId}</h2>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a className="text-primary">
                Delivery
              </a>
            </li>
            <li className="breadcrumb-item active">
              <a>Detalle de envío</a>
            </li>
          </ol>
          <h3 className="text-red font-w600 mb-0">TOTAL: S/ {showDetailOrderSelected && showDetailOrderSelected.totalAmount}</h3>
          <h4 className="text-red font-w600 mb-0">MEDIO DE PAGO: 
            {((showDetailOrderSelected.deliveryInformation.client.payTypeClient)) && getNamePayTypeByCode(Number(showDetailOrderSelected.deliveryInformation.client.payTypeClient))}
          </h4>
          {
            (showDetailOrderSelected.deliveryInformation.client.amountForPay) ?
            <h4>CLIENTE PAGA CON: S/ {showDetailOrderSelected.deliveryInformation.client.amountForPay}</h4>
            : null
          }
          {resultWithDifferentsKit(showDetailOrderSelected) && <h4 className="text-red font-w600 mb-0">También acercate al local restaurante para completar el pedido</h4>}
          {/* <br /> */}
          {showDetailOrderSelected && showDetailOrderSelected.deliveryInformation && showDetailOrderSelected.deliveryInformation.client && showDetailOrderSelected.deliveryInformation.client.noteToDelivery && <Tag color="geekblue" style={{fontSize: "1em"}}>NOTA DEL CLIENTE: {` `}
          {showDetailOrderSelected && showDetailOrderSelected.deliveryInformation && showDetailOrderSelected.deliveryInformation.client && showDetailOrderSelected.deliveryInformation.client.noteToDelivery}</Tag>}
        </div>
        <Button type="primary" onClick={() => goToBack()} danger>
          ATRAS
        </Button>
      </div>
      }
      <div className="row">
        <div className="col-xl-9 col-xxl-8 col-lg-11 col-md-10">
            <div className="row">
            {/* {
          !showDetailOrder &&
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body pt-2">
                    <div className="table-responsive ">
                    <div className="name-for-mobile">
                      <h2 className="text-red">
                        Hola!, <strong>{(myWorkerDelivery && getWorker(myWorkerDelivery)) || ""}</strong>
                      </h2>
                    </div>
                      <table className="table items-table">
                        <tbody>
                          <tr>
                            <th className="my-0 text-black font-w300 fs-20">
                              PEDIDOS DE HOY
                            </th>
                          </tr>
                          {
                            (allOrdersBD && allOrdersBD.length > 0) ? 
                            allOrdersBD.map(e => {
                              return (
                                <tr style={{cursor: "pointer"}} onClick={() => selectOrder(e)}>
                                <td>
                                  <div className="media">
                                    <a>
                                      <img
                                        className="mr-3 to=-fluid rounded"
                                        width={85}
                                        src={pic1}
                                        alt="pic1"
                                      />
                                    </a>
                                    <div className="media-body">
                                      <small className="mt-0 mb-1 font-w500">
                                        <a>
                                          COMANDA # {e.id}
                                        </a><br />
                                        <Tag color="#f50">{(e && e.deliveryInformation) && getNameStateByCode(e.deliveryInformation.state).toUpperCase()}</Tag>
                                        {resultWithDifferentsKit(e) && <h4 className="text-red font-w600 mb-0">También acercate al restaurante para completar el pedido</h4>}
                                      </small>
                                      <h5 className="">
                                          DIRECCION PEDIDO: {e.deliveryInformation && e.deliveryInformation.address}
                                      </h5>
                                      <h5 className="">
                                          TELEFONO: {e.deliveryInformation && e.deliveryInformation.phone}
                                      </h5>
                                      <div className="star-review fs-14">
                                        <span className="ml-3 text-dark">
                                          TOTAL: {e.totalAmount} soles
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                {/* <td>
                                  <h4 className="my-0 text-secondary font-w600">
                                    3x
                                  </h4>
                                </td>
                                <td>
                                  <h4 className="my-0 text-secondary font-w600">
                                    $14.99
                                  </h4>
                                </td>
                                <td>
                                  <h4 className="my-0 text-secondary font-w600">
                                    $44.97
                                  </h4>
                                </td>
                                <td>
                                  <Link
                                    to="/order"
                                    className="ti-close fs-28 text-danger las la-times-circle"
                                  />
                                </td> 
                          </tr>
                              )
                            })
                            :
                            <tr>
                              <td>
                                NO TIENES PEDIDOS ASIGNADOS HASTA EL MOMENTO
                              </td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
      } */}
         {
      (showDetailOrder && showDetailOrderSelected && showDetailOrderSelected.deliveryInformation
        && showDetailOrderSelected.products && showDetailOrderSelected.deliveryInformation.client) &&    
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="full-map-area mb-4">
                      <img src={map2} alt="map2" />
                      {/* <Link to="/order" className="btn btn-danger btn-xs">
                        View in Fullscreen
                      </Link> */}
                      <i className="flaticon-381-location-4" />
                    </div>
                    <div className="row mx-0">
                      <div className="media align-items-center col-md-4 col-lg-6 px-0 mb-3 mb-md-0">
                        {/* <img
                          className="mr-3 to=-fluid rounded-circle"
                          width={65}
                          src={avatar3}
                          alt="avatar3"
                        /> */}
                        <div className="media-body">
                          <h4 className="my-0 text-black">DIRECCION: 
                          {(showDetailOrderSelected.deliveryInformation.client && showDetailOrderSelected.deliveryInformation.client.address
                          &&  showDetailOrderSelected.deliveryInformation.client.address)}</h4>
                          <h4>REFERENCIA: 
                            {(showDetailOrderSelected.deliveryInformation.client && showDetailOrderSelected.deliveryInformation.client.reference
                          &&  showDetailOrderSelected.deliveryInformation.client.reference)}
                          </h4>
                        </div>
                      </div>
                      <div className="iconbox col-md-4 col-lg-3 mb-md-0">
                        <i className="las la-shipping-fast" />
                        <small className="text-black">ESTADO</small>
                        <p>{showDetailOrderSelected.state && showDetailOrderSelected.state && getNameStateByCode(showDetailOrderSelected.state)}</p>
                      </div>
                      <div className="iconbox col-md-4 col-lg-3 mb-3 mb-md-0">
                        <a className="text-black" href={`tel:+51${(showDetailOrderSelected.deliveryInformation.client && showDetailOrderSelected.deliveryInformation.client.phone)}`}>
                        <i className="las la-phone" />
                        <small>TELEFONO</small>
                        <p style={{fontSize: "1.2em"}}>{(showDetailOrderSelected.deliveryInformation.client && showDetailOrderSelected.deliveryInformation.client
                          &&  showDetailOrderSelected.deliveryInformation.client.phone)}</p>
                          </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          }
            </div>
          </div>  
      {
        (showDetailOrder && showDetailOrderSelected) &&
          <div className="col-xl-3 col-xxl-4 col-lg-12 col-md-12">
            <div className="row">
              <div className="col-xl-12 col-lg-6">
                <div className="card">
                  <div className="card-header border-0 pb-0">
                    <h4 className="card-title">HISTORIA</h4>
                  </div>
                  <div className="card-body">
                    <div className="widget-timeline-icon">
                      <ul className="timeline">
                        <li>
                          <div className="icon bg-primary" />
                          <a className="timeline-panel text-muted">
                            <h4 className="mb-2">Pedido emitido</h4>
                            <p className="fs-15 mb-0 ">
                            {(showDetailOrderSelected && showDetailOrderSelected.dateRegistered && showDetailOrderSelected.dateRegistered.date)}
                            </p>
                          </a>
                        </li>
                        {
                          (showDetailOrderSelected.state && showDetailOrderSelected.state >= 9) &&
                          <li>
                          <div className="icon bg-primary" />
                          <a className="timeline-panel text-muted">
                            <h4 className="mb-2">Pedido en camino</h4>
                            <p className="fs-15 mb-0 ">
                              {showDetailOrderSelected.deliveryInformation.shippingDate || ""}
                            </p>
                          </a>
                        </li>
                        }
                        {
                          (showDetailOrderSelected && showDetailOrderSelected.state === 10) &&
                        <li>
                          <div className="icon bg-primary" />
                          <a className="timeline-panel text-muted">
                            <h4 className="mb-2">Pedido entregado</h4>
                            <p className="fs-15 mb-0 ">
                              {showDetailOrderSelected.deliveryInformation.arrivalDate || ""}
                            </p>
                          </a>
                        </li>
                        }
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 col-lg-6 ">
                <div className="card h-auto">
                  {/* <div className="card-body text-center">
                    <img
                      src={avatar1}
                      width={150}
                      className="rounded-circle mb-4"
                      alt="avatar1"
                    />
                    <h4 className="mb-3 text-black font-w600">James Witwitcky</h4>
                    <Link to="/order" className="btn btn-primary light btn-xs">
                      Customer
                    </Link>
                  </div> */}
                  <div className="card bg-secondary sticky mb-0">
                    <div className="card-header border-0 pb-0">
                      <h5 className="card-title text-white mt-2">NOTA</h5>
                    </div>
                    <div className="card-body pt-3">
                      <p className="fs-14 op7 text-white">
                      {
                        (showDetailOrderSelected.state && showDetailOrderSelected.state === 2)
                        &&
                        "ESPERA QUE EL ESTADO SE ENCUENTRE EN ''LISTO PARA LLEVAR'', PARA TOMAR EL PEDIDO."
                      }                      
                      {
                        ((showDetailOrderSelected.state && showDetailOrderSelected.state === 8) ||
                        (showDetailOrderSelected.state && showDetailOrderSelected.state === 7))
                        &&
                        "PRESIONA EL BOTON ''VOY EN CAMINO'' PARA INFORMAR QUE TE DIRIGES A TU DESTINO."
                      }
                         {
                        (showDetailOrderSelected.state && showDetailOrderSelected.state === 9)
                        &&
                        "PRESIONA EL BOTON ''ENTREGADO'' UNA VEZ REALIZADO EL COBRO, YA SEA EN EFECTIVO O CON POS"
                      }
                         {
                        (showDetailOrderSelected.state && showDetailOrderSelected.state === 10)
                        &&
                          "LA ORDEN HA SIDO ENTREGADA CON EXITO."
                      }
                      </p>
                    </div>
                    <div className="card-footer border-0 py-4 bg-warning rounded-xl">
                      <div className="media align-items-center">
                        {
                          (showDetailOrderSelected && showDetailOrderSelected.state !== 10)
                          &&
                          <img
                          className="mr-3 to=-fluid rounded-circle"
                          width={50}
                          src={delivery}
                          alt="delivery"
                        />
                        }
                        <div className="media-body">
                        {
                          ((showDetailOrderSelected.state && showDetailOrderSelected.state === 8) ||
                          (showDetailOrderSelected.state && showDetailOrderSelected.state === 7)) &&
                          <Button type="primary" onClick={() => goToClient()}>
                            VOY EN CAMINO
                          </Button>
                        }
                        {
                          (showDetailOrderSelected.state && showDetailOrderSelected.state === 9) &&
                        <Button type="primary" onClick={() => finishDeliveryOnline(showDetailOrderSelected)}>
                            ENTREGAR
                          </Button>
                        }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      }
      </div>
    </>
  );
};

export default DeliveryDetail;
