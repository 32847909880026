import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Rating from "@material-ui/lab/Rating";
import { Dropdown, Tab, Nav } from "react-bootstrap";
import ChatBox from "../../layouts/ChatBox";
import { Utils } from "../../../utils/utils"
import CountUp from "react-countup";
import TimeAgo from 'react-timeago'
import {STATES_ORDER, PAY_TYPES, USERS, DATA_DISH} from "../../../constants";
import { Table, Button, Space, Modal, InputNumber, Card, Avatar, Checkbox, List, Form, Input, notification, Alert, Select } from 'antd';
//Image
import pic1 from "../../.././images/dish/pic1.jpg";
import { EditOutlined, EllipsisOutlined, SettingOutlined, ShopOutlined} from '@ant-design/icons';
import { ALLMENU, GENERAL_CATEGORIES } from "../../../constants";
import spanishStrings from 'react-timeago/lib/language-strings/es'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import RestomaticOnlineServices from "../../../services/services";
import axios from "axios";

const lastCommandNumber = localStorage.getItem("lastBillNumber") || null
const { Option } = Select;
const formatter = buildFormatter(spanishStrings)
const moment = require('moment-timezone');
const { Meta } = Card;

const Analytics = ({ reference, getAllOrdersRef, getAllMotorcyclesRef, getAllDishes, getConfigDisplayRef, showSpinner, closeSpinner, getAllTablesRef }) => {
  const role = localStorage.getItem("role")
  const cod = localStorage.getItem("workerCod")
  const [toggle, setToggle] = useState("");
  const [dishesBD, setDishesBD] = useState([])
  const [waitressSelected, setWaitressSelected] = useState(null)
  const [deliverySelected, setDeliverySelected] = useState(null)
  const [showModalWaitres, setShowModalWaitres] = useState(false)
  const [motorcycleOnlineSelected, setMotorcycleOnlineSelected] = useState(null)
  const [tableSelectedUI, setTableSelectedUI] = useState(null)
  const [showModalDeliveryFromRestaurant, setShowModalDeliveryFromRestaurant] = useState(false)
  const [allOrdersLoaded, setAllOrdersLoaded] = useState(false)

  const onClickListClientsDelivery = (name) => setToggle(toggle === name ? "" : name);
  useEffect(() => {
    if (!allOrdersBDLoaded) getAllOrdersOnlineBD();
    if (!allTablesLoaded) getAllTablesFirebase();
    if (!allDishesLoaded) getAllDishesFirebase();
    if (infoForDelivery && infoForDelivery.phone) {
      confirmRegisterDraftOrder()
      setInfoForDelivery(null)
      console.log("es delivery")
    }
    // if (allProducts.length === 0) setAllProducts(ALLMENU)
    if (categorySelected !== null && productWasFiltered) filterAllProducts()
    // if (allOrdersBD.length === 0 && !allOrdersLoaded) getAllOrdersFirebase()
  })
  const [infoClientFromRoom, setInfoClientFromRoom] = useState(null)
  const [configLoaded, setConfigLoaded] = useState(null)
  const [allMotorcyclesBD, setAllMotorcyclesBD] = useState([])
  const [noteForMotorcycle, setNoteForMotorcycle] = useState("");
  const [isRegisteredForDelivery, setIsRegisteredForDelivery] = useState(false);
  const [editIsActive, setEditIsActive] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showNewCommand, setShowNewCommand] = useState(false);
  const [allProducts, setAllProducts] = useState([])
  const [newListFiltered, setNewListFiltered] = useState([])
  const [selectProductToAdd, setSelectProductToAdd] = useState(false)
  const [categorySelected, setCategorySelected] = useState(1)
  const [productWasFiltered, setProductWasFiltered] = useState(false)
  const [dishSelected, setDishSelected] = useState({});
  const [infoDishSelected, setInfoDishSelected] = useState({quantity: 1, type: null})
  const [finalOrder, setFinalOrder] = useState({id: null, waitressCode: null, products: [], state: 1, isDelivery: false})
  const [listDishesConfirmed, setListDishesConfirmed] = useState([])
  const [orderByShowUI, setOrderByShowUI] = useState([])
  const [amountForPay, setAmountForPay] = useState(0)
  const [selectDishForEdit, setSelectDishForEdit] = useState({})
  const [showModalConfirmDishDelete, setShowModalConfirmDishDelete] = useState(false)
  const [allOrdersBD, setAllOrdersBD] = useState([])
  const [totalAmountUI, setTotalAmountUI] = useState(null)
  const [isWithDelivery, setIsWithDelivery] = useState(false)
  const [showModalDelivery, setShowModalDelivery] = useState(false)
  const [showModalSelectTable, setShowModalSelectTable] = useState(false)
  const [showModalOrdersToday, setShowModalOrdersToday] = useState(false)
  const [tableSelected, setTableSelected] = useState({label: "MESA", tableId: null})

  const [allOrdersToday, setAllOrdersToday] = useState([])
  const [orderSelectedForEdit, setOrderSelectedForEdit] = useState({})
  const [showModalForDelivery, setShowModalForDelivery] = useState(false)
  const [deliveries, setDeliveries] = useState([])
  const [infoForDelivery, setInfoForDelivery] = useState({motorycleAssigned : ""})
  const [clientPayType, setClientPayType] = useState(null)
  const [clientPayAmount, setClientPayAmount] = useState(null)
  const [clientAmountReturn, setClientAmountReturn] = useState(null)
  const [withAddress, setWithAddress] = useState(false)
  const [openModalForPay, setOpenModalForPay] = useState(false);
  const [orderSelectedForPay, setOrderSelectedForPay] = useState(null)
  const [addingDish, setAddingDish] = useState(false)
  const [allTablesBD, setAllTablesBD] = useState([])
  const [isUsing, setIsUsing] = useState(false)
  const [withDeliveryLocal, setWithDeliveryLocal] = useState(false)
  const [allDishesLoaded, setAllDishesLoaded] = useState(false)
  const [allTablesLoaded, setAllTablesLoaded] = useState(false)
  const [allOrdersOnlineBD, setAllOrdersOnlineBD] = useState([])
  const [allOrdersBDLoaded, setAllOrdersBDLoaded] = useState(false)
  
  const getAllOrdersOnlineBD = () => {
    fetch("https://services-restaurant.elreydelsabor.pe/all-orders-online")
    .then(res =>res.json())
    .then(
      (result) => {
        console.log(result)
        const auxResult = result && result.map(e => {
          if (e.deliveryInformation && typeof (e.deliveryInformation) === "string") e.deliveryInformation = JSON.parse(e.deliveryInformation)
          if (e.products && typeof (e.products) === "string") e.products = JSON.parse(e.products)
          if (e.dateRegistered && typeof (e.dateRegistered) === "string") e.dateRegistered = JSON.parse(e.dateRegistered)
          e.payType = Number(e.payType);

          return e;
        })
      setAllOrdersBDLoaded(true)
        console.log(auxResult)
        setAllOrdersOnlineBD(auxResult)
      },
      // Nota: es importante manejar errores aquí y no en 
      // un bloque catch() para que no interceptemos errores
      // de errores reales en los componentes.
      (error) => {
       console.log(error)
      }
    )
 }
 
  const getAllTablesFirebase = () => {
    fetch("https://services-restaurant.elreydelsabor.pe/all-tables")
    .then(res => {
      setAllTablesLoaded(true)
      res.json()
    })
    .then(
      (result) => {
        console.log(result)
        setAllTablesBD(result)
      },
      // Nota: es importante manejar errores aquí y no en 
      // un bloque catch() para que no interceptemos errores
      // de errores reales en los componentes.
      (error) => {
       console.log(error)
      }
      )
    }

  const getAllOrdersFirebase = () => {
    fetch("https://services-restaurant.elreydelsabor.pe/all-orders")
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
          if (result.deliveryInformation && typeof (result.deliveryInformation) === "string") result.deliveryInformation = JSON.parse(result.deliveryInformation)
          if (result.products && typeof (result.products) === "string") result.products = JSON.parse(result.products)
          if (result.callCenterAssignedUI && typeof (result.callCenterAssignedUI) === "string") result.callCenterAssignedUI = JSON.parse(result.callCenterAssignedUI)
          if (result.waitressAssignedUI && typeof (result.waitressAssignedUI) === "string") result.waitressAssignedUI = JSON.parse(result.waitressAssignedUI)
          setAllOrdersBD(result)
          setAllOrdersLoaded(true)
        },
        // Nota: es importante manejar errores aquí y no en 
        // un bloque catch() para que no interceptemos errores
        // de errores reales en los componentes.
        (error) => {
         console.log(error)
        }
      )
    }
    const getAllDishesFirebase = () => {
      fetch("https://services-restaurant.elreydelsabor.pe/all-dishes")
      .then(res => {        
        setAllDishesLoaded(true)
        res.json()})
      .then(
        (result) => {
          console.log(result)
          setDishesBD(result)
          setAllProducts(result)
        },
        // Nota: es importante manejar errores aquí y no en 
        // un bloque catch() para que no interceptemos errores
        // de errores reales en los componentes.
        (error) => {
         console.log(error)
        }
      )
      }

console.log(allOrdersBD)
    const openNotification = () => {
      notification.success({
        message: `Registro de pedido`,
        description:
          'Pedido registrado con éxito'
      });
    };
    const openNotificationOnline = () => {
      notification.success({
        message: `Procesar pedido online`,
        description:
          'Pedido procesado con éxito'
      });
    };

  const showModal = () => {
    setIsModalVisible(true);
    setDishSelected({})
    setInfoDishSelected({quantity: 1, type: null})
  };
  const showModalOrdersTodayUI = () => {
    getAllOrdersFirebase()
    setShowModalOrdersToday(true);
    // setOrderSelectedForEdit({})
    // setEditIsActive(false)
    // setShowNewCommand(false)
    let aux = []
    let auxResult = false
    console.log(allOrdersBD)
    allOrdersBD.forEach(e => {
      if (e && e.state >= 2) {
        auxResult = filterIsBetweenToday(e.dateRegistered)
        if (auxResult) {          
          
          if (e.deliveryInformation && typeof (e.deliveryInformation) === "string") e.deliveryInformation = JSON.parse(e.deliveryInformation)
          if (e.products && typeof (e.products) === "string") e.products = JSON.parse(e.products)
          if (e.callCenterAssignedUI && typeof (e.callCenterAssignedUI) === "string") e.callCenterAssignedUI = JSON.parse(e.callCenterAssignedUI)
          if (e.waitressAssignedUI && typeof (e.waitressAssignedUI) === "string") e.waitressAssignedUI = JSON.parse(e.waitressAssignedUI)
          
          aux.push(e)
        } 
      
        console.log(aux, "aux")
      if (aux) {
        let newList = [...aux]
        const auxResult = newList.reverse()
        console.log(auxResult, "auxResult")
        setAllOrdersToday(auxResult)
        }
      }
    })
    // setDishSelected({})
    // setInfoDishSelected({quantity: 1, type: null})
  };
  
  const deleteDish = (obj) => {
    let result = []
    
    const service = new RestomaticOnlineServices(reference);
    if (editIsActive) {
      const auxFinalOrder = { ...orderSelectedForEdit }
      
      if (obj) {
        console.log(obj)
        console.log(auxFinalOrder)
        auxFinalOrder.products.forEach(k => {
          if (k && k.keyBD) {
           let auxK = {...k}
           console.log(auxK)
           auxK.soldsUnids = (auxK.soldsUnids && auxK.soldsUnids !== 0) ? auxK.soldsUnids : 0
           auxK.soldsUnids = auxK.soldsUnids - k.quantity
           if (auxK && auxK.soldsUnids > 0 && allProducts) {
             let result = {}
             allProducts.forEach(e => {
               if (e && e.id === obj.id) {
                 result = obj
                 result.soldsUnids = result.soldsUnids - k.quantity
                 service.updateDishUsed(result.keyBD, result).then(res => {
                  console.log("BEBIDAS VENDIDOA ACTUALIZADOS")
                })
               }
             })
           }
            service.updateDishUsed(auxK.keyBD, auxK).then(res => {
              if (auxK.drinkIdIncluded) {
                allProducts.forEach(e => {
                  let result = {}
                  if (e && auxK.drinkIdIncluded && e.id && e.id === auxK.drinkIdIncluded) {
                    result = obj
                    result.soldsUnids = result.soldsUnids - k.quantity
                    service.updateDishUsed(result.keyBD, result).then(res => {
                     console.log("BEBIDAS VENDIDOA ACTUALIZADOS")
                   })
                  }
                })
              }
              console.log("PLATOS VENDIDOS ACTUALIZADOS")
            })
          }
        })
        auxFinalOrder.products.forEach(e => {
          if (!(e.quantity === obj.quantity && e.description === obj.description && e.amount === obj.amount)) result.push(e)
        })
        console.log(result)
        auxFinalOrder.products = result
        const amountObj = calculateTotalAmount(result)
  
        auxFinalOrder.totalAmount = amountObj
        service.updateOrderDB(auxFinalOrder.keyBD, auxFinalOrder).then(res => {    
          console.log(amountObj)
          setOrderSelectedForEdit(auxFinalOrder)
          setTotalAmountUI(amountObj)
          getAllOrdersFirebase()
          setSelectDishForEdit({})
          closeModalConfirmDeleteDish()
        })
      }
    } else {
      const auxFinalOrder = { ...finalOrder }
      const auxListDishesConfirmed = [...listDishesConfirmed]
      if (obj) {
        console.log(obj, "dish a borrar")
        console.log(orderByShowUI)
        auxFinalOrder.products.forEach(e => {
          if (!(e.quantity === obj.quantity && e.description === obj.description && e.amount === obj.amount)) result.push(e)
        })
        console.log(result)
        setOrderByShowUI(result)
        console.log(result)
        const amountObj = calculateTotalAmount(result)
  
        const service = new RestomaticOnlineServices(reference);
        auxFinalOrder.products = result
        auxFinalOrder.totalAmount = amountObj
        console.log(auxFinalOrder)
        let auxNewListConfirmed = []
        console.log(auxListDishesConfirmed)
        if (auxListDishesConfirmed) {
          auxListDishesConfirmed.forEach(k => {
            if (!(k.id === obj.id)) auxNewListConfirmed.push(k)
          })
        }
        console.log(auxNewListConfirmed)
        setListDishesConfirmed(auxNewListConfirmed || [])
        // service.updateOrderDB(auxFinalOrder.keyBD, auxFinalOrder).then(res => {
          console.log(amountObj)
          setFinalOrder(auxFinalOrder)
          getAllOrdersFirebase()
          setTotalAmountUI(amountObj)
          setSelectDishForEdit({})
          closeModalConfirmDeleteDish()

        // })
      }
    }
  }
  
const columns = [
  {
    title: 'Cod',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Cantidad',
    dataIndex: 'quantity',
    key: 'quantity',
    render: text => <a>{text}</a>,
  },
  {
    title: 'Descripción',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Importe',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Acciones',
    key: 'action',
    render: (text, record) => (
      <Space size="middle">
        <a onClick={() => openModalConfirmDeleteDish()}>Borrar</a>
      </Space>
    ),
  },
  // {
  //   title: 'Tags',
  //   key: 'tags',
  //   dataIndex: 'tags',
  //   render: tags => (
  //     <>
  //       {tags.map(tag => {
  //         let color = tag.length > 5 ? 'geekblue' : 'green';
  //         if (tag === 'loser') {
  //           color = 'volcano';
  //         }
  //         return (
  //           <Tag color={color} key={tag}>
  //             {tag.toUpperCase()}
  //           </Tag>
  //         );
  //       })}
  //     </>
  //   ),
  // },
  // 
];

const cancelOrder = () => {
  closeSpinner()
  setShowNewCommand(false)
  setOrderByShowUI([])
  setDishSelected({})
  setFinalOrder({id: null, waitressCode: null, products: [], state: 1})
  setTableSelected({label: "MESA", tableId: null})
  setTotalAmountUI(null)
}

const getLastOrderRegistered = () => {
    // result = 0;
}
console.log(allOrdersBD)



  const showNewCommandUI = () => {
    showSpinner()
    console.log(allOrdersBD)
    let getLastSaleId =  (allOrdersBD && allOrdersBD.length > 0) ? allOrdersBD[allOrdersBD.length-1] :  null
    console.log(getLastSaleId)
    const auxFinalOrder = { ...finalOrder }

    if (showNewCommand || (showNewCommand && orderByShowUI.length)) return cancelOrder()
      console.log("entro")
      auxFinalOrder.saleId = 0
      auxFinalOrder.saleId = Math.floor(Math.random() * (1000000 - 10000)) + 100
      if (auxFinalOrder.saleId && getLastSaleId && (auxFinalOrder.saleId === getLastSaleId.saleId)) {
        console.log("idSale ya esta registrado")
        return showNewCommandUI()
      }
      console.log(allOrdersBD)
      setShowNewCommand(true)
      auxFinalOrder.id = null
      if (allOrdersBD.length === 0) {
        auxFinalOrder.id = 1;
      }
      else {
        if (!editIsActive) auxFinalOrder.id = allOrdersBD.length + 1;
        else auxFinalOrder.id = orderSelectedForEdit.id;
      }
      const momentNow = moment.tz(new Date(), "America/Lima").format()
      // const momentNow = Date.now();
      auxFinalOrder.dateRegistered = JSON.stringify({"date": momentNow});
      auxFinalOrder.tableId = tableSelected.tableId || 0;
      auxFinalOrder.isDelivery = false || 0;
      auxFinalOrder.fromLocal = ""
      auxFinalOrder.fromLocal = "local-restaurant"
      auxFinalOrder.totalAmount = 0
      auxFinalOrder.waitressSelected = ""
      
      // auxFinalOrder.id = auxFinalOrder.id

      // delete auxFinalOrder.products
      delete auxFinalOrder.waitressCode
      // delete auxFinalOrder.id
      auxFinalOrder.products = ""
      console.log(auxFinalOrder)
      if (!auxFinalOrder.payType) auxFinalOrder.payType = 0
        // axios.post("http://localhost:3000/add-order", auxFinalOrder,
        axios.post("https://services-restaurant.elreydelsabor.pe/add-order", auxFinalOrder,
          )
         .then(
           (result) => {
            console.log(result)
              closeSpinner()
              console.log(auxFinalOrder)
              setAllOrdersBD([])
              console.log(allOrdersBD)
              setFinalOrder(auxFinalOrder)
              getAllOrdersFirebase()
              setTotalAmountUI(null)
           },
             (error) => {
             console.log(error)
             }
           )
  }


  console.log(finalOrder)
  const closeNewCommandUI = () => setShowNewCommand(false)

   const filterIsBetweenToday = (date) => {
    let result = false;
    const todayDate = moment.tz(new Date(), "America/Lima").format()
    // console.log(todayDate)
    let initDay = todayDate.split("T")
    let finalDay = todayDate.split("T")
    initDay = initDay[0]+"T00:00:00-05:00"
    finalDay = finalDay[0]+"T23:59:59-05:00"
    initDay = Date.parse(initDay)
    finalDay = Date.parse(finalDay)
    date = Date.parse(date)
    console.log(initDay)
    console.log(finalDay)
    console.log(date)
    // if (moment.tz(Date.parse(date).isAfter(Date.parse(initDay)))) result = true
    if (initDay <= date && date <= finalDay) result = true
    return result
  }
  const getConfigUpdated = () => {
    let allOrdersAux = []
    let allIds = []
    getConfigDisplayRef.on("value", (snapshot) => {
      let result = []
      snapshot.forEach(e => {
        allIds.push(e.key)
      })
         if (snapshot.val() !== null) {
           allOrdersAux = Object.values(snapshot.val()) && Object.values(snapshot.val());
           console.log(allOrdersAux)
           allOrdersAux = Object.values(allOrdersAux)
           allOrdersAux = allOrdersAux.map((e, index) => {
            console.log(allIds)
           if (allIds.length) {
           allIds.forEach(k => {
             e.keyBD = `${allIds[index]}`
           })
           }
           return e
        })
           console.log(allOrdersAux)
          if (allOrdersAux && allOrdersAux[0]) setConfigLoaded(allOrdersAux[0])
          console.log(allOrdersAux[0])
          
         }
         return;
    })
  }
  console.log(configLoaded)
  console.log(waitressSelected)

  const updateTable = (table) => {
    const service = new RestomaticOnlineServices(reference)
    table.state = 2
    service.updateTableDB(table.keyBD, table).then(res => {
      console.log('Mesa actualizada')
    })
  }

  const confirmRegisterDraftOrder = (fromRoom) => {
    console.log(waitressSelected)
    let auxFinalOrder = { ...finalOrder }
    if (waitressSelected) {
      auxFinalOrder.waitressSelected = ""
      auxFinalOrder.waitressSelected = waitressSelected
    }
    console.log(finalOrder)
    console.log(isWithDelivery)
    console.log(auxFinalOrder)    
    showSpinner()
    // setTimeout(() => {
      if (isWithDelivery) {
        if (auxFinalOrder && deliverySelected) {
          auxFinalOrder.motorcycleAssisnedFromRest = null
          auxFinalOrder.motorcycleAssisnedFromRest = deliverySelected
        }
        // open modal info
        // setShowModalDelivery(true)
        console.log(infoForDelivery)
        auxFinalOrder.state = 2;
        console.log(clientPayType)
        auxFinalOrder.isDelivery = 1
        // auxFinalOrder.deliveryInformation = infoForDelivery 
        // if (clientPayType) auxFinalOrder.deliveryInformation.payType = clientPayType
        if (auxFinalOrder.deliveryInformation) {
          console.log("con deliveryInformation")
          auxFinalOrder.deliveryInformation.state = 2
          if (infoForDelivery && infoForDelivery.motorycleAssigned) {
            auxFinalOrder.deliveryInformation.motorycleAssigned = ""
            auxFinalOrder.deliveryInformation.motorycleAssigned = infoForDelivery.motorycleAssigned || null
            auxFinalOrder.totalAmount = auxFinalOrder.totalAmount + 3
          }
          if (infoForDelivery && infoForDelivery.address) auxFinalOrder.deliveryInformation.address = infoForDelivery.address || null
          if (infoForDelivery && infoForDelivery.phone) auxFinalOrder.deliveryInformation.phone = infoForDelivery.phone || null
          if (infoForDelivery && infoForDelivery.reference) auxFinalOrder.deliveryInformation.reference = infoForDelivery.reference || null
          if (infoForDelivery && infoForDelivery.address) {
            auxFinalOrder.deliveryInformation.fromLocal = ""
            auxFinalOrder.deliveryInformation.fromLocal = "local-delivery"
          }
          if (infoForDelivery) {
            auxFinalOrder.deliveryInformation.noteToDelivery = ""
            auxFinalOrder.deliveryInformation.noteToDelivery = infoForDelivery.noteToDelivery || null
            auxFinalOrder.deliveryInformation.noteToCheff = ""
            auxFinalOrder.deliveryInformation.noteToCheff = infoForDelivery.noteToCheff || null
          }
          if (infoForDelivery && infoForDelivery.motorycleAssigned === "" && auxFinalOrder.deliveryInformation.payType) {
            console.log("aqui")
            if (noteForMotorcycle) {
              auxFinalOrder.deliveryInformation.noteToCheff = ""
              auxFinalOrder.deliveryInformation.noteToCheff = noteForMotorcycle || null
            }
            auxFinalOrder.deliveryInformation.fromLocal = ""
            auxFinalOrder.deliveryInformation.fromLocal = "local-restaurant"
          }
          if (infoForDelivery === null && auxFinalOrder.deliveryInformation) {
            console.log("aqui")
            console.log(noteForMotorcycle)
            if (noteForMotorcycle) {
              auxFinalOrder.deliveryInformation.noteToCheff = ""
              auxFinalOrder.deliveryInformation.noteToCheff = noteForMotorcycle || null
            }
            auxFinalOrder.deliveryInformation.fromLocal = ""
            auxFinalOrder.deliveryInformation.fromLocal = "local-restaurant"
          }
          auxFinalOrder.deliveryInformation.payType = clientPayType
          auxFinalOrder.deliveryInformation.amountReturn = clientAmountReturn ? clientAmountReturn - 3 : null
          auxFinalOrder.deliveryInformation.clientPayAmount = clientPayAmount
          let isWithDiffKitchens = false
          console.log("AQUI")
          console.log(infoClientFromRoom)
          if (infoClientFromRoom && auxFinalOrder && auxFinalOrder.deliveryInformation && auxFinalOrder.isDelivery === 1 && auxFinalOrder.deliveryInformation.fromLocal === "local-restaurant") {
            auxFinalOrder.deliveryInformation.infoClientFromRoom = infoClientFromRoom || null
          }
          // SI ES DESDE CALLCENTER
          if (auxFinalOrder && auxFinalOrder.isDelivery === 1 && auxFinalOrder.callCenterAssignedUI) {
            if (auxFinalOrder && auxFinalOrder.products) {
              let auxKit = []
              auxKit = auxFinalOrder.products.map(k => k.kitchenAuthorized.length)
              console.log(auxKit)
              if (auxKit.length > 1) {
                isWithDiffKitchens = !auxKit.every(val => val === auxKit[0]);
              }
            }
          }
          // FIN
          console.log(isWithDiffKitchens, "isWithDiffKitchens")
          if (auxFinalOrder && auxFinalOrder.products && isWithDiffKitchens) {
            auxFinalOrder.isWithDiffKitchens = isWithDiffKitchens
          }
          console.log(auxFinalOrder)
          if (auxFinalOrder.withDeliveryFromLocal) auxFinalOrder.totalAmount = auxFinalOrder.totalAmount + 3
          auxFinalOrder.urlToClient = "";
          auxFinalOrder.urlToClient = (auxFinalOrder && auxFinalOrder.saleId) ? `https://elreydelsabor.pe/sumary-delivery/${auxFinalOrder.saleId}` : null;
          
          const service = new RestomaticOnlineServices(reference);
          const serviceConfig = new RestomaticOnlineServices(reference);
          if (auxFinalOrder.callCenterAssignedUI) auxFinalOrder.callCenterAssignedUI = JSON.stringify(auxFinalOrder.callCenterAssignedUI)
          if (auxFinalOrder.waitressAssignedUI) auxFinalOrder.waitressAssignedUI = JSON.stringify(auxFinalOrder.waitressAssignedUI)
          if (auxFinalOrder.deliveryInformation) auxFinalOrder.deliveryInformation = JSON.stringify(auxFinalOrder.deliveryInformation)
          if (auxFinalOrder.isDelivery === 1 && auxFinalOrder.waitressSelected && auxFinalOrder.deliveryInformation) auxFinalOrder.fromLocal = "local-restaurant"
          if (auxFinalOrder.products) auxFinalOrder.products = JSON.stringify(auxFinalOrder.products)
          
          console.log(auxFinalOrder)
          axios.put(`https://services-restaurant.elreydelsabor.pe/update-order/${auxFinalOrder.id}`, auxFinalOrder, {
            headers: {
                      'Content-Type':'application/json',
                    },
            })
           .then(
             (result) => {
              console.log(result)

            //   if (fromRoom) {
            //     let configLoadedAux = { ...configLoaded }
            //     configLoadedAux.updated = true
            //     console.log(configLoadedAux)
            //     serviceConfig.updateConfigRoom(configLoadedAux.keyBD, configLoadedAux).then(res => {
            //       // showModalOrdersTodayUI()
            //       console.log("SE REGISTRO EN SALA")
            //     }, error => {
            //       console.log("NO SE REGISTRO EN SALA")
            //     })
            //   }
              setWaitressSelected(null)
              getAllOrdersFirebase()
              setDeliverySelected(null)
              setWithDeliveryLocal(false)
              closeSpinner()
              setIsRegisteredForDelivery(true)
              setNoteForMotorcycle("")
              setInfoForDelivery(null)
              openNotification()
              setIsRegisteredForDelivery(false)
              setTotalAmountUI(null)
              cancelOrder()
              setOrderSelectedForEdit({})
              setOrderByShowUI([])
              setListDishesConfirmed([])
              setIsWithDelivery(false)
              setAmountForPay(0)
              setClientPayType(null)
              setInfoClientFromRoom(null)
             }, (error => {
              console.log(error)
             }))

          // service.saveOrder(auxFinalOrder).then(res => {
          //   setWaitressSelected(null)
          //   getAllOrdersFirebase()
          //   setDeliverySelected(null)
          //   setWithDeliveryLocal(false)
          //   closeSpinner()
          //   if (fromRoom) {
          //     let configLoadedAux = { ...configLoaded }
          //     configLoadedAux.updated = true
          //     console.log(configLoadedAux)
          //     serviceConfig.updateConfigRoom(configLoadedAux.keyBD, configLoadedAux).then(res => {
          //       // showModalOrdersTodayUI()
          //       console.log("SE REGISTRO EN SALA")
          //     }, error => {
          //       console.log("NO SE REGISTRO EN SALA")
          //     })
          //   }
          //   console.log(auxFinalOrder)
          //   auxFinalOrder.products.forEach(k => {
          //     if (k && k.keyBD) {
          //     let auxK = {...k}
          //     console.log(auxK)
          //     auxK.soldsUnids = (auxK.soldsUnids && auxK.soldsUnids !== 0) ? auxK.soldsUnids : 0
          //     auxK.soldsUnids = auxK.soldsUnids + k.quantity
          //     if (auxK && auxK.drinkIdIncluded && allProducts) {
          //       let result = {}
          //       allProducts.forEach(e => {
          //         if (e && e.id === auxK.drinkIdIncluded) {
          //           result = e
          //           result.soldsUnids = result.soldsUnids + k.quantity
          //           service.updateDishUsed(result.keyBD, result).then(res => {
          //             console.log("BEBIDAS VENDIDOA ACTUALIZADOS")
          //           })
          //         }
          //       })
          //     }
          //       service.updateDishUsed(auxK.keyBD, auxK).then(res => {
          //         console.log("PLATOS VENDIDOS ACTUALIZADOS")
          //       })
          //     }
          //   })
          //   setIsRegisteredForDelivery(true)
          //   setNoteForMotorcycle("")
          //   setInfoForDelivery(null)
          //   openNotification()
          //   setIsRegisteredForDelivery(false)
          //   setTotalAmountUI(null)
          //   cancelOrder()
          //   setOrderSelectedForEdit({})
          //   setOrderByShowUI([])
          //   setListDishesConfirmed([])
          //   setIsWithDelivery(false)
          //   setAmountForPay(0)
          //   setClientPayType(null)
          //   setInfoClientFromRoom(null)
          // })
        }
        else {
          console.log(clientPayType)
          console.log("delivery sin deliveryInformation")
          auxFinalOrder.state = 2;
          auxFinalOrder.fromLocal = ""
          auxFinalOrder.fromLocal = "local-restaurant"
          console.log(infoForDelivery)
          if (infoForDelivery) {
            auxFinalOrder.motorycleAssigned = ""
            auxFinalOrder.motorycleAssigned = infoForDelivery.motorycleAssigned || null
            auxFinalOrder.noteToDelivery = ""
            auxFinalOrder.noteToDelivery = infoForDelivery.noteToDelivery || null
            auxFinalOrder.noteToCheff = ""
            auxFinalOrder.noteToCheff = infoForDelivery.noteToCheff || null
          }
          // auxFinalOrder.noteForMotorcycle = ""
          // console.log(noteForMotorcycle)
          // auxFinalOrder.noteForMotorcycle = noteForMotorcycle || null
          // auxFinalOrder.deliveryInformation = {payType: null}
          // auxFinalOrder.deliveryInformation.payType = clientPayType
          if (auxFinalOrder.callCenterAssignedUI) auxFinalOrder.callCenterAssignedUI = JSON.stringify(auxFinalOrder.callCenterAssignedUI)
          if (auxFinalOrder.waitressAssignedUI) auxFinalOrder.waitressAssignedUI = JSON.stringify(auxFinalOrder.waitressAssignedUI)
          console.log(auxFinalOrder)
          axios.put(`https://services-restaurant.elreydelsabor.pe/update-order/${auxFinalOrder.id}`, auxFinalOrder, {
            headers: {
                      'Content-Type':'application/json',
                    },
            })
           .then(
             (result) => {
              console.log(result)
                 setWithDeliveryLocal(false)
                 closeSpinner()
                 setNoteForMotorcycle("")
                 setWaitressSelected(null)
                 setDeliverySelected(null)
                 setInfoForDelivery(null)
                 openNotification()
                 cancelOrder()
                 setTotalAmountUI(null)
                 setOrderSelectedForEdit({})
                 setOrderByShowUI([])
                 setListDishesConfirmed([])
                 setAmountForPay(0)
                 setIsWithDelivery(false)
                 getAllOrdersFirebase()
                 setClientPayType(null)

             },
             (error) => {
             console.log(error)
             })
        }
          // const service = new RestomaticOnlineServices(reference);
          // service.saveOrder(auxFinalOrder).then(res => {
          //   setWithDeliveryLocal(false)
          //   closeSpinner()
          //   setNoteForMotorcycle("")
          //   setWaitressSelected(null)
          //   setDeliverySelected(null)
          //   // showModalOrdersTodayUI()
          //   setInfoForDelivery(null)
          //   openNotification()
          //   cancelOrder()
          //   setTotalAmountUI(null)
          //   setOrderSelectedForEdit({})
          //   setOrderByShowUI([])
          //   setListDishesConfirmed([])
          //   setAmountForPay(0)
          //   setIsWithDelivery(false)
          //   getAllOrdersFirebase()
          //   setClientPayType(null)
          //   auxFinalOrder.products.forEach(k => {
          //     if (k && k.keyBD) {
          //     let auxK = {...k}
          //     console.log(auxK)
          //     auxK.soldsUnids = (auxK.soldsUnids && auxK.soldsUnids !== 0) ? auxK.soldsUnids : 0
          //     auxK.soldsUnids = auxK.soldsUnids + k.quantity
          //     if (auxK && auxK.drinkIdIncluded && allProducts) {
          //       let result = {}
          //       allProducts.forEach(e => {
          //         if (e && e.id === auxK.drinkIdIncluded) {
          //           result = e
          //           result.soldsUnids = result.soldsUnids + k.quantity
          //           service.updateDishUsed(result.keyBD, result).then(res => {
          //             console.log("BEBIDAS VENDIDOA ACTUALIZADOS")
          //           })
          //         }
          //       })
          //     }
          //       service.updateDishUsed(auxK.keyBD, auxK).then(res => {
          //         console.log("PLATOS VENDIDOS ACTUALIZADOS")
          //       })
          //     }
          //   })
          // })


        

      }
      else {
        //update last info
        console.log("PEDIDO PARA MESA")
        auxFinalOrder.state = 2;
        auxFinalOrder.payType = clientPayType || 0
        auxFinalOrder.fromLocal = ""
        auxFinalOrder.fromLocal = "local-restaurant"
        if (noteForMotorcycle) {
          auxFinalOrder.deliveryInformation = {}
          auxFinalOrder.deliveryInformation.noteToCheff = ""
          auxFinalOrder.deliveryInformation.noteToCheff = noteForMotorcycle
        }
        if (auxFinalOrder.deliveryInformation) {
          auxFinalOrder.deliveryInformation = JSON.stringify(auxFinalOrder.deliveryInformation);
        }
          auxFinalOrder.products = JSON.stringify(auxFinalOrder.products);
        
        console.log(auxFinalOrder, "auxFinalOrder")
        axios.put(`https://services-restaurant.elreydelsabor.pe/update-order/${auxFinalOrder.id}`, auxFinalOrder, {
          headers: {
                    'Content-Type':'application/json',
                  },
          })
         .then(
           (result) => {
            console.log(result)
          //  updateTable(tableSelected)
           closeSpinner()
           setNoteForMotorcycle("")
           setDeliverySelected(null)
           setWaitressSelected(null)
           // showModalOrdersTodayUI()
           openNotification()
           cancelOrder()
           setOrderSelectedForEdit({})
           setOrderByShowUI([])
           setListDishesConfirmed([])
           getAllOrdersFirebase()
           auxFinalOrder.products = JSON.parse(auxFinalOrder.products)
           auxFinalOrder.products.forEach(k => {
             if (k && k.keyBD) {
               let auxK = {...k}
               auxK.soldsUnids = (auxK.soldsUnids && auxK.soldsUnids !== 0) ? auxK.soldsUnids : 0
               auxK.soldsUnids = auxK.soldsUnids + k.quantity
               if (auxK && auxK.drinkIdIncluded && allProducts) {
                 let result = {}
                 allProducts.forEach(e => {
                   if (e && e.id === auxK.drinkIdIncluded) {
                     result = e
                     result.soldsUnids = result.soldsUnids + k.quantity
                  //    service.updateDishUsed(result.keyBD, result).then(res => {
                  //    console.log("BEBIDAS VENDIDOA ACTUALIZADOS")
                  //  })
                   }
                 })
               }
              //  service.updateDishUsed(auxK.keyBD, auxK).then(res => {
              //    console.log("PLATOS VENDIDOS ACTUALIZADOS")
              //  })
             }
           
           },
             (error) => {
             console.log(error)
             }
           )}
         )
      }
    // }, 10)
  }

  const filterAllProducts = () => {
    console.log("entro")
    console.log(categorySelected)
    let auxAllProducts = [];
    if (categorySelected && allProducts) {
      allProducts.map(e => {
        if (e.generalCategory === categorySelected) auxAllProducts.push(e)
      })
      console.log(auxAllProducts)
      if (auxAllProducts.length) {
        setNewListFiltered(auxAllProducts)
        setProductWasFiltered(false)
      }
    }
  }
  const showModalForDeliveryChatBox = (option) => {
    console.log(option)
    // if (option === "add") setShowModalForDelivery(true)
  }
  const showModalSelectTableUI = () => {
    setShowModalSelectTable(true)
    //  const service = new RestomaticOnlineServices(reference)

    //    const obj = {      
    //     state: 1,
    //     tableId: 12,
    //     title: "MESA"
    //    }
    //    service.insertDishes(obj)
  }
  const showModalDeliveryUI = () => {
    // setShowModalForDelivery(true)
    onClickListClientsDelivery("chatbox")
    getAllOrdersOnlineBD()
    getAllMotorcyclesFirebase()
  }


  const closeModalSelectTableUI = () => {
    setShowModalSelectTable(false)
  }
  const closeModalPayOrder = () => {
    setOpenModalForPay(false)
  }
  const openModalPayOrderUI = () => {
    setOpenModalForPay(true)
  }
  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectProductToAdd(false)
    setDishSelected({})

  };
  const handleCancelOrderToday = () => {
    setShowModalOrdersToday(false);
  };
  const setSelectProductToAddUI = (obj) => {
    console.log(obj, "agregar producto")
    setSelectProductToAdd(true)
    setDishSelected(obj)
  }
  const selectCategoryUI = (value) => {
    setCategorySelected(value)
    setProductWasFiltered(true)
  }
  const calculateTotalAmount = (auxList) => {
  let totalAmount = 0
  let onlyAmounts = []
  console.log(auxList)
  if (auxList.length) {
  onlyAmounts = auxList.map(e =>  {
    let result = 0
    result = e.amount * e.quantity
    return result
  })
  console.log(onlyAmounts)
  totalAmount = onlyAmounts.reduce((a,b) => a + b)
  console.log(totalAmount)
    }    
    if (totalAmount) setTotalAmountUI(totalAmount)
    return totalAmount;
  }
  const openNotificationEdit = () => {
    notification.success({
      message: `Actualización de pedido`,
      description:
        'Pedido actualziado con éxito'
    });
  };
  const confirmDish = (info) => {
    setAddingDish(true)
    showSpinner()
    let auxDishSelected = {...dishSelected}
    let auxFinalOrder = { ...orderSelectedForEdit }
    console.log(auxDishSelected)
    if (editIsActive) {
      setOrderSelectedForEdit({})
      console.log("aqui")
      console.log(auxFinalOrder)
      if (auxFinalOrder && !auxFinalOrder.products) auxFinalOrder.products = []
      console.log(info)
      console.log(auxDishSelected)
      if (auxDishSelected) {
        auxFinalOrder.products.push({
          amount: auxDishSelected.price,
          desc: auxDishSelected.desc || null,
          description: auxDishSelected.name, 
          quantity: info.quantity, 
          drinkIdIncluded: auxDishSelected.drinkIdIncluded || null,
          kitchenAuthorized: auxDishSelected.kitchenAuthorized || null, 
          chickenQuantity: auxDishSelected.chickenQuantity || null,
          keyBD: auxDishSelected.keyBD || null,
          soldsUnids: auxDishSelected.soldsUnids || null
        })

        const amountObj = calculateTotalAmount(auxFinalOrder.products)
        if (amountObj) auxFinalOrder.totalAmount = amountObj

        // auxFinalOrder.keyBD = auxFinalOrder.keyBD.replace("ORDERID", "")
        console.log(auxFinalOrder)
        
        auxFinalOrder.deliveryInformation = JSON.stringify(auxFinalOrder.deliveryInformation)
        auxFinalOrder.products = JSON.stringify(auxFinalOrder.products)
        auxFinalOrder.callCenterAssignedUI = JSON.stringify(auxFinalOrder.callCenterAssignedUI)

        axios.put(`https://services-restaurant.elreydelsabor.pe/update-order/${auxFinalOrder.id}`, auxFinalOrder, {
          headers: {
                    'Content-Type':'application/json',
                  },
          })
         .then(
           (result) => {
            console.log(result)
               setAddingDish(false)
               closeSpinner()
               console.log("actualizo")
               setSelectProductToAdd(false)
               setDishSelected({})
               setInfoDishSelected({quantity: 1, type: null})
               
              if (auxFinalOrder.deliveryInformation && typeof (auxFinalOrder.deliveryInformation) === "string") auxFinalOrder.deliveryInformation = JSON.parse(auxFinalOrder.deliveryInformation)
              if (auxFinalOrder.products && typeof (auxFinalOrder.products) === "string") auxFinalOrder.products = JSON.parse(auxFinalOrder.products)
              if (auxFinalOrder.callCenterAssignedUI && typeof (auxFinalOrder.callCenterAssignedUI) === "string") auxFinalOrder.callCenterAssignedUI = JSON.parse(auxFinalOrder.callCenterAssignedUI)
              if (auxFinalOrder.waitressAssignedUI && typeof (auxFinalOrder.waitressAssignedUI) === "string") auxFinalOrder.waitressAssignedUI = JSON.parse(auxFinalOrder.waitressAssignedUI)
               console.log(auxFinalOrder)
               setOrderSelectedForEdit(auxFinalOrder)
               openNotificationEdit()
           }, (error => {
            console.log(error)
           }))
        // const service = new RestomaticOnlineServices(reference);
        // service.updateOrderDB(auxFinalOrder.keyBD, auxFinalOrder).then(res => {
        //   setAddingDish(false)
        //   closeSpinner()
        //   console.log("actualizo")
        //   setSelectProductToAdd(false)
        //   setDishSelected({})
        //   setInfoDishSelected({quantity: 1, type: null})
        //   console.log(auxFinalOrder)
        //   setOrderSelectedForEdit(auxFinalOrder)
        //   openNotificationEdit()
        // })
      }
    } else {
      let auxFinalOrder = { ...finalOrder }
    let auxDishSelected = {...dishSelected}
    let auxFinalListDishesConfirmed = [...listDishesConfirmed]
    let auxList = []
    console.log(info)
    console.log(auxDishSelected)
    console.log(auxFinalListDishesConfirmed)
    console.log(auxFinalOrder)
    auxDishSelected.infoForOrder = info
    auxFinalListDishesConfirmed.push(auxDishSelected)
    console.log(auxFinalListDishesConfirmed)
    auxFinalListDishesConfirmed.forEach(e => {
      if (e && e.infoForOrder) {
        const obj = {
          id: e.id,
          quantity: e.infoForOrder.quantity,
          description: e.name,
          amount: e.price,          
          desc: auxDishSelected.desc || null,
          drinkIdIncluded: e.drinkIdIncluded || null,
          kitchenAuthorized: e.kitchenAuthorized || null,
          chickenQuantity: e.chickenQuantity || null,
          keyBD: e.keyBD || null,
          soldsUnids: e.soldsUnids || null
        }
        auxList.push(obj)
      }
    })
    // auxFinalOrder.id = 1;
    // auxFinalOrder.waitressCode = 2;
    // setShowNewCommand(false)
    if (auxFinalListDishesConfirmed) setListDishesConfirmed(auxFinalListDishesConfirmed)
    if (auxList) {
        console.log(auxList)
        auxFinalOrder.products = auxList
        const service = new RestomaticOnlineServices(reference);
        console.log(auxFinalOrder)
        const amountObj = calculateTotalAmount(auxList)
        if (amountObj) auxFinalOrder.totalAmount = amountObj
        // service.updateOrderDB(auxFinalOrder.keyBD, auxFinalOrder).then(res => {
          setAddingDish(false)
          closeSpinner()
          setInfoDishSelected({quantity: 1, type: null})
          setSelectProductToAdd(false)
          setDishSelected({})
          setFinalOrder(auxFinalOrder)
          setOrderByShowUI(auxList)
        // })
      }    
    }
  }
  const handleInfo = (evt, id) => {
    if (id === "amountForPay") {
      console.log(evt)
      console.log(id)
      setClientPayAmount(evt)
      const aux = evt - totalAmountUI
      setClientAmountReturn(aux)
      return setAmountForPay(evt)
    } else {
      let aux = {...infoDishSelected}
      if (evt && id) {
        aux[id] = evt;
        setInfoDishSelected(aux)
      }
    }
  }
  console.log(allProducts)
  console.log(orderByShowUI)
  console.log(finalOrder)
  console.log(categorySelected)
  console.log(listDishesConfirmed)
  console.log(dishSelected)
  console.log(infoDishSelected)
  const selectItemForDeleteByOrder = (evt, obj) => {
    console.log(obj)
    let aux = {...obj}
    aux.imgUrl = ""
    ALLMENU.forEach(e => {
      if (e.name === obj.description) aux.imgUrl = e.imgUrl
    })
    setSelectDishForEdit(aux)
    // deleteDish(obj)
  }
  const openModalConfirmDeleteDish = () => {
    console.log(selectDishForEdit)
    setShowModalConfirmDishDelete(true)
  }
  const closeModalConfirmDeleteDish = () => {
    setShowModalConfirmDishDelete(false)
  }
  console.log(selectDishForEdit)
  const handleDeliveryWithAddress = (e) => {
    console.log(e.target.checked)
    if (e.target.checked === false) return setWithAddress(false)
    console.log(`checked = ${e.target.checked}`);
  
    setWithAddress(true)
  }
  const getWorkerByCod = (cod) => {
    let result = {}
    console.log(cod)
    USERS.forEach(e => {
      if (e.cod === cod) result = e
    })
    console.log(result)
    return result;
  }
  const activeDelivery = () => {
    let auxFinalOrder = {...finalOrder}
    showSpinner()
    getAllDishesFirebase()
    setIsWithDelivery(true)
    setTimeout(() => {
      console.log(allOrdersBD)
      let getLastSaleId =  (allOrdersBD && allOrdersBD.length > 0) ? allOrdersBD[allOrdersBD.length-1] :  null
      console.log(getLastSaleId)
  
      const callCenterAssignedUI = (Number(role)) === 5 ? getWorkerByCod(cod) : {}
      const waitressAssignedUI = (Number(role)) === 4 ? getWorkerByCod(cod) : {}
  
      auxFinalOrder.saleId = 0
      auxFinalOrder.saleId = Math.floor(Math.random() * (1000000 - 1000)) + 100
  
      if (auxFinalOrder.saleId && getLastSaleId && (auxFinalOrder.saleId === getLastSaleId.saleId)) {
        console.log("idSale ya esta registrado")
        return activeDelivery()
      }
      // setShowModalSelectTable(false)
      console.log(auxFinalOrder)
      if (allOrdersBD.length === 0) auxFinalOrder.id = 1;
      else {
        if (!editIsActive) auxFinalOrder.id = allOrdersBD.length + 1;
        else auxFinalOrder.id = orderSelectedForEdit.id;
      }
      
      const momentNow = moment.tz(new Date(), "America/Lima").format()
   
      auxFinalOrder.dateRegistered = momentNow;
      auxFinalOrder.dateRegistered = JSON.stringify({"date": momentNow});
      // auxFinalOrder.tableId = tableSelected.tableId || 0;
      auxFinalOrder.isDelivery = 1;
      auxFinalOrder.callCenterAssignedUI = JSON.stringify(callCenterAssignedUI)
      auxFinalOrder.waitressAssignedUI =  JSON.stringify(waitressAssignedUI)
      if (auxFinalOrder.waitressCode) auxFinalOrder.waitressCode = JSON.stringify(auxFinalOrder.waitressCode)
      else delete auxFinalOrder.waitressCode
      // if (auxFinalOrder.products === []) {
        delete auxFinalOrder.products
        auxFinalOrder.products = "[]"
      // }
      console.log(auxFinalOrder)
      delete auxFinalOrder.waitressAssignedUI
      axios.post(`https://services-restaurant.elreydelsabor.pe/add-order`
      , auxFinalOrder, {
        headers: {
                  'Content-Type':'application/json',
                },
        })
       .then(
         (result) => {
          console.log(result)
          closeSpinner()
          setShowNewCommand(true)
          getConfigUpdated()
          console.log(auxFinalOrder)
          setAllOrdersBD([])
          console.log(allOrdersBD)
          auxFinalOrder.products = JSON.parse(auxFinalOrder.products)
          setFinalOrder(auxFinalOrder)
          getAllOrdersFirebase()
          setTableSelected({label: "MESA", tableId: null})
          getAllMotorcyclesFirebase()
         }, (error => {
          console.log(error)
         }))
    }, 1000)
   
  }

  const selectTableUI = (table) => {
    console.log(table)
    setTableSelected(table)
    setShowModalSelectTable(false)
    setIsWithDelivery(false)
    getAllOrdersFirebase()
    // setShowNewCommand(true)
  }

  console.log(tableSelected)
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  
  const onFinish = (values) => {
    if (values && !values.address && !values.phone) return alert("La dirección y teléfono de envio son requeridos, por favor completa la información")
    console.log('Success:', values);
    setInfoForDelivery(values)
    setShowModalForDelivery(false)
    setTotalAmountUI(0)
    setWithAddress(false)
    // confirmRegisterDraftOrder()
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const editOrder = (orderSelected) => {
    const auxOrder = { ...orderSelected}
    console.log(auxOrder)
    if (auxOrder.deliveryInformation && typeof (auxOrder.deliveryInformation) === "string") auxOrder.deliveryInformation = JSON.parse(auxOrder.deliveryInformation)
    if (auxOrder.products && typeof (auxOrder.products) === "string") auxOrder.products = JSON.parse(auxOrder.products)
    if (auxOrder.callCenterAssignedUI && typeof (auxOrder.callCenterAssignedUI) === "string") auxOrder.callCenterAssignedUI = JSON.parse(auxOrder.callCenterAssignedUI)
    if (auxOrder.waitressAssignedUI && typeof (auxOrder.waitressAssignedUI) === "string") auxOrder.waitressAssignedUI = JSON.parse(auxOrder.waitressAssignedUI)
    console.log(auxOrder) 
    setEditIsActive(true)
   console.log(orderByShowUI)
   setOrderSelectedForEdit(auxOrder)
   setTotalAmountUI(auxOrder.totalAmount)
   setShowModalOrdersToday(false)
   setShowNewCommand(false)

  }
  const cancelUpdate = () => {
    setEditIsActive(false)
    setTableSelected({})
  }
  const cancelDelivery = () => {
    setTableSelected({})
    setIsWithDelivery(false)
    setWaitressSelected(null)
    setDeliverySelected(null)
    setShowNewCommand(false)
    setOrderByShowUI([])
    setClientPayType(null)
    setListDishesConfirmed([])
  }
  console.log(orderByShowUI)
  const getNameStateByCode = (state) => {
    let result = ""
    if (state) {
      STATES_ORDER.forEach(e => {
        if (e.value === state) result = e.label
      })
    }
    return result;   
  }
  const getNamePayType = (id) => {
    let result = ""
    if (id) {
      PAY_TYPES.forEach(e => {
        if (e.value === id) result = e.label
      })
    }
    return result;   
  }
  const handleChange = (value) => {
    const service = new RestomaticOnlineServices(reference)
    if (value && value !== 1) setAmountForPay(0)
    if (editIsActive && value) {
      let auxFinalOrder = { ...orderSelectedForEdit }
      auxFinalOrder.deliveryInformation.payType = value;
      console.log(editIsActive)
      console.log(value)
      setFinalOrder(auxFinalOrder)
      // return service.updateOrderDB(auxFinalOrder.keyBD, auxFinalOrder).then(res =>{

        // getAllOrdersFirebase()
      // })
    }
    let auxFinalOrder = { ...finalOrder }
    console.log(`selected ${value}`);
    if (value && auxFinalOrder) {
      auxFinalOrder.deliveryInformation = {}
    console.log(`selected ${value}`);
      setClientPayType(value)
      auxFinalOrder.deliveryInformation.payType = value
      auxFinalOrder.deliveryInformation.amountReturn = null
      auxFinalOrder.deliveryInformation.clientPayAmount = null
      setFinalOrder(auxFinalOrder)
      // return service.updateOrderDB(auxFinalOrder.keyBD, auxFinalOrder).then(res =>{
      //   setFinalOrder(auxFinalOrder)
      //   // getAllOrdersFirebase()
      // })
    }
    // auxFinalOrder.payType = value
    // return service.updateOrderDB(auxFinalOrder.keyBD, auxFinalOrder).then(res =>{

      // getAllOrdersFirebase()
    // })
  }
  const addAddress = () => {
    
  }
  const confirmPayOrder = (obj) => {
    console.log(obj)
    setOrderSelectedForPay(obj)
    setOpenModalForPay(true)
  }
  const confirmPayOrderToBD = () => {
    if (orderSelectedForPay) {

    }
  }
  const getAllMotorcyclesFirebase = () => {
    // let allIds = []
    // let allMotorcyclesAux = []
    // getAllMotorcyclesRef.on("value", (snapshot) => {
      // let result = []
      //    if (snapshot.val() !== null) {
      //     snapshot.forEach(e => {
      //       allIds.push(e.key)
      //     })
      //      allMotorcyclesAux = Object.values(snapshot.val()) && Object.values(snapshot.val());
      //      allMotorcyclesAux = Object.values(allMotorcyclesAux)
      //      allMotorcyclesAux = allMotorcyclesAux.map((e, index) => {
      //         console.log(allIds)
      //        if (allIds.length) {
      //        allIds.forEach(k => {
      //          e.keyBD = `MOTORCYCLEID${allIds[index]}`
      //        })
      //        }
      //        return e
      //     })
      //     // result = filterOrders(allMotorcyclesAux, myWorkerDelivery)
      //     console.log(allMotorcyclesAux)
      //     setAllMotorcyclesBD(allMotorcyclesAux)
      //     // setOrdersLoaded(true)
      //     //  console.log(result, "allOrdersAux")
      //    }
      //    return;
      //  }, (error) => {
      //    console.log("ERROR: " + error.code);
      //  });
      fetch("https://services-restaurant.elreydelsabor.pe/all-motorcycles")
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
           console.log(result)
           setAllMotorcyclesBD(result)
        },
        // Nota: es importante manejar errores aquí y no en 
        // un bloque catch() para que no interceptemos errores
        // de errores reales en los componentes.
        (error) => {
         console.log(error)
        }
        )
    }
    const updateMotorcyclesAvailables = () => {
      getAllMotorcyclesFirebase()
    }
    const handleAssignDelivery = (e) => {
      if (e) {
        console.log(e)
        let aux = {...infoForDelivery}
        aux.motorycleAssigned = e
        setInfoForDelivery(aux)
      }
    }
    const getMotorcycleAvailable = () => {
      let result = {}
  
      return result;
    }
  const handleNotForDelivery = (e) => {
    if (e && e.target) {
      setNoteForMotorcycle(e.target.value)
    }
  }
  const handleInfoClientForDeliveryFromRoom = (e) => {
    if (e && e.target) {
      setInfoClientFromRoom(e.target.value)
    }
  }
  console.log(noteForMotorcycle)
  const goBack = () => {
    // setIsModalVisible(false);
    setSelectProductToAdd(false)
    setDishSelected({})
  }
  const selectWaitress = () => {
    setShowModalWaitres(true)
  }
  const selectDeliveryFromRestaurant = () => {
    setShowModalDeliveryFromRestaurant(true)
  }
  const closeSelectDeliveryFromRestaurant = () => {
    setShowModalDeliveryFromRestaurant(false)
  }
  const selectWaitressUI = (waitress) => {
    console.log(waitress)
    if (waitress) {
      setWaitressSelected(waitress)
      setShowModalWaitres(false)
    }
  }
  const selectDeliveryFromRestaurantUI = (delivery) => {
    let orderAux = {...finalOrder}
    orderAux.withDeliveryFromLocal = null
    console.log(delivery)
    if (delivery) {
      setWithDeliveryLocal(true)
      orderAux.withDeliveryFromLocal = true
      setDeliverySelected(delivery)
      setShowModalDeliveryFromRestaurant(false)
      setFinalOrder(orderAux)
    }
  }
  console.log(finalOrder)
  console.log(waitressSelected)
  const onChangeDeliveryLocal = (e) => {
    const orderAux = { ...finalOrder }
    console.log(e)
    if (e && e.target && e.target.checked) {
      setWithDeliveryLocal(e.target.checked)
      orderAux.withDeliveryFromLocal = false
      orderAux.withDeliveryFromLocal = e.target.checked
      setFinalOrder(orderAux)
    }
  }

  const processOrder = (order) => {
    console.log(order, "orden")
    let aux = {...order}
    aux.state = 2;
    aux.payType = Number(aux.payType)
    if (aux) aux.motorcycleAssisnedFromRest = motorcycleOnlineSelected
   
    if (aux.products) aux.products = JSON.stringify(aux.products)
    if (aux.dateRegistered) aux.dateRegistered = JSON.stringify(aux.dateRegistered)
    if (aux.deliveryInformation) aux.deliveryInformation = JSON.stringify(aux.deliveryInformation)
    console.log(aux)
    updateOrderState(aux)

  }
  const handleChangeMotorcycle = (e) => {
    console.log(e)
    if (e) setMotorcycleOnlineSelected(e)
  }
  const updateOrderState = (order) => {
    axios.put(`https://services-restaurant.elreydelsabor.pe/update-order-online/${order.id}`, order, {
      headers: {
                'Content-Type':'application/json',
              },
      })
     .then(
       (result) => {
        console.log(result)
        getAllOrdersOnlineBD()
        openNotificationOnline()
        setMotorcycleOnlineSelected(null)
       }, (error => {
        console.log(error)
       }))
  }
  console.log(allOrdersOnlineBD)
  return (
    <>
      <div className="row">
        <div className="col-xl-12 col-xxl-3 col-lg-3 col-lg-3 col-md-3">
          <div className="row">
            <div className="col-xl-12">
              <div className="order-buttons">
                {
                  isWithDelivery && 
                <Button type="primary" onClick={() => cancelDelivery()} danger>
                  CANCELAR DELIVERY
              </Button>
                }
                {
                  (showNewCommand && !isWithDelivery) &&      
                  <Button type="primary" onClick={() => showNewCommandUI()} danger>
                    CANCELAR COMANDA
                </Button>
                }
                <br />
                {
                  // (showNewCommand || editIsActive) &&
                  <button className="btn btn-primary" onClick={() => showModal()}>
                  VER CARTA
                </button>
                }
                <br />
                <button className="btn btn-primary" disabled={Number(role) === 5} onClick={() => showModalOrdersTodayUI()}>
                  PEDIDOS DE HOY
                </button>
                <br />  
                <button className="btn btn-primary" onClick={() => showModalDeliveryUI()} disabled={role === "4"}>
                  DELIVERY DE HOY
                </button>
              </div>
            </div>          
          </div>
        </div>
        <div className="col-xl-12 col-xxl-9 col-lg-9 col-lg-6 col-md-9">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header border-0 pb-0 d-sm-flex d-block">
                 {
                   (!showNewCommand && !editIsActive) &&
                   <div>
                    <h4 className="card-title mb-1">+ COMANDA</h4>
                    <h5>{(tableSelected && tableSelected.tableId) && `MESA SELECCIONADA ${tableSelected.tableId}`}</h5>
                    <small className="mb-0">
                      Dale click en "Seleccionar mesa", para activar el botón "Generar comanda" y empezar a crear un nuevo pedido.
                    </small>
                    <br />
                    {
                      !editIsActive &&
                      <Button type="primary" onClick={() => showNewCommandUI()} danger disabled={!tableSelected.tableId} style={{height: 50}}>
                      {
                        !showNewCommand ?
                        "GENERAR COMANDA"
                        :
                        "CANCELAR COMANDA"
                      }
                    </Button>
                    }
                    <br />
                    <br />
                    <button className="btn btn-primary" disabled={Number(role) === 5} onClick={() => showModalSelectTableUI()}>
                      SELECCIONAR MESA
                    </button>
                    <br /><br />
                    <button className="btn btn-primary" onClick={() => activeDelivery()}>
                      PARA LLEVAR
                    </button>
                  </div>
                 }
                {
                  (editIsActive && orderSelectedForEdit) && 
                  <div className="edit">
                  <h4 className="card-title mb-1">Comanda n° {lastCommandNumber ? lastCommandNumber : orderSelectedForEdit.id || ""}</h4>
                  <h5>{(orderSelectedForEdit && orderSelectedForEdit.isDelivery === 1) ? "Para llevar" : `Mesa 0${orderSelectedForEdit && orderSelectedForEdit.tableId}`}</h5>
                  <p>
                  Te encuentras editando la comanda</p>
                  {orderByShowUI && <Table 
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: event => {selectItemForDeleteByOrder(event, record)}, // click row
                    };
                  }}
                  columns={columns} dataSource={orderSelectedForEdit.products} pagination={false}/>}
                  <br />
                  <h5>Total: {totalAmountUI || "0"} soles</h5>
                  {/* <h5>Cliente paga con: <InputNumber onChange={(evt) => handleInfo(evt, "amountForPay")} value={amountForPay} min={0}/> soles</h5> */}
                  {/* <h5>Vuelto para cliente es de: {(amountForPay > 0 && totalAmountUI > 0) ? amountForPay - totalAmountUI : 0} soles</h5> */}
                 
                  {
                    (orderSelectedForEdit && orderSelectedForEdit.isDelivery === 1 && orderSelectedForEdit.deliveryInformation) &&
                    <>
                  {(orderSelectedForEdit.deliveryInformation.address) && <h5>Dirección de envío: {orderSelectedForEdit.deliveryInformation.address}</h5>}
                  {orderSelectedForEdit.deliveryInformation.reference && <h5>Referencia: {orderSelectedForEdit.deliveryInformation.reference}</h5>}
                  {(orderSelectedForEdit.deliveryInformation.phone) && <h5>Teléfono: {orderSelectedForEdit.deliveryInformation.phone}</h5>}
                  {(orderSelectedForEdit.deliveryInformation.totalAmount) && <h5>Vuelto: {orderSelectedForEdit.totalAmount}</h5>}
                  {(orderSelectedForEdit.deliveryInformation.payType) && <h5>Método de pago: {orderSelectedForEdit.deliveryInformation.payType && getNamePayType(orderSelectedForEdit.deliveryInformation.payType)}</h5>}
                  {(orderSelectedForEdit.deliveryInformation.clientPayAmount) && <h5>Cliente paga con: {orderSelectedForEdit.deliveryInformation.clientPayAmount} soles</h5>}
                  {(orderSelectedForEdit.deliveryInformation.amountReturn) && <h5>Vuelto: {orderSelectedForEdit.deliveryInformation.amountReturn} soles</h5>}
                    </>
                  }
                  {/* {
                    (editIsActive) &&
                    <Button type="primary" onClick={() => confirmPayOrder(orderSelectedForEdit)} danger>
                      PAGAR
                  </Button>
                  } */}
                  <br />
                  <br />
                  
                  {/* <Checkbox onChange={(evt) => handleDeliveryWithAddress(evt)}>PEDIDO DELIVERY</Checkbox> */}
                  {/* <button className="btn btn-primary" onClick={() => confirmRegisterDraftOrder()}>
                    ACTUALIZAR PEDIDO
                  </button> */}
                  <button className="btn btn-primary" onClick={() => cancelUpdate()}>
                    VOLVER ATRAS
                  </button>
                </div>
                }
                {
                  (showNewCommand) &&
                  <div>
                    <h4 className="card-title mb-1">Comanda n° {finalOrder.id || ""}</h4>
                    <h5>{isWithDelivery ? "Pedido delivery" : `Mesa 0${tableSelected && tableSelected.tableId}`}</h5>
                    <p>
                    Te encuentras viendo un borrador de la comanda</p>
                    {orderByShowUI && <Table 
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: event => {selectItemForDeleteByOrder(event, record)}, // click row
                      };
                    }}
                    columns={columns} dataSource={orderByShowUI} pagination={false}/>}
                    
                    <div>                  
                    {((isWithDelivery && withAddress) || Number(role) === 5) && <h5>Costo de envío: 3 soles</h5>}                     
                    </div>
                    {(isWithDelivery && Number(role) === 5) && <Checkbox onChange={(evt) => handleDeliveryWithAddress(evt)} checked={true}>REGISTRAR DIRECCION DE ENVIO </Checkbox>}
                    <br />

                    {/* <Alert showIcon message="Al momento de registrar el pedido, la comanda se formateara toda la información" type="info" />
                    <br /> */}
                    {
                      ((isWithDelivery && withAddress) || Number(role) === 5) &&
                      <Form
                      {...layout}
                      name="basic"
                      initialValues={{ remember: true }}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                    >
                      <Form.Item
                        label="Dirección:"
                        name="address"
                        rules={[{ required: true, message: 'Dirección exacta es requerida' }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Referencia:"
                        name="reference"
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Teléfono:"
                        name="phone"
                        rules={[{ required: true, message: 'Teléfono es requerido' }]}
                      >
                        <Input />
                      </Form.Item>
                      {
                        ((isWithDelivery && withAddress) || Number(role) === 5) ?
                        <h5>Total: {(totalAmountUI && totalAmountUI + 3) || "0"} soles</h5>
                        :
                        <h5>Total: {totalAmountUI || "0"} soles</h5>
                      }
                      <Form.Item
                          name="payTypeClient"
                          label="Tipo de pago"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Select
                            placeholder="Seleccione"
                            onChange={(e) => handleChange(e)}
                            // defaultValue={clientPayType}
                          >
                            {
                              PAY_TYPES && PAY_TYPES.map(e => {
                               return <Option value={e.value}>{e.label}</Option>                                
                              })
                            }                            
                          </Select>
                        </Form.Item>
                        {(isWithDelivery && clientPayType === 1) && <h5>Cliente paga con: <InputNumber onChange={(evt) => handleInfo(evt, "amountForPay")} value={amountForPay} min={0}/> soles</h5>}
                        {(isWithDelivery && clientPayType === 1) && <h5>Vuelto para cliente es de: {(isWithDelivery && clientPayType === 1 && finalOrder && finalOrder.callCenterAssignedUI && finalOrder.callCenterAssignedUI.cod) ?
                        ((amountForPay > 0 && totalAmountUI > 0) ? amountForPay - (totalAmountUI + 3) : 0) : 
                        ((amountForPay > 0 && totalAmountUI > 0) ? amountForPay - (totalAmountUI) : 0)
                        } soles</h5>}
                          <button type="button" onClick={() => updateMotorcyclesAvailables()}>Actualizar motorizados</button>
                      <Form.Item
                          name="motorycleAssigned"
                          label="Asignar motorizado"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Select
                            placeholder="LISTAR MOTORIZADOS DISPONIBLES"
                            onChange={(e) => handleAssignDelivery(e)}
                          >
                            {
                              allMotorcyclesBD && allMotorcyclesBD.map(e => {
                                if (e.state === 1) return <Option value={e.code}>{e.code}</Option>                                
                              })
                            }                            
                          </Select>
                        </Form.Item>
                      <Form.Item
                        label="Nota del cliente:"
                        name="noteToDelivery"
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Nota para el cocinero:"
                        name="noteToCheff"
                      >
                        <Input />
                      </Form.Item>
                      {/* <Form.Item {...tailLayout} name="remember" valuePropName="checked">
                        <Checkbox>Remember me</Checkbox>
                      </Form.Item> */}                  
                    <Alert showIcon message="La comanda se actualizará automáticamente, luego de seleccionar algún adicional de la carta" type="info" />
                    <br />
                      <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                          REGISTRAR PEDIDO
                        </Button>
                      </Form.Item>
                      {/* <button className="btn btn-primary" onClick={() => cancelR()}>
                        VOLVER ATRAS
                      </button> */}
                    </Form>
                    }
                    <div style={{display: "inline-grid"}}>
                    {
                      isWithDelivery &&
                      (((!isWithDelivery || (isWithDelivery && !withAddress)) && Number(role) !== 5)) &&
                      <>
                        <h5>Total: {(withDeliveryLocal) ? (totalAmountUI +  3) : (totalAmountUI || "0")} soles</h5>
                        <br />
                        <label>NOMBRES COMPLETOS:</label>
                        <input value={infoClientFromRoom} onChange={(e) => handleInfoClientForDeliveryFromRoom(e)}/>
                      </>
                    }
                    <br />
                    {
                      (isWithDelivery && cod === "agntmsa01") &&
                    <div style={{display: "inline-flex"}}>
                        <h5>Tipo de pago:</h5>
                      <Select defaultValue={clientPayType} style={{ width: 120 }} onChange={(e) => handleChange(e)}>
                        {
                          PAY_TYPES && PAY_TYPES.map(e => {
                            return <Option value={e.value}>{e.label}</Option>
                          })
                        }
                      </Select>
                      < br />
                      {(cod === "agntmsa01" && isWithDelivery && clientPayType === 1) && <h5>Cliente paga con: <InputNumber onChange={(evt) => handleInfo(evt, "amountForPay")} value={amountForPay} min={0}/> soles</h5>}
                       <br /> {(cod === "agntmsa01" && isWithDelivery && clientPayType === 1) && <h5>Vuelto para cliente es de: {(amountForPay && (amountForPay - finalOrder.totalAmount) )|| 0} soles</h5>}
                    </div>
                    }
                    <br />
                    
                    {
                        (!isWithDelivery) && <h5>Total: {totalAmountUI || "0"} soles</h5>
                      }
                    {
                      isWithDelivery && (((!isWithDelivery || (isWithDelivery && !withAddress)) && Number(role) !== 5)) &&
                      <>
                        <label>NOTA DEL CLIENTE:1</label>
                        <input value={noteForMotorcycle} onChange={(e) => handleNotForDelivery(e)}/>
                        {/* <br />
                        <Checkbox onChange={(e) => onChangeDeliveryLocal(e)}>Con envío delivery</Checkbox> */}
                      </>
                    }
                    {
                      !isWithDelivery && (((!isWithDelivery && !withAddress)) && Number(role) !== 5) &&
                           <>
                           <label>NOTA DEL CLIENTE:</label>
                           <input value={noteForMotorcycle} onChange={(e) => handleNotForDelivery(e)}/>
                         </>
                    }
                    </div>
                    {
                      (cod && cod === "agntmsa01") &&
                      <>
                        <br />
                        <br />
                        <Button danger onClick={() => selectWaitress()}>
                          SELECCIONAR MESERO
                        </Button>
                      </>
                    }
                    {
                      (cod && cod === "agntmsa01") &&
                      <>
                      <br />
                      <br />
                        <Button danger onClick={() => selectDeliveryFromRestaurant()}>
                          SELECCIONAR MOTORIZADO
                        </Button>
                      </>
                    }
                    <br />
                    <br />
                    {waitressSelected && <h3>MESERO SELECCIONADO {waitressSelected}</h3>}
                    <br />
                    {deliverySelected && <h3>MOTORIZADO SELECCIONADO {deliverySelected}</h3>}
                    {
                      ((!isWithDelivery || (isWithDelivery && !withAddress)) && Number(role) !== 5) && 
                        <button className="btn btn-primary" disabled={orderByShowUI.length === 0} onClick={() => confirmRegisterDraftOrder(true)}>
                        REGISTRAR PEDIDO 1
                      </button>
                    }
                  </div>
                }
                </div>
                  <div className="card-body revenue-chart px-3">
                </div>
              </div>
            </div>          
          </div>
        </div>
        {/* <Modal title="Registrar delivery" visible={showModalForDelivery} onOk={closeModalDeliveryUI} onCancel={closeModalDeliveryUI}
       style={{ top: 20}} className="modal-dish-delete" okText="CERRAR" cancelButtonProps={{hidden: true}}
       >
   <Form
      {...layout}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Dirección:"
        name="address"
        rules={[{ required: true, message: 'Dirección exacta es requerida' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Referencia:"
        name="reference"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Teléfono:"
        name="phone"
        rules={[{ required: true, message: 'Teléfono es requerido' }]}
      >
        <Input />
      </Form.Item>
      {/* <Form.Item {...tailLayout} name="remember" valuePropName="checked">
        <Checkbox>Remember me</Checkbox>
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          GUARDAR
        </Button>
      </Form.Item>
    </Form>
         </Modal> */}
        <Modal title="ELIMINAR ESTE PLATO" visible={showModalConfirmDishDelete} onOk={closeModalConfirmDeleteDish} onCancel={closeModalConfirmDeleteDish}
       style={{ top: 20}} className="modal-dish-delete" okText="CERRAR" cancelButtonProps={{hidden: true}}
       >
         <Card
              style={{ width: 300 }}
              className="card-dish-selected"
              cover={
                <img
                  alt="example"
                  src={selectDishForEdit.imgUrl || ""}
                  style={{maxWidth: "150px", margin:"auto"}}
                />
              }
            >
              {
                (selectDishForEdit && selectDishForEdit.description && selectDishForEdit.amount) &&
                <Meta
                  avatar={<Avatar src={selectDishForEdit.imgUrl || ""} />}
                  title={selectDishForEdit.description || ""}
                  description={`Tiene un precio de ${selectDishForEdit.amount || ""} soles`}
                />

              }
              <br />
              <button className="btn btn-primary" onClick={() => deleteDish(selectDishForEdit)}>ELIMINAR</button>
              {/* <div className="row">
                <div className="col-6">
                  <label>Cantidad</label>
                  <InputNumber value={infoDishSelected.quantity} id="quantity" defaultValue={1} min={1} onChange={(evt) => handleInfo(evt, "quantity")}/>
                </div>
                <div className="col-6">
                <label>Tipo</label>
                  <InputNumber />
                </div>
                <button className="btn btn-primary" onClick={() => confirmDish(infoDishSelected)}>AGREGAR</button>
              </div> */}
            </Card>
       </Modal>
       <Modal title="Pedidos de hoy" visible={showModalOrdersToday} onOk={handleCancelOrderToday} onCancel={handleCancelOrderToday}
       style={{ top: 20}} width={900} className="modal-dishes" okText="CERRAR" cancelButtonProps={{hidden: true}}
       >
            <h5 style={{padding: "10px 20px"}}>
              (*) Llevas un total de {allOrdersBD.length} pedidos registrados
              </h5>
           <table
               className="table display mb-4 dataTablesCard fs-14 dataTable no-footer"
               id="example5"
               role="grid"
               aria-describedby="example5_info"
            >
               <thead>
                 <tr role="row" className="all_review_strg">
                      {/* <th style={{ width: "250px" }}>ID</th> */}
                     <th style={{ width: "250px" }}>N°</th>
                     <th style={{ width: "90.1406px" }}>Total</th>
                     <th style={{ width: "376px" }}>Atención</th>
                     <th style={{ width: "376px" }}>Fecha</th>
                     <th style={{ width: "376px" }}>Acción</th>
                     <th style={{ width: "191px" }}>Estado</th>
                  </tr>
               </thead>
               <tbody>
                 {
                   allOrdersBD && allOrdersBD.map(e => {
                     if (e && e.totalAmount && e.state > 1) {

                      return (
                        <tr role="row" className="odd">
                          {/* <td>
                            {e.keyBD}
                          </td> */}
                        <td>
                           <div className="media align-items-center">
                              <div className="media-body">
                                 <h4 className="text-black font-w300 mb-1 wspace-no">
                                    {e.id || ""}
                                 </h4>
                              </div>
                           </div>
                        </td>
                        <td>
                         <h4 className="text-black font-w300 mb-1 wspace-no">
                           S/ {e.totalAmount || null}
                          </h4>
                        </td>
                        <td>
                         <h4 className="text-black font-w300 mb-1 wspace-no">
                           {(e.isDelivery === 1) ? "Para llevar" : `Mesa ${e.tableId}`}
                           {
                             ((e.isDelivery === 0 && e.tableId > 0) || (e.isDelivery === 1 && e.fromLocal === "local-restaurant")) && <ShopOutlined />
                           }
                              {
                             (e.isDelivery === 1 && e.deliveryInformation && e.motorcycleAssisnedFromRest) && 
                             <img src="../images/moto.png" style={{maxWidth: "24px"}}/>
                           }
                           < br />
                           {
                           (e && e.waitressSelected) && e.waitressSelected
                           }
                           <br />
                           {
                           (e && e.motorcycleAssisnedFromRest) && 
                           `MOTORIZADO ${e.motorcycleAssisnedFromRest}`
                           }
                          </h4>
                           {/* <span className="star-review d-inline-block mb-2 fs-16 wspace-no">
                              <i className="fa fa-star fs-16 text-orange" />{" "}
                              <i className="fa fa-star fs-16 text-orange" />{" "}
                              <i className="fa fa-star fs-16 text-orange" />{" "}
                              <i className="fa fa-star fs-16 text-orange" />{" "}
                              <i className="fa fa-star fs-16 text-gray" />
                           </span> */}
                           {/* <p className="mb-0 d-none d-xl-inline-block">
                              Karciz is one of the best vendors we've ever worked
                              with. Thanks for your wonderful, helpful service
                              across the board. It is greatly appreciated!
                           </p> */}
                        </td>                     
                        <td>
                         <h4 className="text-black font-w300 mb-1 wspace-no">
                         {
                        e.dateRegistered &&
                        <TimeAgo date={e.dateRegistered} formatter={formatter} />
                      }
                          </h4>
                        </td>
                        <td>
                           <div className="d-flex">
                              <a
                              onClick={() => editOrder(e)}
                                 className="btn btn-primary btn-sm px-4"
                                 style={{color: "white"}}
                              >
                                 VER
                              </a>
                           </div>
                        </td>
                        <td>
                          <h4 className="text-red font-w300 mb-1 wspace-no">
                              {
                              (e && e.deliveryInformation && e.deliveryInformation.state) ?
                              getNameStateByCode(e.deliveryInformation.state)
                            :
                            getNameStateByCode(e.state)
                            }
                          </h4>
                          </td>
                     </tr>
                       )
                     }
                   })
                 }                           
               </tbody>
            </table>           
         </Modal>
       <Modal title="Nuestra carta" closable={false} visible={isModalVisible} onOk={handleCancel}
       style={{ top: 20}} className="modal-dishes" okText="CERRAR" cancelButtonProps={{hidden: true}}
       >
         <div className="col-xl-12 col-xxl-12 col-lg-12 col-lg-12 col-md-12">
          <div className="row">
            <div className="col-xl-12">
              {
                !selectProductToAdd ?
              <div className="card">
                <div className="card-header border-0 pb-0 d-sm-flex d-block">
                  <div>
                    <small className="mb-0">
                      Encuentra el plato, combo o bebida para agregar a tu orden
                    </small>
                  </div>
                  <Dropdown className="dropdown mt-3 mt-sm-0">
                    <Dropdown.Toggle
                      type="button"
                      className="btn btn-primary dropdown-toggle light fs-14"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {
                        categorySelected ? Utils.getNameCategory(categorySelected) : "PLATOS"
                      }
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu">
                    {
                      GENERAL_CATEGORIES && GENERAL_CATEGORIES.map(el => {
                        return (
                          <Dropdown.Item className="dropdown-item" to="#" onClick={() => selectCategoryUI(el.value)}>
                            {el.label}
                          </Dropdown.Item>
                        )
                      })
                    }
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="card-body p-0 pt-3">
             
                  {
                    (allProducts) && allProducts.map((el, index) => {
                      if (categorySelected && categorySelected === el.generalCategory) {
                        return (
                          <div className="media items-list-1">
                            <span className="number col-1 px-0 align-self-center">
                             {index + 1}
                            </span>
                            <a className="text-black">
                              <img
                                className="img-fluid rounded mr-3"
                                width={85}
                                src={el.imgUrl}
                                alt="COMBO"
                              />
                            </a>
                            <div className="media-body col-sm-4 col-5 col-xxl-5 px-0">
                              <h5 className="mt-0 mb-3">
                                <a className="text-black">
                                  {el.name}
                                </a>
                              </h5>
                              <small className="font-w500">
                                <strong className="text-secondary mr-2" style={{fontSize: "1.3em"}}>{el.desc}</strong>
                                {/* <Link className="text-primary" to="#">
                                  PIZZA
                                </Link> */}
                              </small>
                            </div>
                            <div className="media-footer ml-auto col-5 px-0 d-flex align-self-center align-items-center">                         
                              <div>
                                <h3 className="mb-0 font-w600 text-black">S/ {el.price}</h3>
                              </div>
                              <div>
                                {
                                  (showNewCommand || editIsActive) &&
                                  <button className="btn btn-primary" onClick={() => setSelectProductToAddUI(el)}>AGREGAR</button>
                                }
                              </div>
                            </div>
                          </div>
                        )
                      }
                    })
                  }
                  </div>
              </div>
              :
              <Card
              style={{ width: "100%" }}
              className="card-dish-selected"
              cover={
                <img
                  alt="example"
                  src={dishSelected.imgUrl || ""}
                  style={{maxWidth: "150px", margin: "auto"}}
                />
              }
            >
              {
                (dishSelected && dishSelected.name && dishSelected.price) &&
                <Meta
                  avatar={<Avatar src={dishSelected.imgUrl || ""} />}
                  title={dishSelected.name || ""}
                  description={`Tiene un precio de ${dishSelected.price || ""} soles`}
                />

              }
              <br />
              <div className="row">
                <div className="col-6">
                  <label>Cantidad</label>
                  <InputNumber value={infoDishSelected.quantity} id="quantity" defaultValue={1} min={1} onChange={(evt) => handleInfo(evt, "quantity")}/>
                </div>
                {/* <div className="col-6">
                <label>Tipo</label>
                  <InputNumber />
                </div> */}
                <button className="btn btn-primary" disabled={addingDish} onClick={() => confirmDish(infoDishSelected)}>{addingDish ? "AGREGANDO..." : "AGREGAR"}</button>
                <Button type="primary" danger onClick={() => goBack()} style={{margin: "5px"}}>ATRAS</Button>
              </div>
            </Card>
            }
            </div>
           
          </div>
        </div>
        </Modal>
        <Modal title="SELECCIONA UNA MESA" visible={showModalSelectTable} onOk={closeModalSelectTableUI} onCancel={closeModalSelectTableUI}
       style={{ top: 20}} className="list-tables" okText="CERRAR" cancelButtonProps={{hidden: true}}
       >
                 <List
                    grid={{
                      gutter: 16,
                      xs: 1,
                      sm: 2,
                      md: 4,
                      lg: 4,
                      xl: 6,
                      xxl: 3,
                    }}
                    dataSource={allTablesBD || []}
                    renderItem={(item, index) => (
                      <List.Item style={{cursor: "pointer"}} className={(tableSelected && tableSelected.tableId === index+1) ? "table-card active" : "table-card"} onClick={() => selectTableUI(item)}>
                        <Card title={item.title}>
                          <div>
                            {item.state === 2 && <span className="dot-using" />}
                            {item.state === 1 && <span className="dot-available" />}                    
                          </div>
                          {item.tableId}</Card>
                      </List.Item>
                    )}
                  />
                    {/* <Button type="primary" style={{float:"right"}} onClick={() => activeDelivery()}>
                      ES DELIVERY
                  </Button> */}
                  <br />
         </Modal>
         <Modal visible={showModalWaitres} title="SELECCIONE MESERO" onCancel={() => setShowModalWaitres(false)} okButtonProps={{hidden: true}}>
            <Button type="primary" style={{margin: 5}} onClick={() => selectWaitressUI("TONY")} danger>
                TONY
            </Button> 
            <Button type="primary" style={{margin: 5}} onClick={() => selectWaitressUI("ARIADNA")} danger>
                  ARIADNA
            </Button> 
            <Button type="primary" style={{margin: 5}} onClick={() => selectWaitressUI("JHONATAN")} danger>
                 JHONATAN
            </Button>
         </Modal>
         <Modal title={`PAGAR COMANDA #${orderSelectedForPay && orderSelectedForPay.id}`} visible={openModalForPay} onOk={closeModalPayOrder} onCancel={closeModalPayOrder}
       style={{ top: 20}} className="list-tables" okText="CERRAR" cancelButtonProps={{hidden: true}}
       >
         <Card title="CONSTANCIA DE PAGO" style={{ width: 300, margin: "auto", textAlign: "center"}}>
                  <p style={{textAlign: "center", fontSize: "0.7em"}}>
                    EL REY DEL SABOR <br />
                    RUC: 28283829<br />
                    URBANIZACION SAGITARIO-SANTIAGO DE SURCO<br />
                    LIMA-LIMA-SANTIAGO DE SURCO<br />
                    TELEF: 388844<br />
                    LOCAL: 002<br />
                    BOLETA DE VENTA ELECTRONICA<br />
                    B017-123446
                  <div>
                  <br />
                    <div className="row">
                        <div className="col-8">
                          FECHA Y HORA DE EMISION
                        </div>
                        <div className="col-4">
                          MESA: 
                        </div>
                      </div>
                      <div className="row" style={{margin: "auto"}}>----------------------------------------------</div>
                    <div className="row">
                        <div className="col-4">
                          ITEM
                        </div>
                        <div className="col-4">
                          CANTIDAD
                        </div>
                        <div className="col-4">
                          TOTAL
                        </div>
                    </div>
                    {
                      (orderSelectedForPay && orderSelectedForPay.products) && orderSelectedForPay.products.map(e => {
                        return (
                          <div className="row">
                            <div className="col-6">
                                {e.description}
                              </div>
                              <div className="col-2">
                                {e.quantity}
                              </div>
                              <div className="col-4">
                                {e.amount}
                              </div>
                          </div>
                        )
                      })
                    }                    
                    <div className="row" style={{margin: "auto"}}>----------------------------------------------</div>
                        <div className="row">
                          <div className="col-8">
                              METODO DE PAGO
                            </div>
                            <div className="col-4">
                              VISA
                            </div>
                        </div>
                      <div className="row">
                          <div className="col-7">
                              TOTAL A PAGAR
                            </div>
                            <div className="col-2">
                              S/
                            </div>
                            <div className="col-3">
                              32
                            </div>
                        </div>
                        <div className="row">
                          <div className="col-7">
                              CLIENTE PAGA CON
                            </div>
                            <div className="col-2">
                              S/
                            </div>
                            <div className="col-3">
                              43
                            </div>
                        </div>
                        <div className="row">
                          <div className="col-7">
                              VUELTO
                            </div>
                            <div className="col-2">
                              S/
                            </div>
                            <div className="col-3">
                              43
                            </div>
                        </div>
                    </div>
                  </p>
                  <br/>
                  <Button type="primary" onClick={() => confirmPayOrderToBD()} danger>
                      CONFIRMAR PAGO
                  </Button>
         </Card>
         </Modal>
         <Modal visible={showModalDeliveryFromRestaurant} cancelText="CANCELAR" title="SELECCIONE MOTORIZADO" onCancel={closeSelectDeliveryFromRestaurant} okButtonProps={{hidden: true}}>
            <Button type="primary" style={{margin: 5}} onClick={() => selectDeliveryFromRestaurantUI("ALEX")} danger>
                ALEX
            </Button> 
            <Button type="primary" style={{margin: 5}} onClick={() => selectDeliveryFromRestaurantUI("MIGUEL")} danger>
                MIGUEL
            </Button> 
            <Button type="primary" style={{margin: 5}} onClick={() => selectDeliveryFromRestaurantUI("JULIO")} danger>
                 JULIO
            </Button>
            <Button type="primary" style={{margin: 5}} onClick={() => selectDeliveryFromRestaurantUI("RICHARD")} danger>
                 RICHARD
            </Button>
            <Button type="primary" style={{margin: 5}} onClick={() => selectDeliveryFromRestaurantUI("CRIS")} danger>
                 CRIS
            </Button>
            <Button type="primary" style={{margin: 5}} onClick={() => selectDeliveryFromRestaurantUI("RUBEN")} danger>
                 RUBEN
            </Button>
         </Modal>
       <ChatBox onClick={() => onClickListClientsDelivery("chatbox")} 
       toggle={toggle} openModal={(option) => showModalForDeliveryChatBox(option)}
       deliveries={allOrdersBD}
       allOrdersOnline={allOrdersOnlineBD}
       processOrder={processOrder}
       allMotorcyclesBD={allMotorcyclesBD}
       handleChangeMotorcycle={handleChangeMotorcycle}
       motorcycleOnlineSelected={motorcycleOnlineSelected}
       openNotificationOnline={openNotificationOnline}
       />
      </div>
    </>
  );
};

export default Analytics;
