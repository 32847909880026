import { Alert } from "antd";
import axios from "axios";
import React, { Fragment, useState } from "react";

const GenerateKey = ({ generateKeyActive, productSelected, keyGenerated, infoClientToShop ={fullNames: ''} }) => {
  const [generateLoaded, setGenerateLoaded] = useState(false)
  let listAux = []

  setTimeout(() => {
    setGenerateLoaded(keyGenerated)
  }, 4500)
  console.log(keyGenerated)
  console.log(productSelected)

  return (
    <div className="generate-key">
      <div>
        <img src="../images/rey-sabor/gif-chicken.gif"  />
        {
        !generateLoaded
        ?
        <h5>Estamos generando tu código de pedido,<br /> espera un momento por favor...</h5>
        :
        <h5>¡ Muchas gracias por tu pedido, {(infoClientToShop && infoClientToShop.fullNames) && infoClientToShop.fullNames.toLocaleUpperCase()}!</h5>
        }
        </div>
      {generateLoaded
      && <div class="card-magic">
        <h5>
          {generateLoaded && generateLoaded.key}
          </h5>
      </div>
      }
      <br />
      {
        generateLoaded && <Alert showIcon message="Acércate a nuestro local y presenta este código en caja" type="info" />
      }
        </div>
  )
}

export default GenerateKey;
