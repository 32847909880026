import React, { useState, useEffect } from "react";

/// React router dom
import { Link } from "react-router-dom";

/// images
// import logo from "../../../images/rey-sabor/rey-sabor-logo.png";
import logoText from "../../../images/logo-text.png";

const NavHader = ({role}) => {
   useEffect(() => {
      // if (role !== 1) setToggle(false)
   })
   const [toggle, setToggle] = useState(false);
   return (
      <div className="nav-header" style={{zIndex: "12"}}>
         <a className="brand-logo">
            <img className="logo-abbr" src="https://elreydelsabor.pe/images/rey-sabor/rey-sabor-logo.png" alt="" />
            <img className="logo-compact" src={logoText} alt="" />
            {/* <img className="brand-title" src={logoText} alt="" /> */}
         </a>

         {
            (role === 1) &&
            <div className="nav-control" onClick={() => setToggle(!toggle)}>
            <div className={`hamburger ${toggle ? "" : ""}`}>
               <span className="line"></span>
               <span className="line"></span>
               <span className="line"></span>
            </div>
         </div>
         }
      </div>
   );
};

export default NavHader;
