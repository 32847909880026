import React, { useState, useEffect } from "react";

const DisplayProducts = ({ }) => {

  return (
    <div className="row display-products">
      <div className="col-12">
        <br />
        <h2><b>BIENVENIDO A <br />EL REY DEL SABOR</b></h2>
          <img src="https://elreydelsabor.pe/images/rey-sabor/rey-sabor-logo.png" style={{maxWidth: "120px", float: "left"}} />
        <br />
        <h4>VISITA NUESTRAS CARTAS</h4>
      </div>
      <div className="col-12">
        <img src="./images/rey-sabor/carta-qr-1.jpeg" className="img-responsive"/>
      </div>
      <div className="col-12">
        <img src="./images/rey-sabor/carta-qr-2.jpeg" className="img-responsive"/>
      </div>
      <div className="col-12">
        <img src="./images/rey-sabor/carta-qr-3.jpeg" className="img-responsive"/>
      </div>
      <div className="col-12">
        <img src="./images/rey-sabor/carta-qr-4.jpeg" className="img-responsive"/>
      </div>
    </div>
  )
}

export default DisplayProducts;