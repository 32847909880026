import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import {STATES_ORDER, USERS} from "../../../constants";
// Slider
import HomeSlider from "../Slider/HomeSlider/HomeSlider";
import { Table, Button, Card, Modal, Select, InputNumber, notification } from 'antd';
import SideBarSlider from "../Slider/HomeSlider/SideBarSlider";
import { Tag, Divider } from 'antd';
import burgerImg from "./../../../images/dish/pic4.jpg";

import RestomaticOnlineServices from "../../../services/services";
import axios from "axios";

const moment = require('moment-timezone');

const Reviews = ({ reference, getAllOrdersRef, showSpinner, closeSpinner, getConfigDisplayRef }) => {
   useEffect(() => {

    //  getAllOrdersFirebase()
   })
  const myCheffCode = localStorage.getItem("workerCod") || ""
  const [allOrdersBD, setAllOrdersBD] = useState([])
  const [allOrdersRestaurantsAuth, setAllOrdersRestaurantsAuth] = useState(false)
  const [orderSelectCompleted, setOrderSelectCompleted] = useState({})
  const [showOpenModalCooked, setShowOpenModalCooked] = useState(false)
  const [showOpenModalListAll, setShowOpenModalListAll] = useState(false)
  const [configLoaded, setConfigLoaded] = useState(null)
  const [withNote, setWithNote] = useState(null)
  const [allOrdersOnlineBdLoaded, setAllOrdersOnlineBdLoaded] = useState(false)
  const [allOrdersOnlineBD, setAllOrdersOnlineBD] = useState([])

  let showNote = false;
  const getConfigUpdated = () => {
    let allOrdersAux = []
    let allIds = []
    getConfigDisplayRef.on("value", (snapshot) => {
      let result = []
      snapshot.forEach(e => {
        allIds.push(e.key)
      })
         if (snapshot.val() !== null) {
           allOrdersAux = Object.values(snapshot.val()) && Object.values(snapshot.val());
           console.log(allOrdersAux)
           allOrdersAux = Object.values(allOrdersAux)
           allOrdersAux = allOrdersAux.map((e, index) => {
            console.log(allIds)
           if (allIds.length) {
           allIds.forEach(k => {
             e.keyBD = `${allIds[index]}`
           })
           }
           return e
        })
           console.log(allOrdersAux)
          if (allOrdersAux && allOrdersAux[0]) setConfigLoaded(allOrdersAux[0])
          console.log(allOrdersAux[0])
          
         }
         return;
    })
  }
  console.log(configLoaded)
  if (configLoaded === null) getConfigUpdated()
  const allAreOneAuth = (order, code)=> {
    if (order.products && order.products.length === 1) {
      let isIncludLocalDelivery = false
      let aux = [];
      order.products.forEach(e => {
        isIncludLocalDelivery = e.kitchenAuthorized && e.kitchenAuthorized.includes("cocna01");
        console.log(isIncludLocalDelivery)
        if (e && e.kitchenAuthorized && e.kitchenAuthorized.length === 1 && isIncludLocalDelivery === false) {
          if(e.kitchenAuthorized[0] && myCheffCode && e.kitchenAuthorized[0] === "cocna02") {
            return aux.push(e.kitchenAuthorized)
          }
        }
        // if (myCheffCode && myCheffCode === "cocna02" && e && e.kitchenAuthorized) aux.push(e.kitchenAuthorized)
      })
      console.log(order, "order")
      console.log(aux)
      showNote = (aux && aux.length > 0) ? aux.every(val => val === aux[0]) : false;
      console.log(showNote)
      if (showNote && order.products[0] && order.products[0].description && myCheffCode === "cocna02" && order.callCenterAssignedUI) {
        return (
          <Tag color="geekblue" id="noteAux">NOTA:
            <br /> Pronto se acercará el motorizado, prepara los platos faltantes para delivery: {order.products[0].description}
            <br />
            {/* <button className="btn btn-primary" 
                style={{margin:"10px auto", width: "100%"}} onClick={() => isReady(order)}>
                  YA ESTA LISTO
                </button> */}
          </Tag>
        )
      }
      if (showNote && order.products[0] && order.products[0].description && myCheffCode === "cocna01" && order.callCenterAssignedUI) {
        return (
          <Tag color="geekblue" id="noteAux">NOTA:
            <br /> Estos platos serán preparados en el local restaurant: {order.products[0].description}
          </Tag>
        )
      }
    } else {
      if (order.products && order.products.length > 1 && order.isDelivery && order.callCenterAssignedUI) {
          let showNote = false;
          let isSame = false
          let allNames = []
          let aux = [];
          console.log(order)
          order.products.forEach((e) => {
            console.log(e)
            console.log(e.kitchenAuthorized && e.kitchenAuthorized.length > 0)
            if (e && e.kitchenAuthorized && e.kitchenAuthorized.length > 0) {
              aux.push(e.kitchenAuthorized.split(","))
            } else {
              if (e.kitchenAuthorized) aux.push(e.kitchenAuthorized[0])
            }
          })
          console.log(order, "order")
          console.log(aux)
          showNote = (aux && aux.length > 0) ? aux.every(val => val === aux[0]) : false;
          console.log(showNote)
          if (showNote && code === "cocna02") {
            allNames = order.products && order.products.map(k => {
              if (k && k.kitchenAuthorized && k.kitchenAuthorized.length < 2) return k.description
            })
            console.log(allNames)
            if (allNames && allNames.length && allNames[0])
            return (
              <Tag color="geekblue" id="noteAux">NOTA:
                <br /> Pronto se acercará el motorizado, prepara el plato faltante para delivery: {allNames && allNames.join(", ")}
                <br/>
                {/* <button className="btn btn-primary" 
                style={{margin:"10px auto", width: "100%"}} onClick={() => isReady(order)}>
                  YA ESTA LISTO
                </button> */}
              </Tag>
            )
          }
          if (!showNote && code === "cocna02" && aux.length > 1) {
            return (
              <Tag color="geekblue" id="noteAux">NOTA:
                <br /> 2 Pronto se acercará el motorizado, prepara el plato faltante para delivery: {getNamesDishes(order.products)}
              </Tag>
            )
          }
          if (!showNote && code === "cocna01" && aux.length > 1) {
            return (
              <Tag color="geekblue" id="noteAux">NOTA:
                <br /> 7 Estos platos seran preparados en el local de restaurante:: {getNamesDishes(order.products)}
              </Tag>
            )
          }
          if (showNote && code === "cocna01") {
            allNames = order.products && order.products.map(k => {
              if (k && k.kitchenAuthorized && k.kitchenAuthorized.length < 2) return k.description
            })
            console.log(allNames)
            if (allNames && allNames.length && allNames[0])
            return (
              <Tag color="geekblue" id="noteAux">NOTA:
                <br /> Estos platos seran preparados en el local de restaurante: {allNames && allNames.join(", ")}
              </Tag>
            )
          }
        }
    }
    return null
  } 
  const getAllOrdersOnlineBD = () => {
    fetch("https://services-restaurant.elreydelsabor.pe/all-orders-online")
      .then(res => res.json())
      .then(
        (result) => {
          setAllOrdersOnlineBdLoaded(true)
          let auxResult = [...result]
          auxResult.map(el => {
            if (el.deliveryInformation && typeof (el.deliveryInformation) === "string") el.deliveryInformation = JSON.parse(el.deliveryInformation)
            if (el.products && typeof (el.products) === "string") el.products = JSON.parse(el.products)
            if (el.callCenterAssignedUI && typeof (el.callCenterAssignedUI) === "string") el.callCenterAssignedUI = JSON.parse(el.callCenterAssignedUI)
            if (el.waitressAssignedUI && typeof (el.waitressAssignedUI) === "string") el.waitressAssignedUI = JSON.parse(el.waitressAssignedUI)
            if (el.dateRegistered && typeof (el.dateRegistered) === "string") el.dateRegistered = JSON.parse(el.dateRegistered)

            return el
          })
          console.log(auxResult)
          setAllOrdersOnlineBD(auxResult)
          localStorage.setItem("qOrders", auxResult.length)
        },
        // Nota: es importante manejar errores aquí y no en 
        // un bloque catch() para que no interceptemos errores
        // de errores reales en los componentes.
        (error) => {
          console.log("ERROR: " + error.code);
        }
      )
    }
  const getAllOrdersFirebase = () => {
    fetch("https://services-restaurant.elreydelsabor.pe/all-orders")
      .then(res => res.json())
      .then(
        (result) => {
          let auxResult = [...result]
          auxResult.map(el => {
            if (el.deliveryInformation && typeof (el.deliveryInformation) === "string") el.deliveryInformation = JSON.parse(el.deliveryInformation)
            if (el.products && typeof (el.products) === "string") el.products = JSON.parse(el.products)
            if (el.callCenterAssignedUI && typeof (el.callCenterAssignedUI) === "string") el.callCenterAssignedUI = JSON.parse(el.callCenterAssignedUI)
            if (el.waitressAssignedUI && typeof (el.waitressAssignedUI) === "string") el.waitressAssignedUI = JSON.parse(el.waitressAssignedUI)
            if (el.dateRegistered && typeof (el.dateRegistered) === "string") el.dateRegistered = JSON.parse(el.dateRegistered)

            return el
          })
          console.log(auxResult)
          setAllOrdersBD(auxResult)
          localStorage.setItem("qOrders", auxResult.length)
        },
        // Nota: es importante manejar errores aquí y no en 
        // un bloque catch() para que no interceptemos errores
        // de errores reales en los componentes.
        (error) => {
          console.log("ERROR: " + error.code);
        }
      )
    }
    if (allOrdersBD && allOrdersBD.length === 0) {
      localStorage.setItem("qOrders", 0)
      // getAllOrdersFirebase()
    }
    if (!allOrdersOnlineBdLoaded) {
      localStorage.setItem("qOrders", 0)
      getAllOrdersOnlineBD()
    }
    const filterByCode = (list, cod) => {
      let result = []
      if (list) {
        list.forEach(e => {
            if (e && e.cheffAssigned === cod) result.push(e)
          })
      }
        return result;
    }
    const getNameStateByCode = (state) => {
      let result = ""
      if (state) {
        STATES_ORDER.forEach(e => {
          if (e.value === state) result = e.label
        })
      }
      return result;   
    }
    const isReady = (el) => {
      console.log(el)
      let auxFinalOrder = {...el}
      // if (el.isDelivery && el.deliveryInformation && el.deliveryInformation.state) {
      //   auxFinalOrder.deliveryInformation.state = 8
      //    auxFinalOrder.state = 8;
      // } else {
      //   console.log(el)
  
      //   auxFinalOrder.state = 7;
      // }
      // auxFinalOrder.cheffAssigned = ""
      // auxFinalOrder.cheffAssigned = myCheffCode || null
      // console.log(auxFinalOrder)
      setOrderSelectCompleted(auxFinalOrder)
      // setShowOpenModalCooked(true)
      // showSpinner()
      // setTimeout(() => {
        confirmCockeedOnlineOrder(auxFinalOrder)
      // }, 800 )
    }

    const showAllDishes = (el) => {
      setShowOpenModalListAll(true)
    }
    const closeModalCooked = () => {
      setShowOpenModalCooked(false)
      setOrderSelectCompleted({})
    }
    const closeModalAllCooked = () => {
      setShowOpenModalListAll(false)
    }
    const openNotification = () => {
      notification.success({
        message: `Pedido listo!`,
        description:
          'El pedido se encuentra listo.'
      });
    };
    const confirmCockeedOnlineOrder = (order) => {
      if (order) {
        const el = {...order}

        if (el.isDelivery && el.deliveryInformation) {
           order.state = 8;
        } else {
          console.log(el)
    
          order.state = 7;
        }
        order.cheffAssigned = ""
        order.cheffAssigned = myCheffCode || null
        console.log(order)

        const service = new RestomaticOnlineServices(reference)
        const serviceConfig = new RestomaticOnlineServices(reference);
        order.payType = Number(order.payType)
        if (order.callCenterAssignedUI) order.callCenterAssignedUI = JSON.stringify(order.callCenterAssignedUI)
        if (order.waitressAssignedUI) order.waitressAssignedUI = JSON.stringify(order.waitressAssignedUI)
        if (order.deliveryInformation) order.deliveryInformation = JSON.stringify(order.deliveryInformation)
        if (order.isDelivery === 1 && order.waitressSelected && order.deliveryInformation) order.fromLocal = "local-restaurant"
        if (order.products) order.products = JSON.stringify(order.products)
        if (order.dateRegistered) order.dateRegistered = JSON.stringify(order.dateRegistered)
        console.log(order)
        axios.put(`https://services-restaurant.elreydelsabor.pe/update-order-online/${order.id}`, order, {
          headers: {
                    'Content-Type':'application/json',
                  },
          })
         .then(
           (result) => {
            console.log(result)
            console.log("actualizado")
             //   if (orderSelectCompletedAux && orderSelectCompletedAux.deliveryInformation && orderSelectCompletedAux.deliveryInformation.infoClientFromRoom) {
              //     let configLoadedAux = { ...configLoaded }
              //     configLoadedAux.updated = true
              //     serviceConfig.updateConfigRoom(configLoadedAux.keyBD, configLoadedAux).then(res => {
              //       console.log("SE REGISTRO EN SALA")
              //     }, error => {
              //       console.log("NO SE REGISTRO EN SALA")
              //     })
              //   }
            getAllOrdersOnlineBD()
            openNotification()
            setOrderSelectCompleted({})
            setShowOpenModalCooked(false)
           })
    }
  }

    const confirmCockeed = (orderSelectCompletedAux) => {
      if (orderSelectCompletedAux) {
        const el = {...orderSelectCompletedAux}

        if (el.isDelivery && el.deliveryInformation && el.deliveryInformation.state) {
          orderSelectCompletedAux.deliveryInformation.state = 8
           orderSelectCompletedAux.state = 8;
        } else {
          console.log(el)
    
          orderSelectCompletedAux.state = 7;
        }
        orderSelectCompletedAux.cheffAssigned = ""
        orderSelectCompletedAux.cheffAssigned = myCheffCode || null
        console.log(orderSelectCompletedAux)

        orderSelectCompletedAux.payType = Number(orderSelectCompletedAux.payType)
        if (orderSelectCompletedAux.callCenterAssignedUI) orderSelectCompletedAux.callCenterAssignedUI = JSON.stringify(orderSelectCompletedAux.callCenterAssignedUI)
        if (orderSelectCompletedAux.waitressAssignedUI) orderSelectCompletedAux.waitressAssignedUI = JSON.stringify(orderSelectCompletedAux.waitressAssignedUI)
        if (orderSelectCompletedAux.deliveryInformation) orderSelectCompletedAux.deliveryInformation = JSON.stringify(orderSelectCompletedAux.deliveryInformation)
        if (orderSelectCompletedAux.isDelivery === 1 && orderSelectCompletedAux.waitressSelected && orderSelectCompletedAux.deliveryInformation) orderSelectCompletedAux.fromLocal = "local-restaurant"
        if (orderSelectCompletedAux.products) orderSelectCompletedAux.products = JSON.stringify(orderSelectCompletedAux.products)
        if (orderSelectCompletedAux.dateRegistered) orderSelectCompletedAux.dateRegistered = JSON.stringify(orderSelectCompletedAux.dateRegistered)
        console.log(orderSelectCompletedAux)
        axios.put(`https://services-restaurant.elreydelsabor.pe/update-order/${orderSelectCompletedAux.id}`, orderSelectCompletedAux, {
          headers: {
                    'Content-Type':'application/json',
                  },
          })
         .then(
           (result) => {
            console.log(result)
            console.log("actualizado")
             //   if (orderSelectCompletedAux && orderSelectCompletedAux.deliveryInformation && orderSelectCompletedAux.deliveryInformation.infoClientFromRoom) {
              //     let configLoadedAux = { ...configLoaded }
              //     configLoadedAux.updated = true
              //     serviceConfig.updateConfigRoom(configLoadedAux.keyBD, configLoadedAux).then(res => {
              //       console.log("SE REGISTRO EN SALA")
              //     }, error => {
              //       console.log("NO SE REGISTRO EN SALA")
              //     })
              //   }
            getAllOrdersFirebase()
            setOrderSelectCompleted({})
            setShowOpenModalCooked(false)
           })
      }

    }
    let dayNumber = 1;
    const TodayIs = new Date()
    dayNumber = TodayIs && TodayIs.getDay()
    console.log(dayNumber)

    const getMissingDishes = (order) => {
      console.log(order)
      let auxResult = []
      let result = ""
      if (order && order.products) {
        order.products.forEach(e => {
          if (e && e.kitchenAuthorized && e.kitchenAuthorized.length === 1) auxResult.push(e.description)
        })
      }
      if (auxResult) result = auxResult.join(", ")
      return result;
    }
    console.log("showNote", showNote)
    console.log("myCheffCode", myCheffCode)
    const uniqueDishWithOutAllAuth = (list) => {
      let result = true
      if (list && list.length === 1 && list.length[0] && list[0].kitchenAuthorized && list[0].kitchenAuthorized.length === 1 && list[0].kitchenAuthorized[0] !== "cocna01") {
        result = false
      }
      return result;
    }
    const allAuthAllowed = (products) => {
      let result = true

      if (products) {
        products.forEach(k => {
          if (k.kitchenAuthorized && k.kitchenAuthorized.length === 1) result = false
        })
      }
      return result;
    }
    const getNamesDishes = (list) => {
      let result = []
      let resultText = ""
      if (list) {
        list = list.forEach(e => {
          if (e.kitchenAuthorized && e.kitchenAuthorized.length === 1) result.push(e.description)
        })
      }
      if (result) {
        resultText = result.join(", ")
      }
      return resultText;
    }
    const filterIsBetweenToday = (date) => {
      let result = false;
      const todayDate = moment.tz(new Date(), "America/Lima").format()
      // console.log(todayDate)
      let initDay = todayDate.split("T")
      let finalDay = todayDate.split("T")
      initDay = initDay[0]+"T00:00:00-05:00"
      finalDay = finalDay[0]+"T23:59:59-05:00"
      initDay = Date.parse(initDay)
      finalDay = Date.parse(finalDay)
      date = Date.parse(date)
      console.log(initDay)
      console.log(finalDay)
      console.log(date)
      // if (moment.tz(Date.parse(date).isAfter(Date.parse(initDay)))) result = true
      if (initDay <= date && date <= finalDay) result = true
      return result
    }
    let todayOrders = []
    console.log(allOrdersBD)
    allOrdersBD.forEach(e => {
      if (filterIsBetweenToday(e.dateRegistered.date) && e.state === 2) todayOrders.push(e)
    })
    console.log(todayOrders)
    console.log(allOrdersBD)
    console.log(allOrdersOnlineBD)
  return (
    <>
      <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
        <div className="mr-auto d-none d-lg-block">
          <h2 className="text-black font-w600 mb-1">Cocina</h2>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a className="text-primary">
                Dashboard
              </a>
            </li>
            <li className="breadcrumb-item active">
              <a className="text-primary">Nuestra cocina</a>
            </li>
          </ol>
        </div>
      
      </div>
      <div className="">
          {/* <div className="row">
          <Button type="primary"  onClick={() => showAllDishes()}>
              VER TODOS LOS PLATOS
            </Button>
          </div> */}
          {/* <br /> */}
        <div className="row">
          {allOrdersOnlineBD && allOrdersOnlineBD.length === 0 && <h4>Espera mientras llegan comandas al sistema...</h4>}
          {
            allOrdersOnlineBD && allOrdersOnlineBD.map((el, index) => {
              if (el && el.products && (el.state === 2) && el.deliveryInformation) {
                  if (myCheffCode === "cocna01" && el.isDelivery === 1) {
                    return (
                      <div className="items col-xs-12 col-sm-6 col-lg-4">
                        <div className="bootstrap-media card p-4">
                          <div className="media mb-2 align-items-center">
                              <img
                                className="mr-3 img-fluid rounded-xl"
                                width={94}
                                src={burgerImg}
                                alt=""
                              />
                            <div className="media-body">
                              <h5 className="mt-0 mb-3">
                                {/* <Link className="text-black" to="/ecom-product-detail"> */}
                                <a className="text-black fnt100">
                                  PEDIDO ONLINE <br /> # {el.deliveryInformation.orderId}
                                </a>
                              </h5>
                              <h5 className="mt-0 mb-3">
                                {/* <Link className="text-black" to="/ecom-product-detail"> */}
                                <a className="text-black fnt800">
                                  PEDIDO PARA {el.isDelivery ? "LLEVAR" : "MESA"}
                                </a>
                              </h5>
                              <h5 className="mb-0 fnt800">
                                <a className="text-primary">
                                  {
                                    (el.isDelivery && el.deliveryInformation && el.deliveryInformation.state)
                                    ? 
                                    getNameStateByCode(el.deliveryInformation.state).toUpperCase()
                                    :
                                    getNameStateByCode(el.state).toUpperCase()
                                  }
                                  
                                </a>
                              </h5>
                            {/* {((dayNumber > 0 && dayNumber < 5 && myCheffCode && myCheffCode === "cocna02" && (el && el.callCenterAssignedUI) ||
                            (dayNumber > 0 && dayNumber < 5 && myCheffCode && myCheffCode === "cocna02" && ( el && el.fromLocal === "local-delivery" && el.isDelivery)))
                            ) && <Tag color="volcano">(*) DE LUNES A JUEVES DEBES TOMAR ESTA ORDEN</Tag>} */}
                            </div>
                          </div>
                          <p className="fs-18 text-black mb-4">
                            <table style={{"font-size": "0.8em"}}>
                              <thead>
                                <tr>
                                  <td>
                                    ITEM
                                  </td>
                                  <td>
                                    CANTIDAD
                                  </td>
                                  <td style={{textAlign: "right"}}>
                                    DESCRIPCION
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  (el && el.products) ? el.products.map((k, index) => {
                                    // if (el.products[index].kitchenAuthorized && el.products[index+1].kitchenAuthorized && (el.products[index].kitchenAuthorized.length !== el.products[index+1].kitchenAuthorized.length)) withDifferentesKitchens = true
                                    return (                      
                                  <tr>
                                    <td><h6 style={{fontSize: "0.9em"}}>{k.name}</h6></td>
                                    <td style={{textAlign: "center"}}><h6>{k.quantity}</h6></td>
                          <td style={{textAlign: "right"}}><h6 style={{fontSize: "0.8em"}}>{k.description || ""}</h6></td>
                            
                                  </tr>
                                    )
                                  })
                                  :
                                  null
                                }
                              </tbody>
                            </table>
                          </p>
                          {(el && myCheffCode && (myCheffCode === "cocna02" || myCheffCode === "cocna01")) ? allAreOneAuth(el, myCheffCode) : null}
                          {/* {
                            (el && el.isWithDiffKitchens && myCheffCode && myCheffCode === "cocna02" && el.callCenterAssignedUI) && 
                              <Tag color="geekblue" id="noteAux">NOTA:
                                <br /> Prepara el plato faltante para delivery: {el.products ? getMissingDishes(el) : null}
                              </Tag>
                          } */}
                          {(el && el.deliveryInformation && el.deliveryInformation.client && el.deliveryInformation.client.noteToCheff) && <Tag color="volcano" >NOTA: <br />{el.deliveryInformation.client.noteToCheff}</Tag>}
                        
                          {((el && el.isWithDiffKitchens && myCheffCode && myCheffCode === "cocna02" && el.callCenterAssignedUI) ||
                          (el && el.deliveryInformation && el.deliveryInformation.noteToCheff)
                          ) ? <br /> : null}
                          {
                            ((el && el.fromLocal === "local-restaurant" && myCheffCode && myCheffCode === "cocna02") ||
                            (el && el.isDelivery && el.deliveryInformation && el.deliveryInformation.fromLocal && el.deliveryInformation.fromLocal === "local-restaurant" && myCheffCode && myCheffCode === "cocna02")
                            ) ?
                            <button className="btn btn-primary" 
                              style={{margin:"auto"}} disabled={el.state > 2 || (el.deliveryInformation && el.deliveryInformation.state > 2)} onClick={() => isReady(el)}>
                                {(el.state > 2 || (el.deliveryInformation && el.deliveryInformation.state > 2)) ? "COCINADO!1" : "YA ESTA LISTO! 1 E"}
                              </button>
                              : null
                            }
                            
                  {
                    (el.deliveryInformation.orderId && el.isDelivery === 1) ?
                      <button className="btn btn-primary" 
                      style={{margin:"auto"}} disabled={el.state > 2} onClick={() => isReady(el)}>
                        {(el.state > 2 || ( el.state > 2)) ? "COCINADO!9" : "YA ESTA LISTO! 9"}
                      </button>
                    : null
                  }
                            {
                              (dayNumber && dayNumber > 1 && dayNumber < 5 && myCheffCode && myCheffCode === "cocna02" && el && el.deliveryInformation && el.deliveryInformation.fromLocal && el.deliveryInformation.fromLocal === "local-delivery") ?
                              <button className="btn btn-primary" 
                              style={{margin:"auto"}} disabled={el.state > 2 || (el.deliveryInformation && el.deliveryInformation.state > 2)} onClick={() => isReady(el)}>
                                {(el.state > 2 || (el.deliveryInformation && el.deliveryInformation.state > 2)) ? "COCINADO!2" : "YA ESTA LISTO! 2"}
                              </button>
                              : null
                            }
                            {
                              (myCheffCode && myCheffCode === "cocna01" && el.callCenterAssignedUI && el.deliveryInformation && el.isDelivery && allAuthAllowed(el.products)) ?
                              <button className="btn btn-primary" 
                              style={{margin:"auto"}} disabled={el.state > 2 || (el.deliveryInformation && el.deliveryInformation.state > 2)} onClick={() => isReady(el)}>
                                {(el.state > 2 || (el.deliveryInformation && el.deliveryInformation.state > 2)) ? "COCINADO!3" : "YA ESTA LISTO! 4"}
                              </button>
                              : null
                            }
                            {
                              (myCheffCode && myCheffCode === "cocna02" && el.callCenterAssignedUI && el.deliveryInformation && el.isDelivery && uniqueDishWithOutAllAuth(el.products) &&
                              !(myCheffCode && myCheffCode === "cocna02" && el.callCenterAssignedUI && el.deliveryInformation && el.isDelivery && !showNote && allAuthAllowed(el.products) === false) &&
                              !(myCheffCode && myCheffCode === "cocna02" && el.callCenterAssignedUI && el.deliveryInformation && el.isDelivery && allAuthAllowed(el.products))
                              ) ?
                              <>
                              {
                                el.products.length > 1 &&
                                <Tag color="geekblue" id="noteAux">NOTA:
                              <br /> 6Pronto se acercará el motorizado, prepara los platos faltantes para delivery: {getNamesDishes(el.products)}
                            </Tag>
                              }
                            <br/>
                              <button className="btn btn-primary" 
                              style={{margin:"auto"}} disabled={el.state > 2 || (el.deliveryInformation && el.deliveryInformation.state > 2)} onClick={() => isReady(el)}>
                                {(el.state > 2 || (el.deliveryInformation && el.deliveryInformation.state > 2)) ? "COCINADO!3" : "YA ESTA LISTO! 5"}
                              </button>
                              </>
                              : null
                            }
                            
                                <br />
                          <div className="reviewer-box">
                            <div className="media align-items-center">
                              {/* <img
                                className="mr-3 img-fluid rounded"
                                width={55}
                                src={profileImg00}
                                alt="DexignZone"
                              /> */}
                              <div className="media-body">
                                <h4 className="text-white fnt100 mb-0">LOCAL: </h4>
                              </div>
                              <div className="star-review">
                                <span>
                                {/* <i className="fa fa-star text-orange" />
                                <span className="ml-2 fnt100">{(el && el.deliveryInformation && el.deliveryInformation.fromLocal) ? el && el.deliveryInformation && el.deliveryInformation.fromLocal.replace("local-", "").toUpperCase() : 
                                (el && el.fromLocal) ? el.fromLocal.replace("local-", "").toUpperCase() : ""
                                } */}
                                  
                                    <span className="ml-2 fnt100">
                                    {(el.isDelivery === 1) ? "DELIVERY" : "RESTAURANT"}
                                    </span>
                                  
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )                    
                  }
                  if (myCheffCode === "cocna02" && el.isDelivery === 0) {
                    return (
                      <div className="items col-xs-12 col-sm-6 col-lg-4">
              <div className="bootstrap-media card p-4">
                <div className="media mb-2 align-items-center">
                    <img
                      className="mr-3 img-fluid rounded-xl"
                      width={94}
                      src="https://elreydelsabor.pe/images/rey-sabor/combo10.png"
                      alt="DexignZone"
                    />
                  <div className="media-body">
                    <h5 className="mt-0 mb-3 fnt100">
                      {/* <Link className="text-black" to="/ecom-product-detail"> */}
                      <a className="text-black">
                        PEDIDO ONLINE <br /> # {el.deliveryInformation.orderId}
                      </a>
                    </h5>
                    <h5 className="mt-0 mb-3 fnt800">
                      {/* <Link className="text-black" to="/ecom-product-detail"> */}
                      {/* <a className="text-black">
                        PEDIDO PARA {el.isDelivery ? "LLEVAR" : "MESA"} {!el.isDelivery && el.tableId && el.tableId} <br />
                        {(el.waitressSelected) && `MESERO: ${el.waitressSelected}`}
                      </a> */}
                        <a className="text-black">
                        PEDIDO PARA LLEVAR
                      </a>
                    </h5>
                    <h5 className="mb-0 fnt800">
                      <a className="text-primary">
                        {
                          (el.isDelivery && el.deliveryInformation && el.deliveryInformation.state)
                          ? 
                          getNameStateByCode(el.deliveryInformation.state).toUpperCase()
                          :
                          getNameStateByCode(el.state).toUpperCase()
                        }                         
                      </a>
                    </h5>
                  {/* {((dayNumber > 0 && dayNumber < 5 && myCheffCode && myCheffCode === "cocna02" && (el && el.callCenterAssignedUI) ||
                  (dayNumber > 0 && dayNumber < 5 && myCheffCode && myCheffCode === "cocna02" && ( el && el.fromLocal === "local-delivery" && el.isDelivery)))
                  ) && <Tag color="volcano">(*) DE LUNES A JUEVES DEBES TOMAR ESTA ORDEN</Tag>} */}
                  </div>
                </div>
                <p className="fs-18 text-black mb-4 fnt100">
                  <table>
                    <thead>
                      <tr>
                        <td>
                          ITEM
                        </td>
                        <td>
                          CANTIDAD
                        </td>
                                  <td style={{textAlign: "right"}}>
                          DESCRIPCION
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        (el && el.products) ? el.products.map((k, index) => {
                          // if (el.products[index].kitchenAuthorized && el.products[index+1].kitchenAuthorized && (el.products[index].kitchenAuthorized.length !== el.products[index+1].kitchenAuthorized.length)) withDifferentesKitchens = true
                          return (                      
                        <tr className="fnt100">
                        <td><h6 style={{fontSize: "0.9em"}}>{k.name}</h6></td>
                          <td style={{textAlign: "center"}}><h6>{k.quantity}</h6></td>
                          <td style={{textAlign: "right"}}><h6 style={{fontSize: "0.8em"}}>{k.description || ""}</h6></td>
                   
                        </tr>
                          )
                        })
                        :
                        null
                      }
                    </tbody>
                  </table>
                </p>
                {(el && myCheffCode && (myCheffCode === "cocna02" || myCheffCode === "cocna01")) ? allAreOneAuth(el, myCheffCode) : null}
                {/* {
                  (el && el.isWithDiffKitchens && myCheffCode && myCheffCode === "cocna02" && el.callCenterAssignedUI) && 
                    <Tag color="geekblue" id="noteAux">NOTA:
                      <br /> Prepara el plato faltante para delivery: {el.products ? getMissingDishes(el) : null}
                    </Tag>
                } */}
                {(el && el.deliveryInformation && el.deliveryInformation.noteToCheff) && <Tag color="volcano" >NOTA: <br />{el.deliveryInformation.noteToCheff}</Tag>}
               
                {((el && el.isWithDiffKitchens && myCheffCode && myCheffCode === "cocna02" && el.callCenterAssignedUI) ||
                (el && el.deliveryInformation && el.deliveryInformation.noteToCheff)
                ) ? <br /> : null}
                {
                  ((el && el.fromLocal === "local-restaurant" && myCheffCode && myCheffCode === "cocna02" && 
                  !((el && el.isDelivery === 0 && el.deliveryInformation && !el.deliveryInformation.fromLocal && el.state === 2))) ||
                  (el && el.isDelivery && el.deliveryInformation && el.deliveryInformation.fromLocal && el.deliveryInformation.fromLocal === "local-restaurant" && myCheffCode && myCheffCode === "cocna02" &&
                  !(el && el.isDelivery === 0 && el.deliveryInformation && !el.deliveryInformation.fromLocal && el.state === 2))
                  ) ?
                  <button className="btn btn-primary" 
                    style={{margin:"auto"}} disabled={el.state > 2 || (el.deliveryInformation && el.deliveryInformation.state > 2)} onClick={() => isReady(el)}>
                      {(el.state > 2 || (el.deliveryInformation && el.deliveryInformation.state > 2)) ? "COCINADO!1" : "YA ESTA LISTO! 1"}
                    </button>
                    : null
                  }
                  {
                    (dayNumber && dayNumber > 1 && dayNumber < 5 && myCheffCode && myCheffCode === "cocna02" && el && el.deliveryInformation && el.deliveryInformation.fromLocal && el.deliveryInformation.fromLocal === "local-delivery") ?
                    <button className="btn btn-primary" 
                    style={{margin:"auto"}} disabled={el.state > 2 || (el.deliveryInformation && el.deliveryInformation.state > 2)} onClick={() => isReady(el)}>
                      {(el.state > 2 || (el.deliveryInformation && el.deliveryInformation.state > 2)) ? "COCINADO!2" : "YA ESTA LISTO!"}
                    </button>
                    : null
                  }
                  {/* {
                    (el.deliveryInformation.orderId && el.isDelivery === 1) ?
                      <button className="btn btn-primary" 
                      style={{margin:"auto"}} disabled={el.state > 2 || (el.deliveryInformation && el.deliveryInformation.state > 2)} onClick={() => isReady(el)}>
                        {(el.state > 2 || (el.deliveryInformation && el.deliveryInformation.state > 2)) ? "COCINADO!3" : "YA ESTA LISTO! 3"}
                      </button>
                    : null
                  } */}
                  {
                    (el && el.isDelivery === 0 && el.deliveryInformation && !el.deliveryInformation.fromLocal && el.state === 2) &&
                    <button className="btn btn-primary" 
                    style={{margin:"auto"}} disabled={el.state > 2 || (el.deliveryInformation && el.deliveryInformation.state > 2)} onClick={() => isReady(el)}>
                      {(el.state > 2 || (el.deliveryInformation && el.deliveryInformation.state > 2)) ? "COCINADO!2" : "YA ESTA LISTO! 2"}
                    </button>
                  }
                  {
                    (!(dayNumber && dayNumber > 1 && dayNumber < 5) && (myCheffCode && myCheffCode === "cocna02" && el.callCenterAssignedUI && el.deliveryInformation && el.isDelivery && uniqueDishWithOutAllAuth(el.products) &&
                    !(myCheffCode && myCheffCode === "cocna02" && el.callCenterAssignedUI && el.deliveryInformation && el.isDelivery && !showNote && allAuthAllowed(el.products) === false) &&
                    !(myCheffCode && myCheffCode === "cocna02" && el.callCenterAssignedUI && el.deliveryInformation && el.isDelivery && allAuthAllowed(el.products))
                    )) ?
                    <>
                   <br/>
                    <button className="btn btn-primary" 
                    style={{margin:"auto"}} disabled={el.state > 2 || (el.deliveryInformation && el.deliveryInformation.state > 2)} onClick={() => isReady(el)}>
                      {(el.state > 2 || (el.deliveryInformation && el.deliveryInformation.state > 2)) ? "COCINADO!3" : "YA ESTA LISTO! 5"}
                    </button>
                    </>
                    : null
                  }
                  
                      <br />
                <div className="reviewer-box">
                  <div className="media align-items-center">
                    {/* <img
                      className="mr-3 img-fluid rounded"
                      width={55}
                      src={profileImg00}
                      alt="DexignZone"
                    /> */}
                    <div className="media-body">
                      <h4 className="text-white fnt100 mb-0">LOCAL: </h4>
                    </div>
                    <div className="star-review">
                      {/* <i className="fa fa-star text-orange" /> */}
                      <span className="ml-2 fnt100">{(el && el.isDelivery === 0 && el.deliveryInformation && !el.deliveryInformation.fromLocal) && "RESTAURANTE"}
                        {(el && el.deliveryInformation && el.deliveryInformation.fromLocal) ? el && el.deliveryInformation && el.deliveryInformation.fromLocal.replace("local-", "").toUpperCase() : 
                      (el && el.fromLocal) ? el.fromLocal.replace("local-", "").toUpperCase() : ""
                      }</span>
                    </div>
                  </div>
                </div>
              </div>
                    </div>
                    )                    
                  }
              }
            })
          }
          {/* <div className="widget-carousel owl-carousel">
            <HomeSlider data={allOrdersBD} />
          </div> */}
        </div>

      </div>
      <Modal visible={showOpenModalListAll} style={{ top: 20 }} width={900} title="Platos preparados" centered onCancel={() => closeModalAllCooked()} okButtonProps={{hidden: true}} cancelText="CERRAR">
                    <div className="row">
                    {
                    allOrdersOnlineBD && allOrdersOnlineBD.map((el, index) => {
              if (el && el.products && (el.state >= 2)) {
                  if (myCheffCode === "cocna02") {
                    return (
                      <div className="items col-xs-12 col-sm-6 col-lg-4">
                        <div className="bootstrap-media card p-4">
                          <div className="media mb-2 align-items-center">
                              <img
                                className="mr-3 img-fluid rounded-xl"
                                width={94}
                                src="https://elreydelsabor.pe/images/rey-sabor/combo10.png"
                                alt=""
                              />
                            <div className="media-body">
                              <h5 className="mt-0 mb-3">
                                {/* <Link className="text-black" to="/ecom-product-detail"> */}
                                <a className="text-black fnt100">
                                  COMANDA N° {el.id}
                                </a>
                              </h5>
                              <h5 className="mt-0 mb-3">
                                {/* <Link className="text-black" to="/ecom-product-detail"> */}
                                <a className="text-black fnt800">
                                  PEDIDO PARA {el.isDelivery ? "LLEVAR" : "MESA"}
                                </a>
                              </h5>
                              <h5 className="mb-0 fnt800">
                                <a className="text-primary">
                                  {
                                    (el.isDelivery && el.deliveryInformation && el.deliveryInformation.state)
                                    ? 
                                    getNameStateByCode(el.deliveryInformation.state).toUpperCase()
                                    :
                                    getNameStateByCode(el.state).toUpperCase()
                                  }
                                  
                                </a>
                              </h5>
                            {((dayNumber > 0 && dayNumber < 5 && myCheffCode && myCheffCode === "cocna02" && (el && el.callCenterAssignedUI) ||
                            (dayNumber > 0 && dayNumber < 5 && myCheffCode && myCheffCode === "cocna02" && ( el && el.fromLocal === "local-delivery" && el.isDelivery)))
                            ) && <Tag color="volcano">(*) DE LUNES A JUEVES DEBES TOMAR ESTA ORDEN</Tag>}
                            </div>
                          </div>
                          <p className="fs-18 text-black mb-4">
                            <table>
                              <thead>
                                <tr>
                                  <td>
                                    ORDEN
                                  </td>
                                  <td>
                                    CANTIDAD
                                  </td>
                                  <td>
                                    DESCRIPCION
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  (el && el.products) ? el.products.map((k, index) => {
                                    // if (el.products[index].kitchenAuthorized && el.products[index+1].kitchenAuthorized && (el.products[index].kitchenAuthorized.length !== el.products[index+1].kitchenAuthorized.length)) withDifferentesKitchens = true
                                    return (                      
                                  <tr>
                                  <td><h6 style={{fontSize: "0.9em"}}>{k.name}</h6></td>
                                    <td style={{textAlign: "center"}}><h6>{k.quantity}</h6></td>
                          <td style={{textAlign: "right"}}><h6 style={{fontSize: "0.8em"}}>{k.description || ""}</h6></td>                      
                                  </tr>
                                    )
                                  })
                                  :
                                  null
                                }
                              </tbody>
                            </table>
                          </p>
                          </div>
                          </div>
                    )
                    }
                    }
                    })
                  }
                  </div>
      </Modal>  
      <Modal visible={showOpenModalCooked} title="Orden preparada" centered onCancel={() => closeModalCooked()} okButtonProps={{hidden: true}} cancelText="CERRAR">
          <h5>EL PLATO ESTA LISTO?</h5>
          <Button type="primary" disabled={!orderSelectCompleted} onClick={() => confirmCockeed()} danger>
              CONFIRMAR
            </Button>
      </Modal>
    </>
  );
};

export default Reviews;
