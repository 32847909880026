import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Space, Modal, InputNumber, Card, Avatar, Checkbox, List, Select, Input, notification, Alert } from 'antd';
import {PAY_TYPES, STATES_ORDER} from "../../../constants";
import { Table } from "react-bootstrap";
import TimeAgo from 'react-timeago'
import spanishStrings from 'react-timeago/lib/language-strings/es'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
const formatter = buildFormatter(spanishStrings)
const moment = require('moment-timezone');
const { Search } = Input;

const Notes = ({ toggleTab, PerfectScrollbar, toggleChatBox, openModal, deliveries, allOrdersOnline, processOrder, handleChangeMotorcycle,
  allMotorcyclesBD, motorcycleOnlineSelected }) => {
  const [showDetailActive, setShowDetailActive] = useState(false)
  const [showDetailActiveOnline, setShowDetailActiveOnline] = useState(false)
  const [showDetailActiveSelected, setShowDetailActiveSelected] = useState({})
  const [showDetailActiveSelectedOnline, setShowDetailActiveSelectedOnline] = useState({})
  const [codeClient, setCodeClient] = useState("");
  const [foundOrder, setFoundOrder] = useState(null);

    useEffect(() => {
       //  setTimeout(() => {
    if (codeClient && codeClient.length > 4) searchCodeOnBD()
    if (codeClient && codeClient.length <= 4) setFoundOrder(null)
     //},2000)
  })
  console.log(deliveries)

  const showDetail = (e) => {
    setShowDetailActive(true)
    setShowDetailActiveSelected(e)
  }
  const showDetailOnline = (e) => {
    setShowDetailActiveSelectedOnline(e)
    setShowDetailActiveOnline(true)
  }
  const goBack = () => {
    setShowDetailActive(false)
    setShowDetailActiveOnline(false)
    if (setShowDetailActiveSelected !== {}) setShowDetailActiveSelected({})
    if (setShowDetailActiveSelectedOnline !== {}) setShowDetailActiveSelectedOnline({})
    // setShowDetailActiveSelected({})
  }
  const getNameStateByCode = (state) => {
    let result = ""
    if (state) {
      STATES_ORDER.forEach(e => {
        if (e.value === state) result = e.label
      })
    }
    return result;   
  }
  const getNamePayTypeByCode = (state) => {
    let result = ""
    if (state) {
      PAY_TYPES.forEach(e => {
        if (e.value === state) result = e.label
      })
    }
    return result;   
  }
  const filterIsBetweenToday = (date) => {
    let result = false;
    const todayDate = moment.tz(new Date(), "America/Lima").format()
    // console.log(todayDate)
    let initDay = todayDate.split("T")
    let finalDay = todayDate.split("T")
    initDay = initDay[0]+"T00:00:00-05:00"
    finalDay = finalDay[0]+"T23:59:59-05:00"
    initDay = Date.parse(initDay)
    finalDay = Date.parse(finalDay)
    date = Date.parse(date)
    console.log(initDay)
    console.log(finalDay)
    console.log(date)
    // if (moment.tz(Date.parse(date).isAfter(Date.parse(initDay)))) result = true
    if (initDay <= date && date <= finalDay) result = true
    return result
  }
  console.log(deliveries)
  if (deliveries) {
    deliveries =  deliveries.map(e => {
      if (e.deliveryInformation && typeof (e.deliveryInformation) === "string") e.deliveryInformation = JSON.parse(e.deliveryInformation)
      if (e.products && typeof (e.products) === "string") e.products = JSON.parse(e.products)
      if (e.dateRegistered && typeof (e.dateRegistered) === "string") e.dateRegistered = JSON.parse(e.dateRegistered)
      if (e.callCenterAssignedUI && typeof (e.callCenterAssignedUI) === "string") e.callCenterAssignedUI = JSON.parse(e.callCenterAssignedUI)
      if (e.waitressAssignedUI && typeof (e.waitressAssignedUI) === "string") e.waitressAssignedUI = JSON.parse(e.waitressAssignedUI)
      return e
    })
  }
  const handleCode = (e) => {
    console.log(e)
    if (e) {
      setCodeClient(e.target.value)
    }
  }
  const searchCodeOnBD = () => {
    console.log("BUSCANDO CODIGO")
    let result = null;
    console.log(codeClient)
    console.log(allOrdersOnline)
    if (allOrdersOnline && codeClient) {
      allOrdersOnline.forEach(e => {
        if (e && e.deliveryInformation && e.deliveryInformation.orderId &&
          String(e.deliveryInformation.orderId) === codeClient) {
            result = e;
          }
      })
    }
    if (result) setFoundOrder(result)
  }
  console.log(showDetailActiveSelectedOnline)
  console.log(foundOrder)
  console.log(deliveries)
  console.log(allOrdersOnline)
  let reverseListOnline = []
  if (allOrdersOnline) reverseListOnline = allOrdersOnline.reverse()
  console.log(reverseListOnline)
  let listMotors = []
  const transformMotorcycleUI = (allMotorcyclesBD) => {
    console.log("entro")
    listMotors = allMotorcyclesBD.map(e => {
    const a = {
      value: e.code,
      label: e.name
    }
    return a
  })
  }
  if (allMotorcyclesBD) transformMotorcycleUI(allMotorcyclesBD)
  console.log(listMotors)
  console.log(showDetailActiveSelected)
  return (
    <>
    <div
      className={`tab-pane fade ${toggleTab === 'pedidos delivery' ? 'active show' : ''}`}
      id='notes'
    >
      <div className='card mb-sm-3 mb-md-0 note_card'>

        <PerfectScrollbar
          className={`card-body contacts_body p-0 dz-scroll  ${
            toggleChatBox ? 'ps ps--active-y' : ''
          }`}
          id='DZ_W_Contacts_Body2'
        >
          <br />
          {
          (showDetailActiveSelected && showDetailActiveSelected.products && showDetailActiveSelected.deliveryInformation && showDetailActive) &&
          <div className='d-flex bd-highlight col-12'>
              <div className='user_info col-12'>
                  <span style={{maxWidth: "100%"}}>COMANDA #{showDetailActiveSelected.id}</span>
                <p style={{maxWidth: "100%"}}><b>DIRECCION:</b> {showDetailActiveSelected.deliveryInformation.address}</p>
                <p style={{maxWidth: "100%"}}><b>TELEFONO:</b> {showDetailActiveSelected.deliveryInformation.phone}</p>
                  {showDetailActiveSelected.deliveryInformation.motorycleAssigned && <p>
                    <b>MOTORIZADO:</b>
                    {showDetailActiveSelected.deliveryInformation.motorycleAssigned}</p>}
                <p style={{maxWidth: "100%"}}><b>ESTADO:</b> 
                {showDetailActiveSelected.deliveryInformation.state && getNameStateByCode(showDetailActiveSelected.deliveryInformation.state)}</p>
                <p style={{maxWidth: "100%"}}><b>HORA DE PEDIDO:</b> <TimeAgo date={showDetailActiveSelected.dateRegistered.date} formatter={formatter} /></p>
                <p><b>MEDIO DE PAGO: 
                  {(showDetailActiveSelected.deliveryInformation.payType && getNamePayTypeByCode(showDetailActiveSelected.deliveryInformation.payType)) || ""}</b></p>
                {showDetailActiveSelected.deliveryInformation.clientPayAmount && <p><b>Cliente paga con:</b> {showDetailActiveSelected.deliveryInformation.clientPayAmount}</p>}
                {showDetailActiveSelected.deliveryInformation.clientPayAmount && <p><b>Vuelto:</b> {showDetailActiveSelected.deliveryInformation.clientPayAmount-showDetailActiveSelected.totalAmount}</p>}
                <p style={{maxWidth: "100%"}}><b>TOTAL:</b> {showDetailActiveSelected.totalAmount}</p>
              <br/>
              <Table responsive className="w-100">
            <div id="example_wrapper" className="dataTables_wrapper">
              <table
                id="example delivery"
                className="display w-100 dataTable"
                role="grid"
                aria-describedby="example_info"
              >
                <thead>
                  <tr>
                  <td>CANT</td>
                  <td>ITEM</td>
                  <td>PRECIO</td>
                  </tr>
                </thead>
                <tbody>
                  {
                    showDetailActiveSelected &&showDetailActiveSelected.products.map(e => {
                      return (
                        <tr>
                          <td>{e.quantity}</td>
                          <td>{e.description}</td>
                          <td>{e.amount}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </Table>
          <br />
                { (showDetailActive && showDetailActiveSelected) &&
                  <Button type="button" className="col-12" style={{textAlign: "center", backgroundColor: "#2f4cdd"}} onClick={() => goBack()}>
                      <p style={{margin: "auto", color:"white"}}>IR ATRAS</p>
                  </Button>}
                  <br />
                  <br />
                  { (showDetailActive && showDetailActiveSelected) &&
                  <Button type="button" className="col-12" style={{textAlign: "center", backgroundColor: "#2f4cdd"}} onClick={() => navigator.clipboard.writeText(showDetailActiveSelected.urlToClient || showDetailActiveSelectedOnline.urlToClient || "")}>
                      <p style={{margin: "auto", color:"white"}}>COPIAR LINK DE TRACKING</p>
                  </Button>}
              </div>
            </div>
          }
          <ul className='contacts'>
            {
              ((deliveries && deliveries.length > 0) && !showDetailActive) && deliveries.map(e => {
                if (e && e.isDelivery && e.deliveryInformation && e.deliveryInformation.address && e.products && filterIsBetweenToday(e.dateRegistered.date)) {
                  return (
                    <li className='active'>
                      <div className='d-flex bd-highlight'>
                        <div className='user_info'>
                          <span>COMANDA #{e.id}</span>
                          <p><b>DIRECCION:</b> {e.deliveryInformation.address}</p>
                          <p><b>TELEFONO:</b> {e.deliveryInformation.phone}</p>
                          <p><b>MOTORIZADO:</b> {e.deliveryInformation.motorycleAssigned}</p>
                          {
                            (e.deliveryInformation && e.deliveryInformation.state) ?
                            <p><b>ESTADO:</b>{getNameStateByCode(e.deliveryInformation.state)}</p> :
                            <p><b>ESTADO:</b> {e.state && getNameStateByCode(e.state)}</p>
                          }
                        </div>
                        <div className='ml-auto'>
                          <a onClick={() => showDetail(e)} style={{color: "white"}} className='btn btn-primary btn-xs sharp mr-1'>
                            <i className='fa fa-eye'></i>
                          </a>
                          {/* <Link to='#' className='btn btn-danger btn-xs sharp'>
                            <i className='fa fa-trash'></i>
                          </Link> */}
                        </div>
                      </div>
                    </li>
                  )
                }
              })
            }
          </ul>
        </PerfectScrollbar>
      </div>
    </div>
    
  <div
      className={`tab-pane fade ${toggleTab === 'pedidos online' ? 'active show' : ''}`}
      id='alerts'
    >
       <div className='card mb-sm-3 mb-md-0 note_card'>
       <div className='card-header chat-list-header text-center' style={{display: "block"}}>
          {/* <div>
            <h6 className='mb-1'>Buscar</h6>
          </div>
           <a onClick={() => openModal("add")}> <PlusCircleOutlined /></a> */}
           <Search placeholder="Código de pedido online..."
         onChange={(e) => handleCode(e)}
         value={codeClient}
         style={{"display": "block"}}
         enterButton={(codeClient && codeClient.length > 4) ? "Encontrado" : "Buscar"} size="Buscar" loading={(codeClient && codeClient.length > 4) ? true : false} />
       <br />
          <ul className='contacts' style={{overflowY: "auto", maxHeight: "500px"}}>
          {
            !(showDetailActiveSelectedOnline && showDetailActiveSelectedOnline.products && showDetailActiveSelectedOnline.deliveryInformation && 
              showDetailActiveOnline)
              ?
                (foundOrder && foundOrder.deliveryInformation && foundOrder.deliveryInformation.orderId &&  foundOrder.deliveryInformation.client && foundOrder.products) ?
                  (
                    <li className='active 1'>
                      <div className='d-flex bd-highlight'>
                        <div className='user_info'>
                          <h6 className='text-red'>PEDIDO ONLINE ENCONTRADO {foundOrder.deliveryInformation.orderId}</h6>
                          <p><b>DIRECCION:</b> {foundOrder.deliveryInformation.client.address}</p>
                          <p><b>TELEFONO:</b> {foundOrder.deliveryInformation.client.phone}</p>
                          <p><b>MOTORIZADO:</b> {foundOrder.deliveryInformation.motorcycleAssisnedFromRest || ""}</p>
                          {
                            (foundOrder.deliveryInformation && foundOrder.state) ?
                            <p><b>ESTADO:</b>{getNameStateByCode(foundOrder.state)}</p> :
                            <p><b>ESTADO:</b> {foundOrder.state && getNameStateByCode(foundOrder.state)}</p>
                          }
                        </div>
                        <div className='ml-auto'>
                            <a onClick={() => showDetailOnline(foundOrder)} 
                            style={{color: "white", background: "#2f4cdd"}} className='btn btn-primary btn-xs sharp mr-1'>
                            <i className='fa fa-eye'></i>
                          </a>
                          {/* <Link to='#' className='btn btn-danger btn-xs sharp'>
                            <i className='fa fa-trash'></i>
                          </Link> */}
                        </div>
                      </div>
                    </li>
                  )
                  :
                    (allOrdersOnline && reverseListOnline) && reverseListOnline.reverse().map(k => {
                        return (
                          <li className='active 2'>
                      <div className='d-flex bd-highlight'>
                        <div className='user_info'>
                          <h6 className='text-red'>PEDIDO ONLINE # {k.deliveryInformation.orderId}
                          {!(k.deliveryInformation.client.address) ? <br /> : null}
                          {!(k.deliveryInformation.client.address) ? "RECOJO EN RESTAURANTE" : null}</h6>
                          {(k.deliveryInformation.client.address) ?
                          <p><b>DIRECCION:</b> {k.deliveryInformation.client.address}</p>
                          : null
                          }
                          <p><b>TELEFONO:</b> {k.deliveryInformation.client.phone}</p>
                          <p style={{maxWidth: "100%"}}><b>HORA DE PEDIDO:</b> {(k.dateRegistered && k.dateRegistered.date) &&
                          <TimeAgo date={k.dateRegistered.date} formatter={formatter} />}</p>
                          {
                            (k.isDelivery === 1) ?
                            <p><b>MOTORIZADO:</b> {k.deliveryInformation.motorcycleAssisnedFromRest || ""}</p>
                            : null
                          }
                          {
                            (k.deliveryInformation && k.state) ?
                            <p><b>ESTADO:</b>{getNameStateByCode(k.state)}</p> :
                            <p><b>ESTADO:</b> {k.state && getNameStateByCode(k.state)}</p>
                          }
                        </div>
                        <div className='ml-auto'>
                            <a onClick={() => showDetailOnline(k)} 
                            style={{color: "white", background: "#2f4cdd"}} className='btn btn-primary btn-xs sharp mr-1'>
                            <i className='fa fa-eye'></i>
                          </a>
                          {/* <Link to='#' className='btn btn-danger btn-xs sharp'>
                            <i className='fa fa-trash'></i>
                          </Link> */}
                        </div>
                      </div>
                    </li>
                        )
                      })
                      :
                      null
            }
            {

            (showDetailActiveSelectedOnline && showDetailActiveSelectedOnline.products && showDetailActiveSelectedOnline.deliveryInformation && 
              showDetailActiveOnline) ?
          <div className='d-flex bd-highlight col-12' style={{padding: 0}}>
              <div className='user_info col-12' style={{padding: 0, textAlign:"left"}}>
                  <h5 style={{maxWidth: "100%", textAlign: "center"}} className="text-red">PEDIDO ONLINE <br/># {showDetailActiveSelectedOnline.deliveryInformation.orderId}</h5>
                {
                  (showDetailActiveSelectedOnline.deliveryInformation.client.address) ?
                  <p style={{maxWidth: "100%"}}><b>DIRECCION:</b> {showDetailActiveSelectedOnline.deliveryInformation.client.address}</p>
                  : null
                }
                {
                  (showDetailActiveSelectedOnline.deliveryInformation.client.reference) ?
                  <p style={{maxWidth: "100%"}}><b>REFERENCIA:</b> {showDetailActiveSelectedOnline.deliveryInformation.client.reference}</p>
                  : null
                }
                <p style={{maxWidth: "100%"}}><b>TELEFONO:</b> {showDetailActiveSelectedOnline.deliveryInformation.client.phone}</p>
                {
                  (showDetailActiveSelectedOnline.isDelivery === 1) ?
                  <p style={{maxWidth: "100%", display: "inline-block"}}>
                    <b>MOTORIZADO:</b>
                    <Select
                    placeholder="Seleccione"
                        value={(showDetailActiveSelectedOnline.motorcycleAssisnedFromRest) ? 
                          showDetailActiveSelectedOnline.motorcycleAssisnedFromRest
                          :
                          motorcycleOnlineSelected}
                        disabled={(showDetailActiveSelectedOnline.motorcycleAssisnedFromRest) ? true : false}
                        style={{
                          width: 150,
                        }}
                        onChange={handleChangeMotorcycle}
                        options={listMotors ? listMotors : []}
                      />
                </p> : null
                }
                <p style={{maxWidth: "100%"}}><b>ESTADO:</b> 
                {showDetailActiveSelectedOnline.state && getNameStateByCode(showDetailActiveSelectedOnline.state)}</p>
                <p style={{maxWidth: "100%"}}><b>HORA DE PEDIDO:</b> 
                {(showDetailActiveSelectedOnline.dateRegistered && showDetailActiveSelectedOnline.dateRegistered.date) &&
                <TimeAgo date={showDetailActiveSelectedOnline.dateRegistered.date} formatter={formatter} />}</p>
                <p style={{display: "contents"}}><b>MEDIO DE PAGO: {(showDetailActiveSelectedOnline.deliveryInformation.client.payTypeClient &&
                   getNamePayTypeByCode(showDetailActiveSelectedOnline.deliveryInformation.client.payTypeClient)) || ""}
                   </b></p>
                {/* {showDetailActiveSelectedOnline.deliveryInformation.clientPayAmount && <p><b>Cliente paga con:</b> {showDetailActiveSelectedOnline.deliveryInformation.clientPayAmount}</p>}
                {showDetailActiveSelectedOnline.deliveryInformation.clientPayAmount && <p><b>Vuelto:</b> {showDetailActiveSelectedOnline.deliveryInformation.clientPayAmount-showDetailActiveSelectedOnline.totalAmount}</p>} */}
                <p style={{maxWidth: "100%"}}><b>TOTAL:</b>S/ {showDetailActiveSelectedOnline.totalAmount}</p>
              <br/>
              <Table responsive className="w-100">
            <div id="example_wrapper" className="dataTables_wrapper">
              <table
                id="example delivery"
                className="display w-100 dataTable"
                role="grid"
                aria-describedby="example_info"
              >
                <thead>
                  <tr>
                  <td>CANT</td>
                  <td>ITEM</td>
                  <td>PRECIO</td>
                  </tr>
                </thead>
                <tbody>
                  {
                    showDetailActiveSelectedOnline && showDetailActiveSelectedOnline.products.map(e => {
                      return (
                        <tr>
                          <td>{e.quantity}</td>
                          <td>{e.name}</td>
                          <td>S/ {e.price}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </Table>
          {/* <br /> */}
                  { (showDetailActiveOnline && showDetailActiveSelectedOnline) &&
                  <Button type="button" className="col-12" style={{textAlign: "center", backgroundColor: "red"}} 
                  onClick={() => processOrder(showDetailActiveSelectedOnline)} disabled={(showDetailActiveSelectedOnline.state !== 1 ? true : false)}>
                      <p style={{margin: "auto", color:"white"}}>{(showDetailActiveSelectedOnline.state >= 2) ? "PEDIDO PROCESADO":"PROCESAR PEDIDO"}</p>
                  </Button>}
                  <br />
                  <br />
                  { (showDetailActiveOnline && showDetailActiveSelectedOnline) &&
                  <Button type="button" className="col-12" style={{textAlign: "center", backgroundColor: "#2f4cdd", height:45}} onClick={() => navigator.clipboard.writeText(showDetailActiveSelected.urlToClient || showDetailActiveSelectedOnline.urlToClient || "")}>
                      <p style={{margin: "auto", color:"white"}}>COPIAR LINK DE TRACKING</p>
                  </Button>}
                  <br />
                  <br />
                { (showDetailActiveOnline && showDetailActiveSelectedOnline) &&
                  <Button type="button" className="col-12" style={{textAlign: "center", backgroundColor: "#2f4cdd"}} onClick={() => goBack()}>
                      <p style={{margin: "auto", color:"white"}}>IR ATRAS</p>
                  </Button>}
              </div>
            </div>
            : null
            }
            </ul>
           </div>
        </div>
    </div>
   </>
  )
}

export default Notes
