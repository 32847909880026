import React from "react";
import { Link } from "react-router-dom";
import { Table, Button, notification, Alert, Checkbox } from 'antd';

const Products = ({ product, openModal, onChangeItem }) => {
   return (
      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
         {/* <div className="card">
            <div className="card-body" style={{textAlign: "center"}}>
               <div className="new-arrival-product">
                  <div className="new-arrivals-img-contnent">
                     <img className="img-fluid" src={product.imgUrl} alt="" />
                  </div>
                  <div className="new-arrival-content text-center mt-3">
                     <h4>
                        <Link to="/ecom-product-detail">{product.name}</Link>
                     </h4>
                     <span className="price">S/{product.price}</span>
                  </div>
                  <Button type="primary" danger onClick={() => openModal()}>PEDIR YA</Button>
               </div>
            </div>
         </div> */}
         <div className="container" id="card-test-content">
            <div className="main-card">  
               <div className="card online-card"
                style={{"text-align": "center",
                 display: "block"}}>
               <img className="img-fluid" src={product.imgUrl} alt="" style={{height: "100%", borderRadius: "5%"}} />
               <h3 class="card-symbol">S/ {product.price}</h3>
               <Checkbox onChange={(e) => onChangeItem(product, e)}> <h4 class="card-symbol">
                  <b style={{color: "#2f4cdd"}}>AGREGAR A LA LISTA</b></h4></Checkbox>
               <br  /><br />
                  <Button type="primary" style={{background: "red"}} danger onClick={() => openModal(product)}>PEDIR YA</Button>
                   </div>
              

               {/* <div className="card_back" style={{ background: "url('http://i280.photobucket.com/albums/kk195/thelittleblacksmith/lobster-nebula_zps2j1cvbkx.jpg')",}}>
               <img className="img-fluid" src={product.imgUrl} alt="" />
                  <div className="card-text">
                  <div className="new-arrival-content text-center mt-3">
               <h6 class="card-symbol">{product.name}</h6>
                     <span className="price">S/{product.price}</span>
                  </div>
                  <Button type="primary" danger onClick={() => openModal(product)}>PEDIR YA</Button>
                  </div>
               </div>     */}

            </div>
            </div>
      </div>
   );
};

export default Products;
