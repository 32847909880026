import React, { Fragment, useState, useEffect } from "react";
import {STATES_ORDER, PAY_TYPES, USERS, DATA_DISH} from "../../../../../constants";
import Products from "./Products";
import { Table, Button, Space, Modal, InputNumber, Card, Avatar, Checkbox, List, Form, Input, notification, Alert, Select } from 'antd';
// import SpinnerLoader from "../../components/spinner"
/// Data
import productData from "../productData";
import PageTitle from "../../../../layouts/PageTitle";
import GenerateKey from "../GenerateKey";
import axios from "axios";
const moment = require('moment-timezone');

const bodyOrder = {
  id: null,
  totalAmount	: null,
  state: null,
    saleId: null,
      isDelivery: null,
        fromLocal: null,
      dateRegistered: null,
  products: [],
  payType: null,
  deliveryInformation: null,
  urlToClient: null,
  motorcycleAssisnedFromRest: null,
  paymentAtHomeInformation: null
}
const ProductGrid = ({ getAllDishes }) => {
   const [dishesBD, setDishesBD] = useState([])
   const [allDishesLoaded, setAllDishesLoaded] = useState(false)
   const [generateKeyActive, setGenerateKeyActive] = useState(false)
   const [showModal, setShowModal] = useState(false)
   const [optSelected, setOptSelected] = useState(0)
   const [selectedDish, setSelectedDish] = useState(null)
   const header = document.getElementsByClassName("header")
   const [generateLoaded, setGenerateLoaded] = useState(false)
   const [keyGenerated, setKeyGenerated] = useState(null)
   const [infoClientToShop, setInfoClientToShop] = useState(null)
   const [newOrder, setNewOrder] = useState(bodyOrder)
   const [confirmedDelivery, setConfirmedDelivery] = useState(false)
   const [allOrdersBDLoaded, setAllOrdersBDLoaded] = useState(false)
   const [totalAmountUI, setTotalAmountUI] = useState(null)
   const [allOrdersBD, setAllOrdersBD] = useState([])
   const [amountForPay, setAmountForPay] = useState(null)
   const [typePayClientSelectedUi, setTypePayClientSelectedUi] = useState(null)
   const [listShopCar, setListShopCar] = useState([])
   const [activeStep, setActiveStep] = useState(false)
   let listAux = []
 
   useEffect(() => {
    setTimeout(() => {
      if (listShopCar && listShopCar.length > 1 && !activeStep) setActiveStep(true)
    }, 1200)
    
    if (listShopCar.length === 0) setActiveStep(false)
   })
   // if (header) {
   // console.log(header)
   //    header.remove()
   // }
   
const { Option } = Select;
   const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };
    const tailLayout = {
      wrapperCol: { offset: 8, span: 16 },
    };
    
    const calculateTotalAmount = (auxList, option) => {
      let totalAmount = 0
      let onlyAmounts = []
      console.log(auxList)
      if (auxList.length) {
      onlyAmounts = auxList.map(e =>  {
        let result = 0
        result = e.price * e.quantity
        return result
      })
      console.log(onlyAmounts)
      totalAmount = onlyAmounts.reduce((a,b) => a + b)
      console.log(totalAmount)
        }    
        if (totalAmount && !option) setTotalAmountUI(totalAmount)
        return totalAmount;
      }
    const onFinish = (values) => {
      const aux = { ... newOrder }
      if (aux && values) {
        if (values && !values.address && !values.phone) return alert("La dirección y teléfono de envio son requeridos, por favor completa la información")
        console.log('Success:', values);
        console.log(aux)
        aux.id = null
        console.log(allOrdersBD)
        if (allOrdersBD.length === 0) aux.id = 1;
        else {
          aux.id = allOrdersBD[allOrdersBD.length-1].id+1;
        }
        if (!aux.saleId) {
          aux.saleId = 0
          aux.saleId = Math.floor(Math.random() * (1000000 - 10000)) + 100
        }
        aux.deliveryInformation = { client: values};
        aux.deliveryInformation.orderId = 0;
        aux.isDelivery = true;
        const totalAmount = (aux.products) &&  calculateTotalAmount(aux.products, false)
        aux.totalAmount = totalAmount;
        aux.state = 1
        aux.payType = values.payTypeClient;
        aux.urlToClient = ""
        aux.urlToClient = (aux && aux.saleId) ? `https://elreydelsabor.pe/sumary-delivery/${aux.saleId}` : null;
         if (aux.deliveryInformation.amountForPay) aux.deliveryInformation.amountForPay = Number(aux.deliveryInformation.amountForPay)
         if (aux.deliveryInformation.client.amountForPay) aux.deliveryInformation.client.amountForPay = Number(aux.deliveryInformation.client.amountForPay)
        console.log(aux)
        setNewOrder(aux)
        generateNumber(aux)
        setConfirmedDelivery(true)
      }
    };
  
    const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    };
    
    const getAllOrdersBD = () => {
      fetch("https://services-restaurant.elreydelsabor.pe/all-orders-online")
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
           setAllOrdersBD(result)
           setAllOrdersBDLoaded(true)
        },
        // Nota: es importante manejar errores aquí y no en 
        // un bloque catch() para que no interceptemos errores
        // de errores reales en los componentes.
        (error) => {
         console.log(error)
        }
      )
   }

   const getAllDishesFirebase = () => {
      fetch("https://services-restaurant.elreydelsabor.pe/all-dishes")
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
           setDishesBD(result)
           setAllDishesLoaded(true)
        },
        // Nota: es importante manejar errores aquí y no en 
        // un bloque catch() para que no interceptemos errores
        // de errores reales en los componentes.
        (error) => {
         console.log(error)
        }
      )
   }
   
   if (!allDishesLoaded) getAllDishesFirebase()
   if (!allOrdersBDLoaded) getAllOrdersBD()

   const openModal = (obj) => {
    let aux2 = {...newOrder}
    console.log(obj)
      setShowModal(true)
      const aux = []
      aux.push(obj)
      setSelectedDish(aux)

      aux2.saleId = 0
      aux2.saleId = Math.floor(Math.random() * (1000000 - 10000)) + 100

      const momentNow = moment.tz(new Date(), "America/Lima").format()
      aux2.dateRegistered = JSON.stringify({"date": momentNow});
      aux2.fromLocal = "local-restaurant"
      aux2.totalAmount = 0
      aux2.products = []
      aux2.payType = 0
      let aux3 = []
      if (obj) aux3.push(obj)
      aux2.products = aux3
      console.log(aux2)
      setNewOrder(aux2)
   }
   console.log(newOrder)

   const handleCancel = () => {
      setShowModal(false)
      setOptSelected(0)
      setNewOrder(bodyOrder)
      setKeyGenerated(null)
      // setSelectedDish(null)
      setConfirmedDelivery(false)
      setGenerateKeyActive(false)
      setInfoClientToShop(null)
      // setListShopCar([])
      if (confirmedDelivery) setConfirmedDelivery(false)
   }

   const setOptSelectedUI = (opt) => {
    const aux = {...newOrder}
    console.log(selectedDish)
    aux.products = selectedDish && selectedDish
    setNewOrder(aux)
      setOptSelected(opt)
   }

   const goBack = () => {
    setOptSelected(0)
    if (confirmedDelivery) setConfirmedDelivery(false)

   }
   const saveOrderOnline = (order, result2, listAux) => {
    axios.post(`https://services-restaurant.elreydelsabor.pe/add-order-online`
    , order,
    )
    .then(
      (result) => {
        console.log(result)
        setDishesBD([])
        listAux.push(result)
        let aux = {...order}
        if (aux.deliveryInformation) aux.deliveryInformation = JSON.parse(aux.deliveryInformation)
        if (aux.dateRegistered) aux.dateRegistered = JSON.parse(aux.dateRegistered)
        if (aux.products && typeof (aux.products) === "string") aux.products = JSON.parse(aux.products)
        if (aux.payType) aux.payType = Number(aux.payType)
        
        if (listAux && listAux.length > 1) setKeyGenerated(listAux[0])
        else setKeyGenerated(result2)
        getAllDishesFirebase()
        setTotalAmountUI(null)
        setListShopCar([])
        setNewOrder(aux)
        getAllOrdersBD()
        setTypePayClientSelectedUi(null)
      }, (error => {
        console.log(error)
      }))
   }
   const generateNumber = (aux) => {
    fetch("https://services-restaurant.elreydelsabor.pe/pay-bill")
    .then(res => res.json())
    .then(
      (result) => {
        const copyOrder = {...newOrder}
        setGenerateLoaded(true)
        if (result && result.key) {
          console.log(result)
          console.log(aux)
          if ( aux.deliveryInformation) {
            aux.deliveryInformation.orderId = result.key
          setNewOrder(aux)
          if (aux.deliveryInformation) aux.deliveryInformation = JSON.stringify(aux.deliveryInformation)
          if (aux.isDelivery === null) aux.isDelivery = false
          if (aux.state === null) aux.state = 1
            if (typeof aux.products === "string") aux.products = copyOrder.products
            const totalAmount = (aux.products) &&  calculateTotalAmount(aux.products, false)
            aux.totalAmount = totalAmount
            aux.products = JSON.stringify(aux.products)
            aux.cheffAssigned = null;
            // aux.deliveryInformation.orderId = String(aux.deliveryInformation.orderId)
            console.log(aux)
          saveOrderOnline(aux, result, listAux)
          }
        }
      },
      (error) => {
       console.log(error)
      }
      )
  }
const onFinishOpt1 = (values) => {
  const aux = { ...newOrder}
  aux.deliveryInformation = { client: values}
  aux.deliveryInformation.orderId = 0
  console.log(aux)
  console.log(allOrdersBD)
  if (allOrdersBD.length === 0) aux.id = 1;
  else {
    aux.id = allOrdersBD[allOrdersBD.length-1].id+1;
  }
  console.log('Success:', values);
  setInfoClientToShop(values)
  setGenerateKeyActive(true)
  console.log(aux)
  
  
  aux.saleId = 0
  aux.saleId = Math.floor(Math.random() * (1000000 - 10000)) + 100

  aux.urlToClient = ""
  aux.urlToClient = (aux && aux.saleId) ? `https://elreydelsabor.pe/sumary-delivery/${aux.saleId}` : null;
  setNewOrder(aux)
  generateNumber(aux)
}
const onFinishFailedOpt1 = (errorInfo) => {
  console.log('Failed:', errorInfo);  
}
   if (selectedDish) {
    console.log(selectedDish)
  }
  const handleInfo = (evt, id) => {
    if (id === "amountForPay") {
      setAmountForPay(evt)
    }
  }
  const handlePayClientUi = (e) => {
    console.log(e)
    setTypePayClientSelectedUi(e)
  }

  const handleToShopCar = (product, e) => {
    const auxList = [...listShopCar]
    let listUpdated = []
    console.log(product)
    console.log(auxList)
   if (e && product) {
    console.log(e.target.checked)
    console.log(listShopCar)
    console.log(auxList.length)
    if (auxList && auxList.length > 1 && e.target.checked === false)  {
      auxList.forEach(e => { if (e.id !== product.id) listUpdated.push(e)})
    } else if (auxList && auxList.length === 1 && e.target.checked === false && auxList[0] && auxList[0].id === product.id ) {
      console.log("entro")
      setListShopCar([])
    } else auxList.push(product)
    
    if (listUpdated && listUpdated.length > 0) setListShopCar(listUpdated)
    else setListShopCar(auxList)
   }
  }

  console.log(listShopCar)
  
  const goToShopCar = () => {
    setSelectedDish(listShopCar)
    setShowModal(true)
  }
  const onChangeQuantity = (e, value) => {
    const aux = {...newOrder}
    console.log('changed', value);
    console.log('changed', e);
    if (value && newOrder) {
      aux.products.map(k => {
        if (k.id === value.id) k.quantity = e;
        return k;
      })
    }
    console.log(aux)
    const newTotalAmount = calculateTotalAmount(newOrder.products)
    console.log(newTotalAmount)
    if (newTotalAmount) setTotalAmountUI(newTotalAmount)
    setNewOrder(aux)
  };
  console.log(listShopCar)
  console.log(newOrder)
  const btnAux = <button>VER AQUI</button>
   return (
      <Fragment>
        {(listShopCar && listShopCar.length > 1 && !activeStep) && <div class="ant-modal-mask"></div>}
        <div className="row title-aux">
          <h1><b>ESTAS VIENDO COMBOS</b></h1>
         </div>
         <PageTitle motherMenu="Nuestra carta" activeMenu={btnAux}  />
         <div className="row">
            {dishesBD.map((product) => {
               if (product.id && product.id > 13 && product.id < 27) {
                  return <Products product={product} openModal={(obj) => openModal(obj)} onChangeItem={(evt, element) => handleToShopCar(evt, element)} />
               }
               })}
         </div>
      <Modal title="Realiza tu pedido" closable={false} visible={showModal} onOk={handleCancel} cancelText="ATRAS" onCancel={goBack}
       style={{ top: 20, maxHeight: "650px"}} width={1000} className="modal-online-shop" okText="CERRAR"
        cancelButtonProps={{hidden: (optSelected === 0 || keyGenerated) ? true : false}}
       >
         {
            (optSelected === 0) ?
            <div className="row options-delivery">
            <div style={{backgroundImage: "url(../images/rey-sabor/recojo-service.png)"}} className="col-xs-12 col-sm-12 col-md-6 col-lg-6 opt1-d" onClick={() => setOptSelectedUI(1)}>
              <span>RECOJO EN TIENDA</span></div>
            <div style={{backgroundImage: "url(../images/rey-sabor/delivery-service.png)","background-position-x": "-70px"
          }} className="col-xs-12 col-sm-12 col-md-6 col-lg-6 opt2-d" onClick={() => setOptSelectedUI(2)}>
            <span>ENVIO A DOMICILIO</span></div>
         </div>
         : null
         }

         {
            (optSelected && optSelected === 2) ?
         <div className="form-delivery row">
          
          {
            (confirmedDelivery && newOrder && newOrder.deliveryInformation) ? 
            <>
               <div className="generate-key" style={{margin: "auto"}}>
                  <div>
                    <img src="../images/rey-sabor/gif-chicken.gif"  />
                    
                    <h5>¡ Muchas gracias por tu pedido, {(newOrder && newOrder.deliveryInformation && newOrder.deliveryInformation.client
                       && newOrder.deliveryInformation.client.fullNames) && newOrder.deliveryInformation.client.fullNames.toLocaleUpperCase()}!</h5>
                      <h6 className="text-red">Entrégale este código al motorizado al momento de la entrega</h6>
                    </div>
                  {
                  (keyGenerated && keyGenerated.key)
                  ? <div class="card-magic">
                    <h5>
                      {keyGenerated.key}
                      </h5>
                  </div>
                  : null
                  }
                  <br />
                  <Alert showIcon message="Nos pondrémos en contacto contigo a la brevedad" type="info" />
                 
             </div>
            </>
            : null
           }
         {
          !confirmedDelivery &&
          <>
          <table style={{margin:"auto"}} id="view-mobile">
                <tr>
                  <td><b>Item</b></td>
                  <td><b>Producto</b></td>
                  <td><b>Cantidad</b></td>
                  <td><b>Total</b></td>
                </tr>
                {
                  (selectedDish && selectedDish.length > 0) && selectedDish.map(e => {
                    return (
                      <tr>
                        <td>
                          <img src={selectedDish[0].imgUrl} style={{"max-height": "130px"}} />
                        </td>
                        <td>{e.name}</td>
                        <td style={{textAlign:"text-center"}}>{e.quantity}</td>
                        <td>S/{e.price}</td>
                      </tr>
                    )
                  })
                }
              </table>
          <Form
             {...layout}
             name="basic"
             initialValues={{ remember: true }}
             onFinish={onFinish}
             onFinishFailed={onFinishFailed}
             className={"col-lg-5 col-md-5"}
           >
             <Form.Item
               label="Nombres:"
               name="fullNames"
               rules={[{ required: true, message: 'Su nombre es requerido' }]}
             >
               <Input />
             </Form.Item>
             <Form.Item
               label="Dirección:"
               name="address"
               rules={[{ required: true, message: 'Dirección exacta es requerida' }]}
             >
               <Input />
             </Form.Item>
             <Form.Item
               label="Referencia:"
               name="reference"
             >
               <Input />
             </Form.Item>
             <Form.Item
               label="Teléfono:"
               name="phone"
               rules={[{ required: true, message: 'Teléfono es requerido' }]}
             >
               <Input />
             </Form.Item>
             {/* {
               ((isWithDelivery && withAddress) || Number(role) === 5) ?
               <h5>Total: {(totalAmountUI && totalAmountUI + 3) || "0"} soles</h5>
               :
               <h5>Total: {totalAmountUI || "0"} soles</h5>
             } */}
             <Form.Item
                 name="payTypeClient"
                 label="Tipo de pago"
                 rules={[
                   {
                     required: true,
                   },
                 ]}
               >
                 <Select
                   placeholder="Seleccione"
                   onChange={(e) => handlePayClientUi(e)}
                   // defaultValue={clientPayType}
                 >
                   {
                     PAY_TYPES && PAY_TYPES.map(e => {
                      return <Option value={e.value}>{e.label}</Option>                                
                     })
                   }                            
                 </Select>
                 {

                 }
               </Form.Item>
               {
                (typePayClientSelectedUi && typePayClientSelectedUi === 1) ?
                    <Form.Item
                    label="Cliente paga con:"
                    name="amountForPay"
                    rules={[{ required: true, message: 'Monto de pago es requerido' }]}
                  >
                    <Input />
                  </Form.Item>
                  : null
               }
               {/* <h5>Vuelto para cliente es de: {(isWithDelivery && clientPayType === 1 && finalOrder && finalOrder.callCenterAssignedUI && finalOrder.callCenterAssignedUI.cod) ?
               ((amountForPay > 0 && totalAmountUI > 0) ? amountForPay - (totalAmountUI + 3) : 0) : 
               ((amountForPay > 0 && totalAmountUI > 0) ? amountForPay - (totalAmountUI) : 0)
               } soles</h5> */}
          
             <Form.Item
               label="Nota del cliente:"
               name="noteToDelivery"
             >
               <Input />
             </Form.Item>
             <Form.Item
               label="Nota al cocinero:"
               name="noteToCheff"
             >
               <Input />
             </Form.Item>
             {/* <Form.Item {...tailLayout} name="remember" valuePropName="checked">
               <Checkbox>Remember me</Checkbox>
             </Form.Item> */}
             
             <h4><b>TOTAL: S/ {calculateTotalAmount(newOrder.products ? newOrder.products : listShopCar, true)}</b></h4>
             <Form.Item {...tailLayout}>
               <Button type="primary" danger htmlType="submit">
                 REGISTRAR PEDIDO
               </Button>
             </Form.Item>
             {/* <button className="btn btn-primary" onClick={() => cancelR()}>
               VOLVER ATRAS
             </button> */}
             
           {/* {
            ((optSelected === 1 || optSelected === 2) && !generateKeyActive) &&
                <>
                <Button type="primary" onClick={() => goBack()}>
                ATRAS
              </Button>
                </>
          } */}
           </Form>
          </>
          }
            {
          !confirmedDelivery &&
              <div className="code-generate col-lg-7 col-md-7">
              {
              (selectedDish && selectedDish[0] && (selectedDish.length <= 1)) &&
              <img src={selectedDish[0].imgUrl} id="view-desktop" style={{"max-height": "65%"}} />
              }
              <table style={{"width":"100%"}} id="view-desktop">
                <tr>
                  <td><b>Item</b></td>
                  <td><b>Producto</b></td>
                  <td><b>Cantidad</b></td>
                  <td><b>Precio</b></td>
                </tr>
                {
                  (selectedDish && selectedDish.length > 0) && selectedDish.map(e => {
                    return (                      
                    <tr>
                      <td>
                        <img src={selectedDish[0].imgUrl} style={{"max-height": "130px"}} />
                      </td>
                      <td>{e.name}</td>
                      <td>
                        {/* {e.quantity} */}
                        <InputNumber min={1} max={50} defaultValue={e.quantity} onChange={(evt) => onChangeQuantity(evt, e)} />
                        </td>
                      <td>S/{e.price}</td>
                    </tr>
                    )
                  })
                }
              </table>
            </div>
          }
         </div>
         : null
         }
         <div className="row">
         {
            ((optSelected && optSelected === 1) && !generateKeyActive) ?
            <div className="code-generate col-sm-12 col-md-7 col-lg-7" >
              <br />
              <table style={{"width":"100%"}}>
                <tr>
                  <td>Producto(s)</td>
                  <td>Nombre</td>
                  <td>Cantidad</td>
                  <td>Total</td>
                </tr>
                {
                  (selectedDish && selectedDish.length > 0) && selectedDish.map(e => {
                    return (                      
                      <tr>
                      <td>
                        <img src={e.imgUrl} style={{"max-width": "120px"}} />
                        </td>
                      <td>{e.name}</td>
                      <td>
                        {/* {e.quantity} */}
                        
                        <InputNumber min={1} max={50} defaultValue={e.quantity} onChange={(evt) => onChangeQuantity(evt, e)} />
                        </td>
                      <td>S/{e.price}</td>
                    </tr>
                    )
                  })
                }
              </table>
            </div> : null
          }
          {
            (optSelected === 1 && !generateKeyActive) &&
            <div className="col-sm-12 col-md-4 col-lg-5">
                    <Form
                      {...layout}
                      name="basic"
                      initialValues={{ remember: true }}
                      onFinish={onFinishOpt1}
                      onFinishFailed={onFinishFailedOpt1}
                    >
                    <Form.Item
                      label="Nombres"
                      name="fullNames"
                    >
                      <Input />
                    </Form.Item>
                      <Form.Item
                        label="Teléfono:"
                        name="phone"
                        rules={[{ required: true, message: 'Teléfono es requerido' }]}
                      >
                        <Input />
                      </Form.Item>
                    
                      <Form.Item {...tailLayout}>
                      <h4><b>TOTAL: S/ {calculateTotalAmount(newOrder.products, true)}</b></h4>

                        <Button type="primary" danger htmlType="submit">
                          CONFIRMAR PEDIDO
                        </Button>
                      </Form.Item>
                    </Form>
            </div>
          }
         </div>
        
          {
            generateKeyActive &&
            <GenerateKey generateKeyActive={generateKeyActive} productSelected={selectedDish}
             keyGenerated={keyGenerated} infoClientToShop={infoClientToShop}/>
          }
         </Modal>
         <Button id="btn-wishlist" className={(listShopCar && listShopCar.length > 1 ? "active" : "desactive")} type="primary" style={{background: "white"}} danger onClick={() => goToShopCar()}>
          <b style={{fontSize: "1.1em"}}>MI LISTA DE DESEO</b></Button>
      </Fragment>
   );
};

export default ProductGrid;
