export const ALLMENU = [
  {
    id: 1,
    name: "Tequeños anticucheros",
    price: 12,
    ingredients: "",
    desc: "6 tequeños de anticucho con guacamole",
    generalCategory: 1,
    generalSubcategory: 1,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/tequeños-anticucheros.jpeg",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 2,
    name: "Chorizo parrillero",
    price: 17,
    ingredients: "",
    desc: "",
    generalCategory: 1,
    generalSubcategory: 1,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/chorizo-parrillero.jpeg",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 3,
    name: "Anticucho parrillero",
    price: 21,
    ingredients: "",
    desc: "",
    generalCategory: 1,
    generalSubcategory: 1,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/anticucho-parrillero.jpeg",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 4,
    name: "Mollejitas a la parrilla",
    price: 20,
    ingredients: "",
    desc: "",
    generalCategory: 1,
    generalSubcategory: 1,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/mollejitas-parrilla.jpeg",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 5,
    name: "Salchipapa especial",
    price: 17,
    ingredients: "",
    desc: "",
    generalCategory: 1,
    generalSubcategory: 1,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/salchi-especial.jpeg",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 6,
    name: "Salchipapa del Rey",
    price: 14,
    ingredients: "",
    desc: "",
    generalCategory: 1,
    generalSubcategory: 2,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/salchipapa-rey.jpeg",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 7,
    name: "Chuleta parrillera",
    price: 22,
    ingredients: "",
    desc: "",
    generalCategory: 1,
    generalSubcategory: 2,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/chuleta-parrillera.jpeg",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 8,
    name: "Bistec a la parrilla",
    price: 25,
    ingredients: "",
    desc: "",
    generalCategory: 1,
    generalSubcategory: 2,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/bistec-parrilla.jpeg",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 9,
    name: "Filete de pierna a la parilla",
    price: 19,
    ingredients: "",
    desc: "",
    generalCategory: 1,
    generalSubcategory: 2,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/filete-pierna.jpeg",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 10,
    name: "Pechuga a la parrilla",
    price: 22,
    ingredients: "",
    desc: "",
    generalCategory: 1,
    generalSubcategory: 2,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/pechuga-parrilla.jpeg",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 11,
    name: "Churrasco a la parrilla",
    price: 23,
    ingredients: "",
    desc: "",
    generalCategory: 1,
    generalSubcategory: 3,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/churrasco-parrilla.jpeg",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 12,
    name: "Parrilla familiar",
    price: 105,
    ingredients: "",
    desc: "",
    generalCategory: 1,
    generalSubcategory: 3,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/parrilla-familiar.jpeg",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 13,
    name: "Parrilla lovers",
    price: 67,
    ingredients: "",
    desc: "",
    generalCategory: 1,
    generalSubcategory: 3,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/parrilla-lovers.jpeg",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  // COMBOS
  {
    id: 14,
    name: "Combo 1",
    price: 123,
    ingredients: "",
    chickenQuantity: 1.5,
    drinkIdIncluded: 27,
    desc: "2 POLLOS + 2 INKA KOLA 1.5L O CHICHA MORADA + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    generalCategory: 2,
    generalSubcategory: 3,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/combo1.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 15,
    name: "Combo 2",
    price: 80,
    ingredients: "",
    chickenQuantity: 1.5,
    desc: "1 POLLO + 1/2 POLLO + 1 INKA KOLA 1.5L O CHICHA MORADA + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    generalCategory: 2,
    generalSubcategory: 3,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/combo2.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 16,
    name: "Combo 3",
    price: 77,
    ingredients: "",
    chickenQuantity: 1.25,
    drinkIdIncluded: 27,
    desc: "1 POLLO + 1/2 POLLO + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    generalCategory: 2,
    generalSubcategory: 3,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/combo3.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 17,
    name: "Combo 4",
    price: 77,
    ingredients: "",
    chickenQuantity: 1.25,
    desc: "1 POLLO + 1/4 POLLO + 1 INKA KOLA 1.5L O CHICHA MORADA + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    generalCategory: 2,
    generalSubcategory: 3,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/combo4.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 18,
    name: "Combo 5",
    price: 75,
    ingredients: "",
    chickenQuantity: 1,
    drinkIdIncluded: 28,
    desc: "1 POLLO + 1/4 POLLO + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    generalCategory: 2,
    generalSubcategory: 3,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/combo5.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 19,
    name: "Combo 6",
    price: 65,
    ingredients: "",
    chickenQuantity: 1,
    desc: "1 POLLO + 1 INKA KOLA 1.5L O CHICHA MORADA + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    generalCategory: 2,
    generalSubcategory: 3,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/combo6.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 20,
    name: "Combo 7",
    price: 58,
    ingredients: "",
    chickenQuantity: 0.5,
    drinkIdIncluded: 27,
    desc: "1 POLLO + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    generalCategory: 2,
    generalSubcategory: 3,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/combo7.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 21,
    name: "Combo 8",
    price: 41,
    ingredients: "",
    chickenQuantity: 0.5,
    drinkIdIncluded: 28,
    desc: "1/2 POLLO + 1 INKA KOLA 1.5L O CHICHA MORADA + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    generalCategory: 2,
    generalSubcategory: 3,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/combo8.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 22,
    name: "Combo 9",
    price: 40,
    ingredients: "",
    chickenQuantity: 0.5,
    drinkIdIncluded: 29,
    desc: "1/2 POLLO + 1 INKA KOLA 1L O CHICHA MORADA + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    generalCategory: 2,
    generalSubcategory: 3,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/combo9.png",
    kitchenAuthorized: ["cocna02"]
  },
  {
    id: 23,
    name: "Combo 10",
    price: 38,
    ingredients: "",
    chickenQuantity: 0.5,
    drinkIdIncluded: 30,
    desc: "1/2 POLLO + 1 INKA KOLA O GORDITA + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    generalCategory: 2,
    generalSubcategory: 3,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/combo10.png",
    kitchenAuthorized: ["cocna02"]
  },
  {
    id: 24,
    name: "Combo 11",
    price: 34,
    ingredients: "",
    chickenQuantity: 0.5,
    desc: "1/2 POLLO + GASEOSA DESCARTABLE + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    generalCategory: 2,
    generalSubcategory: 3,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/combo11.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 25,
    name: "Combo 12",
    price: 19,
    ingredients: "",
    chickenQuantity: 0.25,
    drinkIdIncluded: 30,
    desc: "1/2 POLLO + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    generalCategory: 2,
    generalSubcategory: 6,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/combo12.png",
    kitchenAuthorized: ["cocna02"]
  },
  {
    id: 26,
    name: "Combo 13",
    price: 20,
    ingredients: "",
    chickenQuantity: 0.25,
    drinkIdIncluded: 31,
    desc: "1/4 POLLO + GASEOSA MEDIANA O CHICHA MORADA + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    generalCategory: 2,
    generalSubcategory: 7,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/combo13.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 48,
    name: "Combo 14",
    price: 23,
    ingredients: "",
    chickenQuantity: 0.25,
    drinkIdIncluded: 31,
    desc: "1/4 POLLO + GASEOSA MEDIANA O CHICHA MORADA + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    generalCategory: 2,
    generalSubcategory: 7,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/combo14.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 49,
    name: "Combo 15",
    price: 20,
    ingredients: "",
    chickenQuantity: 0.25,
    drinkIdIncluded: 31,
    desc: "1/4 POLLO + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    generalCategory: 2,
    generalSubcategory: 7,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/combo15.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  // BEBIDAS
  {
    id: 27,
    name: "Gaseosa 1.5 Litros",
    price: 10,
    ingredients: "",
    desc: "",
    generalCategory: 3,
    generalSubcategory: 7,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/inka-1p5.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  }, 
  {
    id: 28,
    name: "Gaseosa 1 Litro",
    price: 3,
    ingredients: "",
    desc: "",
    generalCategory: 3,
    generalSubcategory: 7,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/inka-1.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 29,
    name: "Gordita",
    price: 5,
    ingredients: "",
    desc: "",
    generalCategory: 3,
    generalSubcategory: 3,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/gordita.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 30,
    name: "Gaseosa 500ml",
    price: 5,
    ingredients: "",
    desc: "",
    generalCategory: 3,
    generalSubcategory: 3,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/500vidrio.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 31,
    name: "Gaseosa 500ml descartable",
    price: 5,
    ingredients: "",
    desc: "",
    generalCategory: 3,
    generalSubcategory: 3,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/500desc.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 32,
    name: "Agua",
    price: 3.5,
    ingredients: "",
    desc: "",
    generalCategory: 3,
    generalSubcategory: 3,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/agua500.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 33,
    name: "Chicha morada 1L",
    price: 8,
    ingredients: "",
    desc: "",
    generalCategory: 3,
    generalSubcategory: 3,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/chicha.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 34,
    name: "Chicha morada 1/2 L",
    price: 4,
    ingredients: "",
    desc: "",
    generalCategory: 3,
    generalSubcategory: 3,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/chicha-med.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 35,
    name: "Cusqueña",
    price: 6,
    ingredients: "",
    desc: "",
    generalCategory: 3,
    generalSubcategory: 3,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/cusqueña.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 36,
    name: "Pilsen",
    price: 6,
    ingredients: "",
    desc: "",
    generalCategory: 3,
    generalSubcategory: 3,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/pilsen.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 37,
    name: "Cristal",
    price: 5,
    ingredients: "",
    desc: "",
    generalCategory: 3,
    generalSubcategory: 3,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/cristal.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 38,
    name: "Corona",
    price: 6,
    ingredients: "",
    desc: "",
    generalCategory: 3,
    generalSubcategory: 3,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/corona.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 39,
    name: "Vino",
    price: 22,
    ingredients: "",
    desc: "",
    generalCategory: 3,
    generalSubcategory: 3,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/vino.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  // CREMAS
  {
    id: 40,
    name: "Mayonesa - 4oz",
    price: 4,
    ingredients: "",
    desc: "",
    generalCategory: 4,
    generalSubcategory: 3,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/4oz.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 41,
    name: "Ají, ketchup, etc - 6oz",
    price: 7,
    ingredients: "",
    desc: "",
    generalCategory: 4,
    generalSubcategory: 3,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/8oz.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 42,
    name: "Chimichurri - 4oz",
    price: 5,
    ingredients: "",
    desc: "",
    generalCategory: 4,
    generalSubcategory: 3,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/chimi.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  //GUARNICIONES
  {
    id: 43,
    name: "Porción de papas fritas",
    price: 10,
    ingredients: "",
    desc: "",
    generalCategory: 5,
    generalSubcategory: 3,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/papasfrit.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 44,
    name: "Ensalada",
    price: 5,
    ingredients: "",
    desc: "",
    generalCategory: 5,
    generalSubcategory: 3,
    imgUrl: "https://elreydelsabor.pe/images/rey-sabor/ensalada.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  }
]

export const GENERAL_CATEGORIES = [
  {
    label: "PARRILLAS",
    value: 1
  },
  {
    label: "COMBOS",
    value: 2
  },
  {
    label: "BEBIDAS",
    value: 3
  },
  {
    label: "CREMAS",
    value: 4
  },
  {
    label: "GUARNICIONES",
    value: 5
  },
  {
    label: "INFUSIONES",
    value: 6
  }
]

export const GENERAL_SUBCATEGORIES = [
  {
    label: "CARNES Y PLATOS A LA PARRILLA",
    value: 1
  },
  {
    label: "POLLO",
    value: 2
  },
  {
    label: "SANGUCHES",
    value: 3
  },
  {
    label: "FAVORITOS",
    value: 4
  },
  {
    label: "GUARNICIONES",
    value: 5
  },
  {
    label: "ENDULZATE EL DIA",
    value: 6
  },
  {
    label: "PARA LA SED",
    value: 7
  }
]

export const STATES_ORDER = [
  {
    label: "Borrador",
    value: 1
  },
  {
    label: "Por preparar",
    value: 2
  },
  {
    label: "Pagado",
    value: 3
  },
  {
    label: "Cancelado",
    value: 4
  },
   {
     label: "Delivery Pagado",
     value: 5
   },
   {
     label: "Devuelto",
     value: 6
   },
   {
     label: "Listo para mesa",
     value: 7
   },
   {
    label: "Listo para llevar",
    value: 8
  },
   {
     label: "En camino",
     value: 9
   },
   {
     label: "Entregado",
     value: 10
   }
]

export const STATES_MOTORCYCLES = [
  {
    label: "Disponible",
    value: 1
  },
  {
    label: "Ocupado",
    value: 2
  }
]

export const PAY_TYPES = [
  {
    label: "Efectivo",
    value: 1
  },
  {
    label: "Visa",
    value: 2
  },
  {
    label: "Mastercard",
    value: 3
  },
  {
    label: "Yape",
    value: 4
  },
  {
    label: "Plin",
    value: 5
  }
]

export const USERS = [
  {
    username: "robert.quiroz",
    password: "espinoza1",
    cod: "admn01",
    name:"Robert Quiroz",
    role: 1,
    token: "DASKLFJASIJDSALDJHDSS"
  },
  {
    username: "rolando.canales",
    password: "cruz1",
    cod: "admn02",
    name:"Rolando Canales",
    role: 1,
    token: "DASKLFJASIJDSALDJHDSS"
  },
  // ASISTENTES DE COCINA
    {
    username: "cocina1",
    password: "cocina1",
    cod: "cocna01",
    name:"Cocina Delivery",
    role: 2,
    token: "JKLDSAJNVLKEJBOIRVH"
  },
  {
    username: "cocina2",
    password: "cocina2",
    cod: "cocna02",
    name:"Cocina Restaurante",
    role: 2,
    token: "67843123DJKSADKSASF"
  },
  {
    username: "cocina3",
    password: "cocina3",
    cod: "cocna02",
    name:"Cocina Restaurante",
    role: 2,
    token: "67843123DJKSADKSASF"
  },
  // ASISTENTES DE CAJA LOCALES SURCO
  {
    username: "caja1",
    password: "caja1",
    cod:"cja01",
    name:"Asistente de caja 1",
    role: 3,
    token: "OPKBJIOSDLAJBNIMESHV"
  },
  {
    username: "caja2",
    password: "caja2",
    cod:"cja02",
    name:"Asistente de caja 2",
    role: 3,
    token: "58234HJFASJBNIMESHV"
  },
  // MESEROS LOCAL SURCO
  {
    username: "sala1",
    password: "sala1",
    cod: "agntmsa01",
    name:"Mesero 1",
    role: 4,
    token: "O3451263748KMVNAKDUHDU"
  },
  {
    username: "sala2",
    password: "sala2",
    name:"Mesero 2",
    cod: "agntmsa02",
    role: 4,
    token: "98509243JUITYESADFEF"
  },
  {
    username: "sala3",
    password: "sala3",
    cod: "agntmsa03",
    name:"Mesero 3",
    role: 4,
    token: "OIHUEIUVJLQUFIJSADFEF"
  },
  //AGENTES CALL CENTER
  {
    username: "callcenter1",
    password: "callcenter1",
    cod: "call01",
    name:"Alberto Flores",
    role: 5,
    token: "UDIASOUDHUEE(#3$fDSUTT"
  },
  {
    username: "callcenter2",
    password: "callcenter2",
    cod: "call02",
    name:"Gonzalo Delfín",
    role: 5,
    token: "432khFDSFDSG(#3$fDSUTT"
  },
  {
    username: "callcenter3",
    password: "callcenter3",
    cod: "call03",
    name:"Gabriel Rodriguez",
    role: 5,
    token: "UDI478324952JVKSDLFT"
  },
  // MOTORIZADOS
  {
    username: "moto1",
    password: "moto1",
    cod: "mto01",
    name:"Alex",
    role: 6,
    token: "UDI478&/#24952JVKSDLFT"
  },
  {
    username: "moto2",
    password: "moto2",
    cod: "mto02",
    name:"Rafael",
    role: 6,
    token: "UDI478324952JVKSDLFT"
  },
  {
    username: "moto3",
    password: "moto3",
    cod: "mto03",
    name:"Andrés",
    role: 6,
    token: "UDI478324952JVKSDLFT"
  },
  // APERTURA
  {
    username: "apertura1",
    password: "apertura1",
    cod: "apt01",
    name:"Usuario apertura",
    role: 7,
    token: "KÑLJDSANFLKJHKRW732847"
  }
]

export const ALL_WAITRESS = [
  {
    fullNames: "mesero 1",
    code: 1
  },
  {
    fullNames: "mesero 2",
    code: 2
  },
  {
    fullNames: "mesero 3",
    code: 3
  }
]

export const ROLES = [
  {
    label: "Administrador",
    value: 1
  },
  {
    label: "Asistente de cocina",
    value: 2
  },
  {
    label: "Asistente de caja",
    value: 3
  },
  {
    label: "Meseros",
    value: 4
  },
  {
    label: "Call Center",
    value: 5
  },
  {
    label: "Motorizado",
    value: 6
  },
  {
    label: "Apertura",
    value: 7
  }
]

export const REASONS_RETURN_ORDER = [
  {
    reason: "Demora de entrega",
    cod: 1
  },
  {
    reason: "Pedido comida fría",
    cod: 2
  },
  {
    reason: "Calidad de servicio",
    cod: 3
  }
]

export const REASONS_CANCEL_ORDER = [
  {
    reason: "BILLETE FALSO O ROTO",
    cod: 1
  },
  {
    reason: "A SOLICITUD DE CLIENTE",
    cod: 2
  }
]

export const OPEN_DAYS_STATES = [
  {
    label: "APERTURADO",
    value: 1
  },
  {
    label: "CERRADO",
    value: 2
  }
]

export const DATA_DISH = [
  {
    "amount": 14,
    "description": "Tequeños anticucheros",
    "generalCategory": 1,
    "generalSubcategory": 1,
    "id": 1,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/tequeños-anticucheros.jpeg",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7i0sHvBIh98fRHU",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Tequeños anticucheros",
    "price": 14,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 19,
    "description": "Chorizo parrillero",
    "generalCategory": 1,
    "generalSubcategory": 1,
    "id": 2,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/chorizo-parrillero.jpeg",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7i2Tbpr3OpsoXvl",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Chorizo parrillero",
    "price": 19,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 23,
    "description": "Anticucho parrillero",
    "generalCategory": 1,
    "generalSubcategory": 1,
    "id": 3,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/anticucho-parrillero.jpeg",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7i3lqLlnKcmw3p2",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Anticucho parrillero",
    "price": 23,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 20,
    "description": "Mollejitas a la parrilla",
    "generalCategory": 1,
    "generalSubcategory": 1,
    "id": 4,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/mollejitas-parrilla.jpeg",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7i4eCsTA90loAUi",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Mollejitas a la parrilla",
    "price": 20,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 17,
    "description": "Salchipapa especial",
    "generalCategory": 1,
    "generalSubcategory": 1,
    "id": 5,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/salchi-especial.jpeg",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7i5x8jkK3Nqw9O6",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Salchipapa especial",
    "price": 17,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 15,
    "description": "1/4 POLLO + GASEOSA MEDIANA O CHICHA MORADA + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    "generalCategory": 1,
    "generalSubcategory": 2,
    "id": 6,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/salchipapa-rey.jpeg",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7i6fbG5mHxt6DSY",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Salchipapa del Rey",
    "price": 15,
    "quantity": 2,
    "soldsUnids": 0
  },
  {
    "amount": 24,
    "description": "Chuleta parrillera",
    "generalCategory": 1,
    "generalSubcategory": 2,
    "id": 7,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/chuleta-parrillera.jpeg",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7i6fbG5mHxt6DSZ",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Chuleta parrillera",
    "price": 24,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 28,
    "description": "Bistec a la parrilla",
    "generalCategory": 1,
    "generalSubcategory": 2,
    "id": 8,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/bistec-parrilla.jpeg",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7i7wVHZGyISGUgR",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Bistec a la parrilla",
    "price": 28,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 21,
    "description": "Filete de pierna a la parilla",
    "generalCategory": 1,
    "generalSubcategory": 2,
    "id": 9,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/filete-pierna.jpeg",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7i8-hAkRYfaJvd4",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Filete de pierna a la parilla",
    "price": 21,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 24,
    "description": "Pechuga a la parrilla",
    "generalCategory": 1,
    "generalSubcategory": 2,
    "id": 10,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/pechuga-parrilla.jpeg",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iA-mcw_hKOvukp",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Pechuga a la parrilla",
    "price": 24,
    "quantity": 2,
    "soldsUnids": 0
  },
  {
    "amount": 25,
    "description": "Churrasco a la parrilla",
    "generalCategory": 1,
    "generalSubcategory": 3,
    "id": 11,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/churrasco-parrilla.jpeg",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iBkoqcjYzb6JyQ",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Churrasco a la parrilla",
    "price": 25,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 118,
    "description": "Parrilla familiar",
    "generalCategory": 1,
    "generalSubcategory": 3,
    "id": 12,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/parrilla-familiar.jpeg",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iBkoqcjYzb6JyR",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Parrilla familiar",
    "price": 118,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 75,
    "description": "Parrilla lovers",
    "generalCategory": 1,
    "generalSubcategory": 3,
    "id": 13,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/parrilla-lovers.jpeg",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iCNOGnF3TF1mdA",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Parrilla lovers",
    "price": 75,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 128,
    "chickenQuantity": 1.5,
    "description": "2 POLLOS + 2 INKA KOLA 1.5L O CHICHA MORADA + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    "drinkIdIncluded": 27,
    "generalCategory": 2,
    "generalSubcategory": 3,
    "id": 14,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/combo1.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iCNOGnF3TF1mdB",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Combo 1",
    "price": 128,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 85,
    "chickenQuantity": 1.5,
    "description": "Combo 2",
    "drinkIdIncluded": 27,
    "generalCategory": 2,
    "generalSubcategory": 3,
    "id": 15,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/combo.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iDd2X-ywACPZtp",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Combo 2",
    "price": 85,
    "quantity": 1,
    "soldsUnids": 0
  },
 {
    "amount": 83,
    "chickenQuantity": 1.25,
    "description": "Combo 3",
    "drinkIdIncluded": 27,
    "generalCategory": 2,
    "generalSubcategory": 3,
    "id": 16,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/combo3.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iDd2X-ywACPZtq",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Combo 3",
    "price": 83,
    "quantity": 1,
    "soldsUnids": 0
  },
 {
    "amount": 83,
    "chickenQuantity": 1.25,
    "description": "Combo 4",
    "generalCategory": 2,
    "generalSubcategory": 3,
    "id": 17,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/combo4.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iEVRbNKN3VSaHA",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Combo 4",
    "price": 83,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 80,
    "chickenQuantity": 1,
    "description": "Combo 5",
    "drinkIdIncluded": 28,
    "generalCategory": 2,
    "generalSubcategory": 3,
    "id": 18,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/combo5.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iEVRbNKN3VSaHB",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Combo 5",
    "price": 80,
    "quantity": 1,
    "soldsUnids": 0
  },
   {
    "amount": 69,
    "chickenQuantity": 1,
    "description": "1 POLLO + 1 INKA KOLA 1.5L O CHICHA MORADA + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    "generalCategory": 2,
    "generalSubcategory": 3,
    "id": 19,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/combo6.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iFtpfapdTLZt8Z",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Combo 6",
    "price": 69,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 61,
    "chickenQuantity": 0.5,
    "description": "1 POLLO + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    "drinkIdIncluded": 27,
    "generalCategory": 2,
    "generalSubcategory": 3,
    "id": 20,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/combo7.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iFtpfapdTLZt8_",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Combo 7",
    "price": 61,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 44,
    "chickenQuantity": 0.5,
    "description": "1/2 POLLO + 1 INKA KOLA 1.5L O CHICHA MORADA + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    "drinkIdIncluded": 28,
    "generalCategory": 2,
    "generalSubcategory": 3,
    "id": 21,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/combo8.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iG2QF004ldUtIT",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Combo 8",
    "price": 44,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 41,
    "chickenQuantity": 0.5,
    "description": "Combo 9",
    "drinkIdIncluded": 30,
    "generalCategory": 2,
    "generalSubcategory": 3,
    "id": 22,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/combo9.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iG2QF004ldUtIU",
    "kitchenAuthorized": [
      "cocna02"
    ],
    "name": "Combo 9",
    "price": 41,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 40,
    "chickenQuantity": 0.5,
    "description": "1/2 POLLO + 1 INKA KOLA O GORDITA + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    "drinkIdIncluded": 30,
    "generalCategory": 2,
    "generalSubcategory": 3,
    "id": 23,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/combo10.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iHI6twJZ0uxIHw",
    "kitchenAuthorized": [
      "cocna02"
    ],
    "name": "Combo 10",
    "price": 40,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 40,
    "chickenQuantity": 0.5,
    "description": "1/2 POLLO + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    "generalCategory": 2,
    "generalSubcategory": 3,
    "id": 24,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/combo11.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iHI6twJZ0uxIHx",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Combo 11",
    "price": 40,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 37,
    "chickenQuantity": 0.25,
    "description": "1/4 POLLO + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    "drinkIdIncluded": 30,
    "generalCategory": 2,
    "generalSubcategory": 6,
    "id": 25,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/combo12.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iI5ImwmihB3oRn",
    "kitchenAuthorized": [
      "cocna02"
    ],
    "name": "Combo 12",
    "price": 37,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 22,
    "chickenQuantity": 0.25,
    "description": "1/4 POLLO + GASEOSA MEDIANA O CHICHA MORADA + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    "drinkIdIncluded": 31,
    "generalCategory": 2,
    "generalSubcategory": 7,
    "id": 26,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/combo13.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iI5ImwmihB3oRo",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Combo 13",
    "price": 22,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 12,
    "description": "Gaseosa 1.5 Litros",
    "drinkQuantity": 1.5,
    "generalCategory": 3,
    "generalSubcategory": 7,
    "id": 27,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/inka-1p5.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iJpnsj4exXJxGz",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Gaseosa 1.5 Litros",
    "price": 12,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 10,
    "description": "Gaseosa 1 Litro",
    "drinkQuantity": 1,
    "generalCategory": 3,
    "generalSubcategory": 7,
    "id": 28,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/inka-1.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iJpnsj4exXJxH-",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Gaseosa 1 Litro",
    "price": 10,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 6,
    "description": "Gordita",
    "drinkQuantity": 0.7,
    "generalCategory": 3,
    "generalSubcategory": 3,
    "id": 29,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/gordita.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iKIfpYlmCHoHrF",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Gordita",
    "price": 6,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 3,
    "description": "Gaseosa 296ml",
    "drinkQuantity": 0.5,
    "generalCategory": 3,
    "generalSubcategory": 3,
    "id": 30,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/500vidrio.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iKIfpYlmCHoHrG",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Gaseosa 296ml",
    "price": 3,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 4.5,
    "description": "Gaseosa 500ml descartable",
    "drinkQuantity": 0.5,
    "generalCategory": 3,
    "generalSubcategory": 3,
    "id": 31,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/500desc.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iLnGCzjxOqR2CH",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Gaseosa 500ml descartable",
    "price": 4.5,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 3.5,
    "description": "Agua 500ml",
    "drinkQuantity": 0.5,
    "generalCategory": 3,
    "generalSubcategory": 3,
    "id": 32,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/agua500.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iLnGCzjxOqR2CI",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Agua 500ml",
    "price": 3.5,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 14,
    "description": "Chicha morada 1L",
    "drinkQuantity": 1,
    "generalCategory": 3,
    "generalSubcategory": 3,
    "id": 33,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/chicha.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iMLlZgnkkBNBgK",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Chicha morada 1L",
    "price": 14,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 8,
    "description": "Chicha morada 1/2 L",
    "drinkQuantity": 0.5,
    "generalCategory": 3,
    "generalSubcategory": 3,
    "id": 34,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/chicha-med.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iMLlZgnkkBNBgL",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Chicha morada 1/2 L",
    "price": 8,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 6,
    "description": "Cusqueña 310ml",
    "drinkQuantity": 0.6,
    "generalCategory": 3,
    "generalSubcategory": 3,
    "id": 35,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/cusqueña600.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iN_DYSIpk7zWqB",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Cusqueña 310ml",
    "price": 6,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 6,
    "description": "Pilsen 305ml",
    "drinkQuantity": 0.8,
    "generalCategory": 3,
    "generalSubcategory": 3,
    "id": 36,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/pilsen.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iN_DYSIpk7zWqC",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Pilsen 305ml",
    "price": 6,
    "quantity": 4,
    "soldsUnids": 0
  },
  {
    "description": "Cristal",
    "drinkQuantity": 0.8,
    "generalCategory": 3,
    "generalSubcategory": 3,
    "id": 37,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/cristal.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iOd7r7_Dm0C8Gi",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Cristal 305ml",
    "price": 5,
    "soldsUnids": 0
  },
  {
    "amount": 6,
    "description": "Corona 355ml",
    "drinkQuantity": 0.5,
    "generalCategory": 3,
    "generalSubcategory": 3,
    "id": 38,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/corona.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iOd7r7_Dm0C8Gj",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Corona 355ml",
    "price": 7,
    "quantity": 2,
    "soldsUnids": 0
  },
  {
    "amount": 22,
    "description": "Vino 750ml",
    "drinkQuantity": 0.75,
    "generalCategory": 3,
    "generalSubcategory": 3,
    "id": 39,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/vino.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iPmNmE_5XzVuOF",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Vino 750ml",
    "price": 22,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 5,
    "description": "Cremas de la casa - 4oz",
    "generalCategory": 4,
    "generalSubcategory": 3,
    "id": 40,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/4oz.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iPmNmE_5XzVuOG",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Cremas de la casa - 4oz",
    "price": 5,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 8,
    "description": "Cremas de la casa - 6oz",
    "generalCategory": 4,
    "generalSubcategory": 3,
    "id": 41,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/8oz.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iQrYDoihOpqq_0",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Cremas de la casa - 6oz",
    "price": 8,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 7,
    "description": "Chimichurri - 4oz",
    "generalCategory": 4,
    "generalSubcategory": 3,
    "id": 42,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/chimi.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iQrYDoihOpqq_1",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Chimichurri - 4oz",
    "price": 7,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 12,
    "description": "Porción de papas fritas",
    "generalCategory": 5,
    "generalSubcategory": 3,
    "id": 43,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/papasfrit.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iRdlRfCDqHjEBC",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Porción de papas fritas",
    "price": 12,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 5,
    "description": "Café",
    "generalCategory": 6,
    "generalSubcategory": 3,
    "id": 44,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/cafe.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iRdlRfCDqHjEBD",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Café",
    "price": 5,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 5,
    "description": "Té",
    "generalCategory": 6,
    "generalSubcategory": 3,
    "id": 45,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/te.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iRdlRfCDqHjEBD",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Té",
    "price": 4,
    "quantity": 1,
    "soldsUnids": 1
  },
  {
    "amount": 5,
    "description": "Manzanilla",
    "generalCategory": 6,
    "generalSubcategory": 3,
    "id": 46,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/manzanilla.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iRdlRfCDqHjEBD",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Manzanilla",
    "price": 4,
    "quantity": 1,
    "soldsUnids": 1
  },
  {
    "amount": 5,
    "description": "Café",
    "generalCategory": 6,
    "generalSubcategory": 3,
    "id": 47,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/cafe.png",
    "ingredients": "",
    "keyBD": "DISHID-Mmju7iRdlRfCDqHjEBD",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Café",
    "price": 5,
    "quantity": 1,
    "soldsUnids": 1
  },
  {
    "amount": 23,
    "chickenQuantity": 0.25,
    "description": "1/4 POLLO + GASEOSA MEDIANA O CHICHA MORADA + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    "drinkIdIncluded": 31,
    "generalCategory": 2,
    "generalSubcategory": 7,
    "id": 48,
    "imgUrl": "",
    "ingredients": "",
    "keyBD": "DISHID-N2UgafDfcHp_OXYX6-r",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Combo 14",
    "price": 23,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 20,
    "description": "Combo 15",
    "drinkIdIncluded": 31,
    "generalCategory": 2,
    "generalSubcategory": 7,
    "id": 49,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/combo15.png",
    "ingredients": "",
    "keyBD": "DISHID-N2Ui2kBHYroWtHSLVKq",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Combo 15",
    "price": 20,
    "quantity": 2,
    "soldsUnids": 0
  },
  {
    "amount": 7,
    "description": "Ensalada",
    "generalCategory": 5,
    "generalSubcategory": 3,
    "id": 50,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/ensalada.png",
    "ingredients": "",
    "keyBD": "DISHID-N2XbySEu9CQR4B7cCdI",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Ensalada",
    "price": 7,
    "quantity": 1,
    "soldsUnids": 0
  },
  {
    "amount": 1.5,
    "description": "Taper",
    "generalCategory": 5,
    "generalSubcategory": 3,
    "id": 51,
    "imgUrl": "https://elreydelsabor.pe/images/rey-sabor/taper.png",
    "ingredients": "",
    "keyBD": "DISHID-N2ZK5fVB8ECJBgN7USx",
    "kitchenAuthorized": [
      "cocna01",
      "cocna02"
    ],
    "name": "Taper",
    "price": 1.5,
    "quantity": 1,
    "soldsUnids": 0
  }
]